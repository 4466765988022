import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormInput } from "../Components/FormInput";
import * as Yup from "yup";
import ButtonComponent from "../Components/ButtonComponent";

import { Formik, Form } from "formik";
import { createDiscount } from "../Services/Service";

export default function AddDiscount(props) {
  const [loading, setloading] = useState("");
  return (
    <div>
      <Modal id="myModal" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{ name: "" }}
            validationSchema={Yup.object({
              //   email: Yup.string().required("Enter Email"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setloading(true);
                await createDiscount({
                  name: values.name,
                });

                await props.discountUpdated();
                await props.optionUpdated();
                props.onHide();
                setloading(false);
              } catch (error) {
                setloading(false);
                alert(error.message);
              }
            }}
          >
            <Form>
              <FormInput
                placeholder="Crazy discount"
                name="name"
                type="text"
                style={{ marginBottom: 4 }}
                label="Discount name"
              />

              <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                <ButtonComponent
                  disable={loading}
                  text="Create"
                  type="submit"
                  noOnclick
                />
                <ButtonComponent
                  text="Cancel"
                  style={{
                    marginLeft: 16,
                    color: "#3F4247",
                    backgroundColor: "#fff",
                    border: "1px solid #D7DFE9",
                  }}
                  onClick={() => props.onHide()}
                />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
