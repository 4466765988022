/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import {
  getAgeGroup,
  getBookings,
  getBookingsByMonth,
  getCoupons,
} from "../Services/Service";
import moment from "moment";

function Statistic() {
  const [adults, setadults] = useState(0);
  const [teens, setteens] = useState(0);
  const [kids, setkids] = useState(0);
  const [group, setgroup] = useState(0);
  const [privateOpt, setprivateOpt] = useState(0);
  const [coupons, setcoupons] = useState([]);
  const [last6Months, setlast6Months] = useState([]);
  const [last6MonthsBooking, setlast6MonthsBooking] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // You can await hereby

      var monthNames = [
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
      ];

      var today = new Date();
      var d;
      var month;

      for (var i = 6; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() + 1 - i, 1);
        month = monthNames[d.getMonth()];
        last6Months.push(month.name);
        const monthBooking = await getBookingsByMonth({
          month: month.value,
          year: moment().format("YYYY"),
        });
        last6MonthsBooking.push(monthBooking.totalResults);
      }

      const age = await getAgeGroup();
      const kids = age.filter((a) => a.name === "Kids");
      const adult = age.filter((a) => a.name === "Adults");
      const teen = age.filter((a) => a.name === "Teens");

      const total = await getBookings({
        status: "Paid",
        sortBy: "createdDate:desc",
        limit: 10,
        page: 1,
        ageGroup: kids[0].id,
      });
      const res = await getBookings({
        status: "Paid",
        sortBy: "createdDate:desc",
        limit: 10,
        page: 1,
        ageGroup: adult[0].id,
      });
      const data = await getBookings({
        status: "Paid",
        sortBy: "createdDate:desc",
        limit: 10,
        page: 1,
        ageGroup: teen[0].id,
      });
      const optPrivate = await getBookings({
        status: "Paid",
        sortBy: "createdDate:desc",
        limit: 10,
        page: 1,
        optionType: "private",
      });
      const optGroup = await getBookings({
        status: "Paid",
        sortBy: "createdDate:desc",
        limit: 10,
        page: 1,
        optionType: "group",
      });
      const cpn = await getCoupons();
      setcoupons(cpn);
      setgroup(optGroup.totalResults);
      setprivateOpt(optPrivate.totalResults);
      setteens(data.totalResults);
      setadults(res.totalResults);
      setkids(total.totalResults);
    }
    fetchData();
  }, []);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Booking per month",
      },
    },
  };

  const labels = last6Months;

  const dataLine = {
    labels,
    datasets: [
      {
        label: "Bookings",
        data: last6MonthsBooking,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  function getRandomRgb() {
    var o = Math.round,
      r = Math.random,
      s = 255;
    return "rgb(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ",0.2)";
  }
  const data = {
    labels: ["Kids", "Adults", "Teens"],
    datasets: [
      {
        label: "# of Votes",
        data: [kids, adults, teens],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const data2 = {
    labels: ["Group", "Private"],
    datasets: [
      {
        label: "# of Votes",
        data: [group, privateOpt],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const rdmclr = coupons?.map(() => getRandomRgb());

  const data3 = {
    labels: coupons?.map((a) => a.name),
    datasets: [
      {
        label: "# of Votes",
        data: coupons?.map((a) => a.booked),
        backgroundColor: rdmclr,
        borderColor: rdmclr,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <Row>
        <Col md={6} className="d-flex align-items-center">
          <Line options={options} data={dataLine} />
        </Col>

        <Col md={6}>
          <Pie data={data2} />
        </Col>
      </Row>
      <Row style={{ paddingTop: 80 }}>
        <Col md={6}>
          <Pie data={data3} />
        </Col>
        <Col md={6}>
          <Pie data={data} />
        </Col>
      </Row>
    </div>
  );
}

export default Statistic;
