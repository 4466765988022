import React from "react";

export default function PlacementIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill={props.color}
        d="M5.103.003H.497A.497.497 0 000 .5v4.606c0 .274.223.497.497.497h4.606a.497.497 0 00.497-.497V.5a.497.497 0 00-.497-.497zM5.103 8.304H.497A.497.497 0 000 8.801v4.606c0 .274.223.497.497.497h4.606a.497.497 0 00.497-.497V8.801a.497.497 0 00-.497-.497z"
      ></path>
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M13 8.3H8a1 1 0 100 2h5a1 1 0 000-2zM13 3.5H8a1 1 0 000 2h5a1 1 0 100-2zM8 2h5a1 1 0 100-2H8a1 1 0 000 2zM13 12H8a1 1 0 000 2h5a1 1 0 000-2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
