import React from "react";

function Users(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M5.833 4.667a2.167 2.167 0 114.334 0 2.167 2.167 0 01-4.334 0zM8 1.5a3.167 3.167 0 100 6.333A3.167 3.167 0 008 1.5zm-2.667 8a3.167 3.167 0 00-3.166 3.167V14a.5.5 0 001 0v-1.333A2.167 2.167 0 015.333 10.5h5.334a2.167 2.167 0 012.166 2.167V14a.5.5 0 101 0v-1.333A3.167 3.167 0 0010.667 9.5H5.333z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Users;
