import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormInput } from "../Components/FormInput";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import DropDownComponent from "../Components/DropDownComponent";
import TabComponent from "../Components/TabComponent";
import TimePicker from "../Components/TimePicker";
import DropDown from "../Components/DropDown";
import ButtonComponent from "../Components/ButtonComponent";
import moment from "moment";
import {
  getCourses,
  updateClass,
  getLevels,
  getDaysPerWeek,
  getAgeGroup,
  getPartners,
  getCourseById,
  getLevelById,
  getPartnerById,
} from "../Services/Service";
import Loader from "../Components/Loader/Loader";
import { db } from "../firebaseConfig";

export default function EditClassModal(props) {
  const [courses, setcourses] = useState([]);
  const [courseSelected, setcourseSelected] = useState("");
  const [levels, setlevels] = useState([]);
  const [tabSelected, settabSelected] = useState({
    name: props.selectedClass.optionAgeName,
    value: props.selectedClass.optionAge,
  });
  const [daysSelected, setdaysSelected] = useState(
    props.selectedClass.availableDays.map((a) => {
      return { value: a };
    })
  );
  const [levelSelected, setlevelSelected] = useState([]);
  const [Starttime, setStarttime] = useState(
    moment(props.selectedClass.time).format("HH:mm")
  );
  const [partners, setpartners] = useState([]);
  const [partnerSelected, setpartnerSelected] = useState(
    props.selectedClass?.partner
  );
  const [daysPerWeek, setdaysPerWeek] = useState([]);
  const [daysPerWeekSelected, setdaysPerWeekSelected] = useState(
    props.selectedClass.daysPerWeek
  );
  const [ageGroup, setageGroup] = useState([]);

  const [loading, setloading] = useState(false);
  const [error, seterror] = useState("");

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const levels = await getLevels();
      const partner = await getPartners();
      const daysperweek = await getDaysPerWeek();
      const ageGroup = await getAgeGroup();
      const courses = await getCourses();
      const courseSel = await getCourseById(props.selectedClass?.course);
      props.selectedClass?.level.map(async (x) => {
        const data = await getLevelById(x);

        setlevelSelected((oldData) => [
          ...oldData,
          {
            label: data.name,
            value: data.id,
          },
        ]);
      });
      const partnerSel = await getPartnerById(props.selectedClass?.partner);
      setpartnerSelected(partnerSel);
      setcourseSelected(courseSel);
      setcourses(courses);
      setdaysPerWeek(daysperweek);
      setageGroup(ageGroup);
      setpartners(partner);
      setlevels(levels);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const daysSelectedfn = (value) => {
    setdaysSelected([
      ...daysSelected,
      {
        value,
      },
    ]);
  };
  const ageData = ageGroup.map((x) => {
    const data = { name: x.name, value: x.id };
    return data;
  });

  return (
    <div>
      <Modal id="myModal" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!levelSelected.length ? (
            <Loader />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={{
                zoomLink: props.selectedClass.zoomLink,
                zoomPassword: props.selectedClass.zoomPassword,
              }}
              validationSchema={Yup.object({
                zoomLink: Yup.string().required("Enter zoom link"),
                zoomPassword: Yup.string().required("Enter zoom password"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                const levelIds = levelSelected.map((x) => x.value);
                const availableDays = daysSelected.map((x) => x.value);
                const time = moment(Starttime, "HH:mm");

                if (
                  !partnerSelected ||
                  !courseSelected ||
                  !tabSelected ||
                  levelSelected.length === 0 ||
                  !time ||
                  !availableDays ||
                  !daysPerWeekSelected
                ) {
                  seterror(true);
                  setloading(false);
                } else {
                  setloading(true);
                  await updateClass(props.selectedClass.id, {
                    optionAge: tabSelected.value,
                    optionAgeName: tabSelected.name,
                    level: levelIds,
                    course: courseSelected.id,
                    courseName: courseSelected.slug,
                    partner: partnerSelected.id,
                    zoomLink: values.zoomLink,
                    zoomPassword: values.zoomPassword,
                    time: moment(time).format(),
                    status: false,
                    availableDays: availableDays,
                    daysPerWeek: daysPerWeekSelected.id,
                  });
                  await db
                    .collection("clear_cache_home")
                    .doc("su3Zr6L4Q2d21lp0wDJL")
                    .update({
                      status: false,
                    });
                  await db
                    .collection("clear_cache_pricing")
                    .doc("Ex02fRvGaBL5IGYjsbxT")
                    .update({
                      status: false,
                    });
                  await db
                    .collection("clear_cache_checkout")
                    .doc("rk4HW8Rad7ju2jk7CEze")
                    .update({
                      status: false,
                    });
                  await props.classUpdated();
                  props.onHide();
                  setloading(false);
                }
              }}
            >
              <Form>
                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Course
                  </span>

                  <DropDown
                    data={courses}
                    error={error && !courseSelected}
                    errorMessage={error && !courseSelected ? "course" : null}
                    onClick={async (x) => {
                      setcourseSelected(x);
                    }}
                    courseIcon={courseSelected?.flag}
                    course
                    name={courseSelected?.name}
                  />
                </div>

                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Age
                  </span>
                  <TabComponent
                    data={ageData}
                    error={error && !tabSelected}
                    errorMessage={error && !tabSelected ? "Age" : null}
                    style={{
                      padding:
                        tabSelected?.value === "6135dfad4d92d9052971158c"
                          ? "0 49.5px"
                          : "0 50px",
                    }}
                    tabSelected={tabSelected}
                    settabSelected={(tabSelected) =>
                      settabSelected(tabSelected)
                    }
                  />
                </div>
                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Level
                  </span>
                  <DropDownComponent
                    levels={levels}
                    optionSelected={levelSelected}
                    error={error && levelSelected.length === 0}
                    errorMessage={
                      error && levelSelected.length === 0 ? "levels" : null
                    }
                    handleChange={(selected) => {
                      setlevelSelected(selected);
                    }}
                  />
                </div>

                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Partner
                  </span>
                  <DropDown
                    data={partners}
                    error={error && !partnerSelected}
                    errorMessage={error && !partnerSelected ? "partner" : null}
                    onClick={async (x) => {
                      setpartnerSelected(x);
                    }}
                    name={partnerSelected.name}
                  />
                </div>

                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Days Per Week
                  </span>
                  <DropDown
                    data={daysPerWeek}
                    error={error && !daysPerWeekSelected}
                    errorMessage={
                      error && !daysPerWeekSelected ? "days per week" : null
                    }
                    onClick={async (x) => {
                      setdaysPerWeekSelected(x);
                      setdaysSelected([]);
                    }}
                    menuStyle={{ height: 114 }}
                    name={
                      daysPerWeekSelected === props.selectedClass.daysPerWeek
                        ? // eslint-disable-next-line array-callback-return
                          daysPerWeek.map((x) => {
                            if (x.id === props.selectedClass.daysPerWeek) {
                              return x.name;
                            }
                          })
                        : daysPerWeekSelected.name
                    }
                  />
                </div>

                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Days
                  </span>
                  <TabComponent
                    error={error && !daysSelected}
                    errorMessage={error && !daysSelected ? "days" : null}
                    data={[
                      { name: "Mon", value: "mon" },
                      { name: "Tue", value: "tue" },
                      { name: "Wed", value: "wed" },
                      { name: "Thu", value: "thu" },
                      { name: "Fri", value: "fri" },
                      { name: "Sat", value: "sat" },
                      { name: "Sun", value: "sun" },
                    ]}
                    style={{
                      padding: "0 17px",
                    }}
                    multi
                    tabSelected={daysSelected}
                    settabSelected={(a) => {
                      if (daysSelected.some((y) => y.value === a)) {
                        setdaysSelected(
                          daysSelected.filter((z) => z.value !== a)
                        );
                      } else {
                        if (
                          daysPerWeekSelected.daysPerWeek !==
                          daysSelected.length
                        ) {
                          daysSelectedfn(a);
                        }
                      }
                    }}
                  />
                </div>
                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Time
                  </span>
                  <TimePicker
                    text="00:00"
                    time={Starttime}
                    error={error && !Starttime}
                    errorMessage={error && !Starttime ? "time" : null}
                    data={(x) => {
                      setStarttime(x);
                    }}
                  />
                </div>
                <FormInput
                  placeholder="Zoom Link"
                  name="zoomLink"
                  type="text"
                  style={{ marginBottom: 4 }}
                  label="Zoom Link"
                />
                <FormInput
                  placeholder="Zoom Password"
                  name="zoomPassword"
                  type="text"
                  style={{ marginBottom: 4 }}
                  label="Zoom Password"
                />

                <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                  <ButtonComponent
                    disable={loading}
                    text="Update"
                    type="submit"
                    noOnclick
                  />
                  <ButtonComponent
                    text="Cancel"
                    style={{
                      marginLeft: 16,
                      color: "#3F4247",
                      backgroundColor: "#fff",
                      border: "1px solid #D7DFE9",
                    }}
                    onClick={() => props.onHide()}
                  />
                </div>
              </Form>
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
