import React, { useState, useEffect } from "react";
import { FormCheck, Modal } from "react-bootstrap";

import { FormInput } from "../Components/FormInput";
import * as Yup from "yup";
import ButtonComponent from "../Components/ButtonComponent";
import { Formik, Form } from "formik";
import {
  getDiscountTypes,
  createCoupon,
  getCountries,
} from "../Services/Service";
import Calender from "../Components/Calender";
import DropDown from "../Components/DropDown";
import DropDownComponent from "../Components/DropDownComponent";
import DiscountIcon from "../Assets/Icons/DiscountIcon";
export default function AddCoupon(props) {
  const [discountTypes, setdiscountTypes] = useState([]);
  const [selectedDiscountType, setselectedDiscountType] = useState("");
  const [loading, setloading] = useState(false);
  const [countries, setcountries] = useState([]);
  const [countriesSelected, setcountriesSelected] = useState([]);
  const [calenderOpen, setcalenderOpen] = useState(false);
  const [validity, setvalidity] = useState({});
  const [status, setstatus] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const discountTypes = await getDiscountTypes();
      const courses = await getCountries();
      setcountries(courses);
      setdiscountTypes(discountTypes);
    }
    fetchData();
  }, []);
  return (
    <div>
      <Modal id="myModal" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{ name: "" }}
            validationSchema={Yup.object({
              //   email: Yup.string().required("Enter Email"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                // setloading(true);
                let resluts = [];
                resluts = countriesSelected.map((a) => {
                  return a.value;
                });

                // var merged = [].concat.apply([], options);
                // const optionids = merged.map((a) => a.id);

                await createCoupon({
                  name: values.name,
                  description: values.description,
                  code: values.code,
                  countries: resluts,
                  discountType: selectedDiscountType.id,
                  validity: validity,
                  status: true,
                  preApplied: status,
                });

                setloading(false);
                await props.couponUpdated();
                await props.optionUpdated();
                props.onHide();
              } catch (error) {
                setloading(false);
                alert(error.message);
              }
            }}
          >
            <Form>
              <FormInput
                placeholder="Name"
                name="name"
                type="text"
                style={{ marginBottom: 4 }}
                label="Coupon name"
              />
              <FormInput
                placeholder="Description"
                name="description"
                type="text"
                style={{ marginBottom: 4 }}
                label="Coupon Description"
              />
              <FormInput
                placeholder="code"
                name="code"
                type="text"
                style={{ marginBottom: 4 }}
                label="Coupon code"
              />
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Discount Types
                </span>

                <DropDown
                  discount
                  icon={<DiscountIcon />}
                  data={discountTypes}
                  onClick={async (x) => {
                    setselectedDiscountType(x);
                  }}
                  name={
                    !selectedDiscountType
                      ? "Discount Type"
                      : selectedDiscountType.name
                  }
                />
              </div>

              <Calender
                onClick={() => setcalenderOpen(!calenderOpen)}
                open={calenderOpen}
                validity={(x) => {
                  setvalidity(x);
                }}
              />

              <DropDownComponent
                course
                courses={countries}
                handleChange={(selected) => {
                  setcountriesSelected(selected);
                }}
              />

              <div className="flex-column d-flex" style={{ marginTop: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Pre-Applied
                </span>

                <FormCheck custom type="switch">
                  <FormCheck.Input
                    disabled={props.discount ? true : loading}
                    onChange={async () => {
                      setstatus(!status);
                    }}
                    checked={status}
                  />
                </FormCheck>
              </div>

              <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                <ButtonComponent
                  disable={loading}
                  text="Create"
                  type="submit"
                  noOnclick
                />
                <ButtonComponent
                  text="Cancel"
                  style={{
                    marginLeft: 16,
                    color: "#3F4247",
                    backgroundColor: "#fff",
                    border: "1px solid #D7DFE9",
                  }}
                  onClick={() => props.onHide()}
                />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
