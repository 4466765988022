import React from "react";
import Helmet from "react-helmet";
import { DayPicker, addToRange } from "react-day-picker";
import moment from "moment";

export default class Calender extends React.Component {
  static defaultProps = {
    numberOfMonths: 1,
  };
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  handleDayClick(day) {
    const range = addToRange(day, this.state);
    this.setState(range);
    if (range.to) {
      this.props.onClick();
      this.props.validity(range);
    }
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }
  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <div className="RangeExample">
        <div style={{ marginBottom: 24 }}>
          <span className="input-title d-flex justify-content-start align-items-center">
            Expiry date & time
          </span>
          <div
            className="res-input-100 input form-control form-control-lg"
            onClick={() => this.props.onClick()}
          >
            <span
              className="d-flex align-items-center"
              style={{ height: 40, fontSize: 14 }}
            >
              {!from &&
                !to &&
                !this.props.edit &&
                "Please select the first day."}
              {!from &&
                !to &&
                !this.props.edit &&
                "Please select the last day."}
              {!from && !to && this.props.edit
                ? `${moment(this.props.selectedDates?.from).format(
                    "DD/MM/YYYY"
                  )} - ${moment(this.props.selectedDates?.to).format(
                    "DD/MM/YYYY"
                  )}`
                : ""}
              {from &&
                to &&
                ` ${moment(
                  !from ? this.props.selectedDates?.from : from
                ).format("DD/MM/YYYY")} to
                ${moment(!to ? this.props.selectedDates?.to : to).format(
                  "DD/MM/YYYY"
                )}`}{" "}
              {from && to && (
                <button className="link" onClick={this.handleResetClick}>
                  Reset
                </button>
              )}
            </span>
          </div>
          {this.props.open ? (
            <DayPicker
              className="Selectable"
              numberOfMonths={this.props.numberOfMonths}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
            />
          ) : null}
        </div>
        <Helmet>
          <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #0B69FF !important;
    color: #fff;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
    background-color: #0B69FF !important;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }
  .DayPicker{
    font-size: 1.47rem;
    background-color:#fff;
    margin-top: 4px;
    position: absolute;
    z-index:99
  }
  .DayPicker-NavButton--prev{
    margin-right: 12.8em;
  }
  .DayPicker-Caption{
    text-align:center
  }
  .DayPicker-Caption > div{
    font-family: Cairo;
font-style: normal;
font-weight: normal;
font-size: 14px;
color: #3F4247;

  }
  .DayPicker-Day{
    padding:1.22em;
  }
  .DayPicker-Weekday abbr[title]{


font-family: Cairo;
font-style: normal;
font-weight: normal;
font-size: 12.7843px;
line-height: 17px;
letter-spacing: 0.01em;
text-transform: uppercase;

color: rgba(126, 133, 142, 0.6);
  }
  .Selectable .DayPicker-Day{
    font-size:14px
  }
  .DayPicker-wrapper{
    padding:0
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }
`}</style>
        </Helmet>
      </div>
    );
  }
}
