import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

export const FormInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  // const fieldRef = React.useRef();
  // useFocusOnError({fieldRef, name: props.name});
  return (
    <>
      <Form.Group
        className={
          props.contact ? "d-flex justify-content-end" : props.className
        }
        style={{ marginBottom: 12, ...props.containerStyle }}
        onClick={props.onClick}
      >
        {props.noLabel ? null : (
          <Form.Label className="input-title" style={props.style}>
            {props.required ? <span style={{ color: "red" }}>*</span> : null}{" "}
            {label}
          </Form.Label>
        )}
        <Form.Control
          {...field}
          style={{
            width: "100%",
            background: " #FFFFFF",
            border: "1px solid #D7DFE9",
            borderRadius: 4,
            minHeight: "40px !important",
            ...props.style,
          }}
          className={`res-input-100 input ${props.className}`}
          size="lg"
          id={props.id}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
        />

        {/* {props.searchInput ? (
          <span
            style={{
              position: "absolute",
              top: 4,
              marginLeft: 10,
              width: "50px",
              height: 40,
            }}
          >
            <SearchIcon />
          </span>
        ) : null} */}
      </Form.Group>
      {meta.touched && meta.error ? (
        <div className="error" style={style.error}>
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

const style = {
  error: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#FB000A",
    marginTop: -8,
  },
};
