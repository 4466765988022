import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import arrow from "../Assets/Icons/ArrowDown.svg";
import * as CONSTANT from "../Constant/Constant";

export default function TimePicker(props) {
  return (
    <div>
      <Dropdown className="time-dd">
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          className="custom-dropdown"
          style={{
            background: "transparent",
            border: "1px solid #E8E9F5",
          }}
        >
          <div className="d-flex flex-row justify-content-between align-items-center w-100 h-100">
            <span
              className="d-flex justify-content-center align-items-center h-100"
              style={style.th}
            >
              {props.time ? props.time : props.text}
            </span>
            <img src={arrow} alt="icon" style={{ marginLeft: 9 }} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="custom-dropdown-menu">
          {CONSTANT.TimeArray.map((x, key) => (
            <Dropdown.Item
              key={key}
              className="d-flex justify-content-start"
              onClick={() => props.data(x)}
            >
              <span style={{ direction: "rtl" }}>{x}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {props.error && props.errorMessage ? (
        <div>
          <span
            style={style.error}
            className="error"
          >{`Please select the ${props.errorMessage}  `}</span>
        </div>
      ) : null}
    </div>
  );
}

const style = {
  th: {
    color: "#676767",
  },
  dropdown: {
    background: "transparent",
    border: "none",
    height: 40,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  error: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#FB000A",
    marginTop: -8,
  },
};
