import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDMpTPEKHA-YpMgHc7AYjJT6CKNGAkMbPQ",
  authDomain: "digital-vim-270607.firebaseapp.com",
  databaseURL: "https://digital-vim-270607.firebaseio.com",
  projectId: "digital-vim-270607",
  storageBucket: "digital-vim-270607.appspot.com",
});

const db = firebaseApp.firestore();

export { db };
