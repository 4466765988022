/* eslint-disable no-unused-vars */
import debounce from "debounce";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import SearchIcon from "../Assets/Icons/SearchIcon";
import DashboardCard from "../Components/DashboardCard/DashboardCard";
import DropDown from "../Components/DropDown";
import Loader from "../Components/Loader/Loader";
import PaginationComponent from "../Components/PaginationComponent/PaginationComponent";
import StudentsTable from "../Components/StudentsTable";
import {
  getAgeGroup,
  getBookings,
  getCourses,
  getDurations,
  getLevels,
  getPartners,
  searchBooking,
} from "../Services/Service";
import Icon1 from "../Assets/Icons/Icon1.svg";
import Icon2 from "../Assets/Icons/Icon2.svg";
import Icon3 from "../Assets/Icons/Icon3.svg";

function Students(props) {
  var authToken = localStorage.getItem("authToken");
  const [booking, setbooking] = useState([]);
  const [levels, setlevels] = useState([]);
  const [courses, setcourses] = useState([]);
  const [durations, setdurations] = useState([]);
  const [ageGroup, setageGroup] = useState([]);
  const [page, setpage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [selectedBooking, setselectedBooking] = useState({});
  const [modalShow, setmodalShow] = useState(false);
  const [totalPage, settotalPage] = useState("");
  const [filter, setfilter] = useState(false);
  const [courseSelected, setcourseSelected] = useState(null);
  const [durationSelected, setdurationSelected] = useState(null);
  const [levelSelected, setlevelSelected] = useState(null);
  const [ageGroupSelected, setageGroupSelected] = useState(null);
  const [searchText, setsearchText] = useState("");
  const [stage, setstage] = useState("All");
  const [partners, setpartners] = useState([]);
  const [activeStudents, setactiveStudents] = useState(0);
  const [inactive, setinactive] = useState(0);
  const [refund, setrefund] = useState(0);

  let options = {
    status: "Paid",
    level: levelSelected?.id,
    duration: durationSelected?.id,
    course: courseSelected?.id,
    ageGroup: ageGroupSelected?.id,
    sortBy: "createdAt:desc",
    stage: stage === "All" ? null : stage,
  };

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const levels = await getLevels();
      const course = await getCourses();
      const duration = await getDurations();
      const age = await getAgeGroup();
      const partners = await getPartners();
      setpartners(partners);
      setageGroup(age);
      setdurations(duration);
      setlevels(levels);
      setcourses(course);
      const res = await getBookings(
        Object.entries(options).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          { limit: 10, page: 1 }
        )
      );

      const active = await getBookings(
        Object.entries(options).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          { limit: 10, page: 1, stage: "Active" }
        )
      );
      const inactive = await getBookings(
        Object.entries(options).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          { limit: 10, page: 1, stage: "Inactive" }
        )
      );
      const refunded = await getBookings(
        Object.entries(options).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          { limit: 10, page: 1, stage: "Refunded" }
        )
      );
      setrefund(refunded.totalResults);
      setinactive(inactive.totalResults);
      setactiveStudents(active.totalResults);
      settotalPage(res.totalPages || null);
      setbooking(res.results);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBookingsfn = async (data) => {
    let options = {
      status: "Paid",
      sortBy: "createdAt:desc",
      stage: data === "All" ? null : data,
    };
    const res = await getBookings(
      Object.entries(options).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        { limit: 10, page: 1 }
      )
    );
    setpage(1);
    settotalPage(res.totalPages || null);
    setbooking(res.results);
  };
  if (!props.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Row style={{ paddingTop: 24 }}>
        <DashboardCard
          text={activeStudents}
          subtext="Active Students"
          icon={Icon1}
          color={"#299CF7"}
        />
        <DashboardCard
          text={inactive}
          subtext="Inactive Students"
          icon={Icon2}
          color={"#FD3E60"}
        />
        <DashboardCard
          text={refund}
          subtext="Refunded"
          icon={Icon3}
          color={"#43C59E"}
        />
      </Row>
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row " style={{ position: "relative" }}>
          <div className="search-icon">
            <SearchIcon />
          </div>
          <input
            placeholder="Input Text"
            name="name"
            onChange={debounce(async (e) => {
              setsearchText(e.target.value);
              if (e.target.value === "") {
                const res = await getBookings({
                  status: "Paid",
                  sortBy: "createdAt:desc",
                  limit: 10,
                  page: page,
                  stage: stage,
                });
                settotalPage(res.totalPages || null);
                setbooking(res.results);
              } else {
                const res = await searchBooking(
                  { text: e.target.value },
                  {
                    status: "Paid",
                    sortBy: "createdAt:desc",
                    limit: 10,
                    page: 1,
                    stage: stage,
                  }
                );
                setpage(1);
                settotalPage(res.totalPages || null);
                setbooking(res.results);
              }
            }, 500)}
            type="text"
            className="search-input"
          />
        </div>
        <div className="filter-box" onClick={() => setfilter(!filter)}>
          <svg
            width="28"
            height="27"
            viewBox="0 0 28 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.316 14.5106V20.1336C17.316 20.5523 17.0794 20.935 16.705 21.1222L12.2839 23.3327C11.549 23.7002 10.6844 23.1658 10.6844 22.3442V14.5106L3.19737 5.35985C2.60692 4.63818 3.12036 3.55469 4.05279 3.55469H23.9475C24.88 3.55469 25.3934 4.63818 24.803 5.35985L17.316 14.5106ZM15.1054 19.4505V14.1161C15.1054 13.8609 15.1937 13.6137 15.3553 13.4162L21.6152 5.76521H6.38517L12.6451 13.4162C12.8066 13.6137 12.8949 13.8609 12.8949 14.1161V20.5558L15.1054 19.4505Z"
              fill="#7E858E"
            />
          </svg>
        </div>
      </div>
      {filter ? (
        <Card style={{ padding: 20, width: "50%", float: "right" }}>
          <Row>
            <Col md={3}>
              <DropDown
                data={courses}
                onClick={async (x) => {
                  setcourseSelected(x);
                }}
                courseIcon={courseSelected?.flag}
                course
                name={!courseSelected ? "Course" : courseSelected.slug}
              />
            </Col>
            <Col md={3}>
              <DropDown
                data={levels}
                onClick={async (x) => {
                  setlevelSelected(x);
                }}
                name={!levelSelected ? "Levels" : levelSelected.slug}
              />
            </Col>
            <Col md={3}>
              <DropDown
                data={durations}
                onClick={async (x) => {
                  setdurationSelected(x);
                }}
                name={!durationSelected ? "Duration" : durationSelected.slug}
              />
            </Col>
            <Col md={3}>
              <DropDown
                data={ageGroup}
                onClick={async (x) => {
                  setageGroupSelected(x);
                }}
                name={!ageGroupSelected ? "Age" : ageGroupSelected.slug}
              />
            </Col>
          </Row>
          <div className="d-flex flex-row">
            <Button
              onClick={async () => {
                const res = await getBookings(
                  Object.entries(options).reduce(
                    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                    { limit: 10, page: 1 }
                  )
                );
                setpage(1);
                settotalPage(res.totalPages);
                setbooking(res.results);
              }}
              style={{ width: "max-content", marginTop: 10 }}
            >
              Apply
            </Button>
            <Button
              onClick={async () => {
                let options = {
                  status: "Paid",
                  sortBy: "createdAt:desc",
                  stage: stage === "All" ? null : stage,
                };
                const res = await getBookings(
                  Object.entries(options).reduce(
                    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                    { limit: 10, page: 1 }
                  )
                );
                settotalPage(res.totalPages);
                setbooking(res.results);
                setcourseSelected(null);
                setdurationSelected(null);
                setlevelSelected(null);
                setageGroupSelected(null);
                setfilter(false);
              }}
              style={{ width: "max-content", marginTop: 10, marginLeft: 10 }}
            >
              Remove Filter
            </Button>
          </div>
        </Card>
      ) : null}
      <div className="d-flex flex-row">
        <div
          onClick={() => {
            setstage("All");
            getBookingsfn("All");
          }}
          style={{
            padding: 10,
            cursor: "pointer",
            color: stage === "All" ? "#0B69FF" : "#000",
            borderBottom: stage === "All" ? "1px solid #0B69FF" : "",
          }}
        >
          All
        </div>
        <div
          onClick={() => {
            setstage("Active");
            getBookingsfn("Active");
          }}
          style={{
            padding: 10,
            cursor: "pointer",
            color: stage === "Active" ? "#0B69FF" : "#000",
            borderBottom: stage === "Active" ? "1px solid #0B69FF" : "",
            marginRight: 20,
          }}
        >
          Active
        </div>

        <div
          onClick={() => {
            setstage("Inactive");
            getBookingsfn("Inactive");
          }}
          style={{
            padding: 10,
            cursor: "pointer",
            color: stage === "Inactive" ? "#0B69FF" : "#000",
            borderBottom: stage === "Inactive" ? "1px solid #0B69FF" : "",
          }}
        >
          Inactive
        </div>
        <div
          onClick={() => {
            setstage("Paused");
            getBookingsfn("Paused");
          }}
          style={{
            padding: 10,
            cursor: "pointer",
            color: stage === "Paused" ? "#0B69FF" : "#000",
            borderBottom: stage === "Paused" ? "1px solid #0B69FF" : "",
          }}
        >
          Paused
        </div>
        <div
          onClick={() => {
            setstage("Refunded");
            getBookingsfn("Refunded");
          }}
          style={{
            padding: 10,
            cursor: "pointer",
            color: stage === "Refunded" ? "#0B69FF" : "#000",
            borderBottom: stage === "Refunded" ? "1px solid #0B69FF" : "",
          }}
        >
          Refunded
        </div>
      </div>
      {booking?.length === 0 ? (
        <div
          style={{ height: "70vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <h2>{`No ${stage} Bookings...!`}</h2>
        </div>
      ) : null}
      {!booking.length ? (
        <Loader />
      ) : (
        <StudentsTable
          header={[
            "Status",
            "Name / ID / Order #",
            "Course / Duration",
            "Days / Time",
            "Assign to",
          ]}
          tableData={!booking ? [] : booking}
          levels={levels}
          partners={partners}
          courses={courses}
          durations={durations}
          ageGroup={ageGroup}
          onClick={(x) => {
            setselectedBooking(x);
            setmodalShow(true);
          }}
          stage={stage}
          getBookingsfn={(data) => getBookingsfn(data)}
        />
      )}
      {booking?.length ? (
        <PaginationComponent
          page={parseInt(page)}
          totalPage={totalPage}
          onPageClick={async (x) => {
            setpage(parseInt(x));
            if (searchText) {
              const res = await searchBooking(
                { text: searchText },
                {
                  status: "Paid",
                  sortBy: "createdAt:desc",
                  limit: 10,
                  page: x,
                }
              );
              settotalPage(res.totalPages || null);
              setbooking(res.results);
            } else {
              const data = await getBookings(
                Object.entries(options).reduce(
                  (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                  { limit: 10, page: x }
                )
              );
              setbooking(data.results);
              settotalPage(data.totalPages);
            }
          }}
          onChange={async () => {
            setpage(parseInt(page) + 1);
            if (searchText) {
              const res = await searchBooking(
                { text: searchText },
                {
                  status: "Paid",
                  sortBy: "createdAt:desc",
                  limit: 10,
                  page: page + 1,
                }
              );
              settotalPage(res.totalPages || null);
              setbooking(res.results);
            } else {
              if (booking?.length === 10) {
                const data = await getBookings(
                  Object.entries(options).reduce(
                    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                    { limit: 10, page: page + 1 }
                  )
                );
                setbooking(data.results);
                settotalPage(data.totalPages);
              }
            }
          }}
          onChangePrev={async () => {
            setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);

            if (searchText) {
              const res = await searchBooking(
                { text: searchText },
                {
                  status: "Paid",
                  sortBy: "createdAt:desc",
                  limit: 10,
                  page: page - 1,
                }
              );
              settotalPage(res.totalPages || null);
              setbooking(res.results);
            } else {
              const data = await getBookings(
                Object.entries(options).reduce(
                  (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                  { limit: 10, page: page - 1 }
                )
              );
              setbooking(data.results);
              settotalPage(data.totalPages);
            }
          }}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(Students);
