/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormInput } from "../Components/FormInput";
import * as Yup from "yup";
import ButtonComponent from "../Components/ButtonComponent";
import { Formik, Form } from "formik";
import {
  getDiscountTypes,
  getCountries,
  updateCoupon,
} from "../Services/Service";
import Calender from "../Components/Calender";
import DropDown from "../Components/DropDown";
import DropDownComponent from "../Components/DropDownComponent";
import DiscountIcon from "../Assets/Icons/DiscountIcon";
import Loader from "../Components/Loader/Loader";
import { db } from "../firebaseConfig";

export default function AddCoupon(props) {
  const [discountTypes, setdiscountTypes] = useState([]);
  const [selectedDiscountType, setselectedDiscountType] = useState("");
  const [loading, setloading] = useState(false);
  const [countries, setcountries] = useState([]);
  const [countrySelected, setcountrySelected] = useState([]);
  const [calenderOpen, setcalenderOpen] = useState(false);
  const [validity, setvalidity] = useState(props.selectedCoupon.validity);

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const discountTypes = await getDiscountTypes();
      const courses = await getCountries();

      const selecteddiscountTypes = discountTypes.filter(
        (x) => props.selectedCoupon?.discountType === x.id
      );
      setselectedDiscountType(selecteddiscountTypes[0]);
      let countrySelected = [];
      for (let index = 0; index < courses.length; index++) {
        const element = courses[index];
        props.selectedCoupon?.countries.filter((a) => {
          if (a === element.id) {
            countrySelected.push({ label: element.name, value: element.id });
          }
        });
      }
      setcountrySelected(countrySelected);
      setcountries(courses);
      setdiscountTypes(discountTypes);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal id="myModal" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!countrySelected ? (
            <Loader />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: props.selectedCoupon?.name,
                description: props.selectedCoupon?.description,
                code: props.selectedCoupon?.code,
              }}
              validationSchema={Yup.object({
                //   email: Yup.string().required("Enter Email"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  setloading(true);
                  let resluts = [];
                  resluts = countrySelected.map((a) => {
                    return a.value;
                  });
                  await db
                    .collection("clear_cache_home")
                    .doc("su3Zr6L4Q2d21lp0wDJL")
                    .update({
                      status: false,
                    });
                  await db
                    .collection("clear_cache_pricing")
                    .doc("Ex02fRvGaBL5IGYjsbxT")
                    .update({
                      status: false,
                    });
                  await db
                    .collection("clear_cache_checkout")
                    .doc("rk4HW8Rad7ju2jk7CEze")
                    .update({
                      status: false,
                    });
                  await updateCoupon(props.selectedCoupon.id, {
                    name: values.name,
                    description: values.description,
                    code: values.code,
                    countries: resluts,
                    discountType: selectedDiscountType?.id,
                    validity: validity,
                    status: true,
                  });

                  setloading(false);
                  await props.couponUpdated();
                  await props.optionUpdated();
                  props.onHide();
                } catch (error) {
                  setloading(false);
                  alert(error.message);
                }
              }}
            >
              <Form>
                <FormInput
                  placeholder="Name"
                  name="name"
                  type="text"
                  style={{ marginBottom: 4 }}
                  label="Coupon name"
                />
                <FormInput
                  placeholder="Description"
                  name="description"
                  type="text"
                  style={{ marginBottom: 4 }}
                  label="Coupon Description"
                />
                <FormInput
                  placeholder="code"
                  name="code"
                  type="text"
                  style={{ marginBottom: 4 }}
                  label="Coupon code"
                />
                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Discount Types
                  </span>

                  <DropDown
                    discount
                    icon={<DiscountIcon />}
                    coupon
                    data={discountTypes}
                    onClick={async (x) => {
                      setselectedDiscountType(x);
                    }}
                    name={
                      !selectedDiscountType
                        ? "Discount Type"
                        : selectedDiscountType.name
                    }
                  />
                </div>

                <Calender
                  onClick={() => setcalenderOpen(!calenderOpen)}
                  open={calenderOpen}
                  edit
                  selectedDates={validity}
                  validity={(x) => {
                    setvalidity(x);
                  }}
                />

                <DropDownComponent
                  course
                  courses={countries}
                  optionSelected={countrySelected}
                  handleChange={(selected) => {
                    setcountrySelected(selected);
                  }}
                />
                <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                  <ButtonComponent
                    disable={loading}
                    text="Update"
                    type="submit"
                    noOnclick
                  />
                  <ButtonComponent
                    text="Cancel"
                    style={{
                      marginLeft: 16,
                      color: "#3F4247",
                      backgroundColor: "#fff",
                      border: "1px solid #D7DFE9",
                    }}
                    onClick={() => props.onHide()}
                  />
                </div>
              </Form>
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
