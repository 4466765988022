import { LOGGED_IN, USER_DETAILS } from "./types";
import { ApiV1, LOGIN } from "../API";

export const login = ({ email, password }) => {
  return async (dispatch) => {
    try {
      const res = await ApiV1.post(LOGIN, {
        email,
        password,
      });
      // console.log(res.data.tokens.access.token);
      await localStorage.setItem("authToken", res.data.tokens.access.token);

      dispatch({
        type: LOGGED_IN,
        payload: true,
      });
      dispatch({
        type: USER_DETAILS,
        payload: res.data.user,
      });
    } catch (error) {
      console.log("ERROR", error.response);
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    // localStorage.setItem("")
    localStorage.removeItem("authToken");
    localStorage.clear();

    dispatch({
      type: LOGGED_IN,
      payload: false,
    });
  };
};
