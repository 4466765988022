import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import arrow from "../Assets/Icons/ArrowDown.svg";

export default function DropDown(props) {
  return (
    <Dropdown style={props.ddStyle}>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className={
          !props.toggleClass
            ? "custom-dropdown"
            : "custom-dropdown custom-dd-height"
        }
      >
        <div className="d-flex flex-row justify-content-between align-items-center w-100 h-100">
          <div className="d-flex flex-row  align-items-center">
            {props.icon ? (
              props.icon
            ) : props.course && props.courseIcon ? (
              <img
                width="18px"
                height="18px"
                src={props.courseIcon}
                alt="img"
              />
            ) : props.country && props.countryIcon ? (
              <img
                width="18px"
                height="18px"
                src={props.countryIcon}
                alt="img"
              />
            ) : null}
            <span
              className="d-flex justify-content-start align-items-center h-100"
              style={{
                marginLeft: 10,
                color: "#676767",
              }}
            >
              {props.sort ? (
                <span
                  className="d-flex align-items-center h-100"
                  style={props.textStyle}
                >
                  Sort By:
                </span>
              ) : null}
              <span className="dd-text">{props.name}</span>
            </span>
          </div>
          <img
            src={arrow}
            alt="icon"
            style={{
              marginLeft: "9px",
              position: "relative",
              bottom: "-2px",
            }}
          />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ ...props.menuStyle, padding: 0 }}
        className="custom-dropdown-menu"
      >
        {props.data?.map((x, key) => (
          <Dropdown.Item
            key={key}
            onClick={() => props.onClick(x)}
            style={{
              boxShadow: "inset 0px -1px 0px #D7DFE9",
              padding: 12,
            }}
            className="d-flex flex-row  align-items-center"
          >
            {props.icon ? (
              props.icon
            ) : props.course ? (
              <img width="18px" height="18px" src={x.flag} alt="img" />
            ) : props.country ? (
              <img width="18px" height="18px" src={x.flag} alt="img" />
            ) : null}
            {props.course ? (
              <span className="dd-text" style={{ marginLeft: 10 }} key={key}>
                {x.slug}
              </span>
            ) : props.discount ? (
              <span className="dd-text" style={{ marginLeft: 10 }} key={key}>
                {x.name}
              </span>
            ) : (
              <span className="dd-text" style={{ marginLeft: 10 }} key={key}>
                {x.name}
              </span>
            )}
          </Dropdown.Item>
        ))}
        {props.discount && !props.coupon ? (
          <Dropdown.Item onClick={() => props.onClickAddDiscount()}>
            {props.icon}
            <span className="dd-text" style={{ marginLeft: 10 }}>
              Add Discount
            </span>
          </Dropdown.Item>
        ) : null}
      </Dropdown.Menu>

      {props.error ? (
        <div>
          <span style={style.error} className="error">
            {`Please select the ${props.errorMessage}  `}
          </span>
        </div>
      ) : null}
    </Dropdown>
  );
}

const style = {
  error: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#FB000A",
    marginTop: -8,
  },
};
