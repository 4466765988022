/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
import moment from "moment";
import React, { useState } from "react";

import { FormCheck } from "react-bootstrap";
import DotIcon from "../Assets/Icons/DotIcon";
import { deleteCoupon, updateCoupon } from "../Services/Service";
import DeleteModal from "../Models/DeleteModal.js";
import { db } from "../firebaseConfig.js";

export default function CouponTable(props) {
  const [showMenu, setshowMenu] = useState(false);
  const [modalShow, setmodalShow] = useState(false);
  const [CouponId, setCouponId] = useState("");
  return (
    <div>
      <div>
        <div className="table-responsive-xl ">
          <table className="table table-bordered">
            {props.tableData.length ? (
              <thead>
                <tr>
                  {props.header?.map((x) => (
                    <th className="table-option-header">{x}</th>
                  ))}
                </tr>
              </thead>
            ) : null}
            <tbody className="option-table">
              {props.tableData.map((x, key) => (
                <>
                  <tr
                    key={key}
                    className="option-table"
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "inset 0px -1px 0px #D7DFE9",
                    }}
                  >
                    <td className="option-table">{x.name}</td>
                    <td className="option-table">{x.description}</td>
                    <td className="option-table">{x.code}</td>
                    <td className="option-table">
                      {props.discountTypes.map((a) => {
                        if (a.id === x.discountType) {
                          return a.name;
                        }
                      })}
                    </td>

                    <td className="option-table">
                      {moment(x.validity?.from).format("DD/MM/YYYY")} -{" "}
                      {moment(x.validity?.to).format("DD/MM/YYYY")}
                    </td>
                    <td className="option-table">{x.applied}</td>
                    <td className="option-table">{x.booked}</td>
                    <td className=" option-table d-flex justify-content-center">
                      <div className=" d-flex justify-content-around h-100 align-items-center">
                        {x.countries.map((y) => {
                          for (
                            let index = 0;
                            index < props.countries.length;
                            index++
                          ) {
                            const element = props.countries[index];
                            if (element.id === y) {
                              return (
                                <div style={{ position: "relative" }}>
                                  <img
                                    width="28px"
                                    className="coupon-flag"
                                    style={{ marginRight: 15 }}
                                    src={element.flag}
                                    alt="img"
                                  />
                                  <div className="coupon-flag-name">
                                    <span>{element.name}</span>
                                  </div>
                                </div>
                              );
                            }
                          }
                        })}
                      </div>
                    </td>
                    <td className="option-table">
                      {" "}
                      <FormCheck custom type="switch">
                        <FormCheck.Input
                          onChange={async () => {
                            await updateCoupon(x.id, {
                              preApplied: !x.preApplied,
                            });
                            await db;
                            await db
                              .collection("clear_cache_home")
                              .doc("su3Zr6L4Q2d21lp0wDJL")
                              .update({
                                status: false,
                              });
                            await db
                              .collection("clear_cache_pricing")
                              .doc("Ex02fRvGaBL5IGYjsbxT")
                              .update({
                                status: false,
                              });
                            await db
                              .collection("clear_cache_checkout")
                              .doc("rk4HW8Rad7ju2jk7CEze")
                              .update({
                                status: false,
                              });
                            await props.couponUpdated();
                          }}
                          checked={x.preApplied}
                        />
                      </FormCheck>
                    </td>
                    <td className="option-table">
                      {" "}
                      <FormCheck custom type="switch">
                        <FormCheck.Input
                          onChange={async () => {
                            await updateCoupon(x.id, { status: !x.status });
                            await db
                              .collection("clear_cache_home")
                              .doc("su3Zr6L4Q2d21lp0wDJL")
                              .update({
                                status: false,
                              });
                            await db
                              .collection("clear_cache_pricing")
                              .doc("Ex02fRvGaBL5IGYjsbxT")
                              .update({
                                status: false,
                              });
                            await db
                              .collection("clear_cache_checkout")
                              .doc("rk4HW8Rad7ju2jk7CEze")
                              .update({
                                status: false,
                              });
                            props.couponUpdated();
                          }}
                          checked={x.status}
                        />
                      </FormCheck>
                    </td>
                    <td>
                      <div
                        style={{ cursor: "pointer" }}
                        onBlur={() => setshowMenu(false)}
                        onClick={() => setshowMenu(showMenu ? !x.id : x.id)}
                      >
                        <DotIcon />
                      </div>
                      {showMenu === x.id ? (
                        <div
                          className="d-flex flex-column align-items-start"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            position: "absolute",
                            border: "1px solid #D7DFE9",
                            boxSizing: "border-box",
                            borderRadius: 4,
                            padding: 10,
                            marginTop: 10,
                            zIndex: 99,
                          }}
                        >
                          <span
                            onClick={async () => {
                              props.onClick(x);
                            }}
                          >
                            Edit
                          </span>
                          <span
                            onClick={async () => {
                              setmodalShow(true);
                              setshowMenu(false);
                              setCouponId(x.id);
                            }}
                          >
                            Delete
                          </span>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {modalShow ? (
        <DeleteModal
          show={modalShow}
          name="Coupon "
          onHide={() => {
            setmodalShow(false);
            setshowMenu(false);
          }}
          onClick={async () => {
            await deleteCoupon(CouponId);
            await props.couponUpdated();
            await props.optionUpdated();
          }}
        />
      ) : null}
    </div>
  );
}
