import React, { useEffect, useState } from "react";
// import logo from "../Assets/Images/logo.svg";
import ButtonComponent from "../Components/ButtonComponent";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import debounce from "debounce";
import ClassTable from "../Components/ClassTable";
import {
  getClasses,
  getLevels,
  searchClass,
  sortClass,
} from "../Services/Service";
import AddClassModal from "../Models/AddClassModal";
import EditClassModal from "../Models/EditClassModal";
import PaginationComponent from "../Components/PaginationComponent/PaginationComponent";
import Loader from "../Components/Loader/Loader";
import DropDown from "../Components/DropDown";
import SearchIcon from "../Assets/Icons/SearchIcon";

function Classes(props) {
  const [classes, setclasses] = useState("");
  const [modalShow, setmodalShow] = useState("");
  const [selectedClass, setselectedClass] = useState({});
  const [page, setpage] = useState(1);
  var authToken = localStorage.getItem("authToken");
  const [querySelected, setquerySelected] = useState("");
  const [totalPage, settotalPage] = useState("");
  const [levels, setlevels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const data = await getClasses({
        page: page,
        limit: 10,
      });

      const levels = await getLevels();
      setlevels(levels);
      setclasses(data.results || []);
      settotalPage(data.totalPages || null);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classUpdated = async () => {
    const data = await getClasses({
      page: page,
      limit: 10,
    });
    setclasses(data.results);
    settotalPage(data.totalPages);
  };
  if (!props.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  // const _debouncedSearch = (text) => {
  //   debounce(async () => {
  //     console.log(text);
  //     await searchClass(text);
  //   }, 100);
  // };

  return (
    <div
      style={{
        background: "#F4F6FA",
        overflowY: "scroll",
        height: "100vh",
      }}
    >
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: 32 }}
      >
        <div>
          <ButtonComponent
            onClick={() => setmodalShow("add-class")}
            text="Create Class"
          />
        </div>
        <div className="d-flex flex-row ">
          <div className="d-flex flex-row " style={{ position: "relative" }}>
            <div className="search-icon">
              <SearchIcon />
            </div>
            <input
              placeholder="Input Text"
              name="name"
              onChange={debounce(async (e) => {
                if (e.target.value === "") {
                  const data = await getClasses({
                    page: page,
                    limit: 10,
                  });
                  setclasses(data.results);
                  settotalPage(data.totalPages);
                } else {
                  const data = await searchClass(e.target.value);
                  setclasses(data);
                }
              }, 500)}
              type="text"
              className="search-input"
            />
          </div>
          <DropDown
            ddStyle={{ width: 200 }}
            data={[{ slug: "course" }, { slug: "age" }, { slug: "partner" }]}
            onClick={async (x) => {
              setquerySelected(x.slug);
              const data = await sortClass(x.slug);
              setclasses(data);
            }}
            sort
            textStyle={{
              fontWeight: "bold",
              marginRight: 7,
              fontSize: 12,
              color: "#7E858E",
            }}
            name={!querySelected ? "Sort By" : querySelected}
          />
        </div>
      </div>
      {!classes ? (
        <Loader />
      ) : (
        <>
          {" "}
          <ClassTable
            header={[
              "Class id",
              "course",
              "Age",
              "Level",
              "Time",
              "Partner",
              "Days",
              "Zoom",
              "Status",
            ]}
            classUpdated={() => classUpdated()}
            tableData={!classes ? [] : classes}
            levels={levels}
            onClickEdit={(x) => {
              setselectedClass(x);
              setmodalShow("edit");
            }}
          />
          {classes.length && classes.length <= 10 ? (
            <PaginationComponent
              page={page}
              totalPage={totalPage}
              onPageClick={async (x) => {
                const data = await getClasses({
                  page: parseInt(x),
                  sortBy: querySelected,
                });
                setclasses(data.results);
                settotalPage(data.totalPages);
                setpage(parseInt(x));
              }}
              onChange={async () => {
                if (classes.length === 10) {
                  const data = await getClasses({
                    page: page + 1,
                    sortBy: querySelected,
                  });
                  setclasses(data.results);
                  settotalPage(data.totalPages);
                  setpage(page + 1);
                }
              }}
              onChangePrev={async () => {
                setpage(page > 1 ? page - 1 : 1);

                const data = await getClasses({
                  page: page - 1,
                  sortBy: querySelected,
                });
                setclasses(data.results);
                settotalPage(data.totalPages);
              }}
            />
          ) : null}{" "}
        </>
      )}
      {modalShow === "edit" ? (
        <EditClassModal
          show={modalShow}
          selectedClass={selectedClass}
          onHide={() => setmodalShow(false)}
          classUpdated={() => classUpdated()}
        />
      ) : null}
      {modalShow === "add-class" ? (
        <AddClassModal
          show={modalShow}
          onHide={() => setmodalShow(false)}
          classUpdated={() => classUpdated()}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(Classes);
