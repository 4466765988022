/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { logout } from "../../Actions/AuthActions";
import ClassIcon from "../../Assets/Icons/ClassIcon";
import SettingIcon from "../../Assets/Icons/SettingIcon";
import logo from "../../Assets/Icons/logo.svg";
import LogoIcon from "../../Assets/Icons/LogoIcon";
import LogoutIcon from "../../Assets/Icons/LogoutIcon";
import Users from "../../Assets/Icons/Users";
import BookingIcon from "../../Assets/Icons/Icons/BookingIcon";
import StatsIcon from "../../Assets/Icons/Icons/StatsIcon";
import PlacementIcon from "../../Assets/Icons/Icons/PlacementIcon";

const Header = (props) => {
  const pathName = window.location.pathname;
  return (
    <>
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          {!props.toggle ? (
            <img className="logo" src={logo} alt="icon" />
          ) : null}
        </div>
        <div>{props.toggle ? <LogoIcon /> : null}</div>
        <ul className="sidebar-nav">
          <li
            style={{ paddingBottom: props.toggle ? 22 : 0 }}
            className={
              pathName === "/classes" && !props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
          >
            <a
              className="d-flex"
              style={
                pathName === "/classes" ||
                (window.location.pathname === "/" && !props.toggle)
                  ? {
                      color: "#0b69ff",
                    }
                  : null
              }
              href="/classes"
            >
              <div
                className={`${
                  props.toggle && pathName === "/classes" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
              >
                <ClassIcon
                  color={
                    pathName === "/classes" || window.location.pathname === "/"
                      ? "#0B69FF"
                      : "#7E858E"
                  }
                />
              </div>

              <span style={{ marginLeft: 25 }}>Classes</span>
            </a>
          </li>
          <li
            style={{ paddingBottom: props.toggle ? 22 : 0 }}
            className={
              pathName === "/setting" && !props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
          >
            <a
              className="d-flex"
              style={
                pathName === "/setting" && !props.toggle
                  ? {
                      color: "#0b69ff",
                    }
                  : null
              }
              href="/setting"
            >
              <div
                className={`${
                  props.toggle && pathName === "/setting" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
              >
                <SettingIcon
                  color={pathName === "/setting" ? "#0B69FF" : "#7E858E"}
                />
              </div>
              <span style={{ marginLeft: 25 }}>Settings</span>
            </a>
          </li>

          <li
            style={{ paddingBottom: props.toggle ? 22 : 0 }}
            className={
              pathName === "/placements" && !props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
          >
            <a
              className="d-flex"
              style={
                pathName === "/placements" && !props.toggle
                  ? {
                      color: "#0b69ff",
                    }
                  : null
              }
              href="/placements"
            >
              <div
                className={`${
                  props.toggle && pathName === "/placements" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
              >
                <PlacementIcon
                  color={pathName === "/placements" ? "#0B69FF" : "#7E858E"}
                />
              </div>

              <span style={{ marginLeft: 25 }}>Placements</span>
            </a>
          </li>
          <li
            style={{ paddingBottom: props.toggle ? 22 : 0 }}
            className={
              pathName === "/bookings" && !props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
          >
            <a
              className="d-flex"
              style={
                pathName === "/bookings" && !props.toggle
                  ? {
                      color: "#0b69ff",
                    }
                  : null
              }
              href="/bookings"
            >
              <div
                className={`${
                  props.toggle && pathName === "/bookings" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
              >
                <BookingIcon
                  color={pathName === "/bookings" ? "#0B69FF" : "#7E858E"}
                />
              </div>
              <span style={{ marginLeft: 25 }}>Booking</span>
            </a>
          </li>
          <li
            style={{ paddingBottom: props.toggle ? 22 : 0 }}
            className={
              pathName === "/statistics" && !props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
          >
            <a
              className="d-flex"
              style={
                pathName === "/statistics" && !props.toggle
                  ? {
                      color: "#0b69ff",
                    }
                  : null
              }
              href="/statistics"
            >
              <div
                className={`${
                  props.toggle && pathName === "/statistics" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
              >
                <StatsIcon
                  color={pathName === "/statistics" ? "#0B69FF" : "#7E858E"}
                />
              </div>

              <span style={{ marginLeft: 25 }}>Statistics</span>
            </a>
          </li>

          <li
            style={{ paddingBottom: props.toggle ? 22 : 0 }}
            className={
              pathName === "/students" && !props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
          >
            <a
              className="d-flex"
              style={
                pathName === "/students" && !props.toggle
                  ? {
                      color: "#0b69ff",
                    }
                  : null
              }
              href="/students"
            >
              <div
                className={`${
                  props.toggle && pathName === "/students" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
              >
                <Users
                  color={pathName === "/students" ? "#0B69FF" : "#7E858E"}
                />
              </div>

              <span style={{ marginLeft: 25 }}>Students</span>
            </a>
          </li>

          <li
            style={{ paddingBottom: props.toggle ? 22 : 0 }}
            className={
              pathName === "/majors" && !props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
          >
            <a
              className="d-flex"
              style={
                pathName === "/majors" && !props.toggle
                  ? {
                      color: "#0b69ff",
                    }
                  : null
              }
              href="/majors"
            >
              <div
                className={`${
                  props.toggle && pathName === "/majors" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
              >
                <BookingIcon
                  color={pathName === "/majors" ? "#0B69FF" : "#7E858E"}
                />
              </div>
              <span style={{ marginLeft: 25 }}>Majors</span>
            </a>
          </li>

          <li
            className={
              pathName === "/logout" && props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
            style={{ paddingBottom: !props.toggle ? 22 : 0 }}
          >
            <a className="d-flex" href="#" onClick={() => props.logout()}>
              <div
                className={`${
                  props.toggle && pathName === "/logout" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
                style={{ position: "relative", left: props.toggle ? -7.3 : 3 }}
              >
                <LogoutIcon
                  color={pathName === "/logout" ? "#0B69FF" : "#7E858E"}
                />
              </div>
              <span style={{ marginLeft: 25 }}>Logout</span>
            </a>
          </li>

          {/* <li
            style={{ paddingBottom: props.toggle ? 22 : 0 }}
            className={
              pathName === "/uapply" && !props.toggle
                ? "active"
                : props.toggle
                ? ""
                : "hover"
            }
          >
            <a
              className="d-flex"
              style={
                pathName === "/uapply" && !props.toggle
                  ? {
                      color: "#0b69ff",
                    }
                  : null
              }
              href="/uapply"
            >
              <div
                className={`${
                  props.toggle && pathName === "/uapply" ? "active" : ""
                } ${props.toggle ? "toggled-sidebar" : ""}`}
              >
                <ClassIcon
                  color={pathName === "/uapply" ? "#0B69FF" : "#7E858E"}
                />
              </div>

              <span style={{ marginLeft: 25 }}>Uapply</span>
            </a>
          </li> */}
        </ul>
      </aside>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps, { logout })(Header);
