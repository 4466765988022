import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormInput } from "../Components/FormInput";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  getCourses,
  getLevels,
  getPartners,
  getDaysPerWeek,
  createClass,
  getAgeGroup,
} from "../Services/Service";
import DropDownComponent from "../Components/DropDownComponent";
import TabComponent from "../Components/TabComponent";
import TimePicker from "../Components/TimePicker";
import DropDown from "../Components/DropDown";
import ButtonComponent from "../Components/ButtonComponent";
import moment from "moment-timezone";
import { db } from "../firebaseConfig";

export default function AddClassModal(props) {
  const [courses, setcourses] = useState([]);
  const [courseSelected, setcourseSelected] = useState(null);
  const [levels, setlevels] = useState([]);
  const [tabSelected, settabSelected] = useState(null);

  const [Starttime, setStarttime] = useState("");
  const [partners, setpartners] = useState([]);
  const [partnerSelected, setpartnerSelected] = useState(null);
  const [daysPerWeek, setdaysPerWeek] = useState([]);
  const [daysPerWeekSelected, setdaysPerWeekSelected] = useState(null);
  const [ageGroup, setageGroup] = useState([]);
  const [optionSelected, setoptionSelected] = useState(null);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState("");
  const [daysSelected, setdaysSelected] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const courses = await getCourses();
      const levels = await getLevels();
      const partner = await getPartners();
      const daysperweek = await getDaysPerWeek();
      const ageGroup = await getAgeGroup();
      setdaysPerWeek(daysperweek);
      setageGroup(ageGroup);
      setpartners(partner);
      setcourses(courses);
      setlevels(levels);
    }
    fetchData();
  }, []);

  const daysSelectedfn = (value) => {
    setdaysSelected([
      ...daysSelected,
      {
        value,
      },
    ]);
  };
  const ageData = ageGroup.map((x) => {
    const data = { name: x.name, value: x.id };
    return data;
  });
  var daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  function daysOfWeekSorter(x, y) {
    return daysOfWeek.indexOf(x) - daysOfWeek.indexOf(y);
  }

  return (
    <div>
      <Modal id="myModal" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{ email: "" }}
            validationSchema={Yup.object({
              zoomLink: Yup.string().required("Enter zoom link"),
              zoomPassword: Yup.string().required("Enter zoom password"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              const levelIds = optionSelected.map((x) => x.value);
              const availableDays = daysSelected.map((x) => x.value);
              const time = moment(Starttime, "HH:mm");

              const sortedDays = availableDays.sort(daysOfWeekSorter);
              if (
                !partnerSelected ||
                !courseSelected ||
                !tabSelected ||
                !levelIds ||
                !time ||
                !availableDays ||
                !daysPerWeekSelected
              ) {
                seterror(true);
                setloading(false);
              } else {
                setloading(true);
                await createClass({
                  optionAge: tabSelected.value,
                  optionAgeName: tabSelected.name,
                  level: levelIds,
                  course: courseSelected.id,
                  courseName: courseSelected.slug,
                  partner: partnerSelected.id,
                  partnerName: partnerSelected.name,
                  zoomLink: values.zoomLink,
                  zoomPassword: values.zoomPassword,
                  time: moment(time).format(),
                  status: false,
                  availableDays: sortedDays,
                  daysPerWeek: daysPerWeekSelected.id,
                });
                await db
                  .collection("clear_cache_home")
                  .doc("su3Zr6L4Q2d21lp0wDJL")
                  .update({
                    status: false,
                  });
                await db
                  .collection("clear_cache_pricing")
                  .doc("Ex02fRvGaBL5IGYjsbxT")
                  .update({
                    status: false,
                  });
                await db
                  .collection("clear_cache_checkout")
                  .doc("rk4HW8Rad7ju2jk7CEze")
                  .update({
                    status: false,
                  });
                await props.classUpdated();
                props.onHide();
                setloading(false);
              }
            }}
          >
            <Form>
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Course
                </span>

                <DropDown
                  data={courses}
                  error={error && !courseSelected}
                  errorMessage={error && !courseSelected ? "course" : null}
                  onClick={async (x) => {
                    setcourseSelected(x);
                  }}
                  courseIcon={courseSelected?.flag}
                  course
                  name={!courseSelected ? "Course" : courseSelected.slug}
                />
              </div>

              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Age
                </span>
                <TabComponent
                  data={ageData}
                  error={error && !tabSelected}
                  errorMessage={error && !tabSelected ? "Age" : null}
                  style={{
                    padding:
                      tabSelected?.value === "6135dfad4d92d9052971158c"
                        ? "0 49.5px"
                        : "0 50px",
                  }}
                  tabSelected={tabSelected}
                  settabSelected={(tabSelected) => {
                    settabSelected(tabSelected);
                  }}
                />
              </div>
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Level
                </span>
                <DropDownComponent
                  levels={levels}
                  error={error && !optionSelected}
                  errorMessage={error && !optionSelected ? "levels" : null}
                  handleChange={(selected) => {
                    setoptionSelected(selected);
                  }}
                />
              </div>

              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Partner
                </span>
                <DropDown
                  data={partners}
                  error={error && !partnerSelected}
                  errorMessage={error && !partnerSelected ? "partner" : null}
                  onClick={async (x) => {
                    setpartnerSelected(x);
                  }}
                  name={!partnerSelected ? "Partner" : partnerSelected.name}
                />
              </div>

              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Days Per Week
                </span>
                <DropDown
                  data={daysPerWeek}
                  error={error && !daysPerWeekSelected}
                  errorMessage={
                    error && !daysPerWeekSelected ? "days per week" : null
                  }
                  onClick={async (x) => {
                    setdaysPerWeekSelected(x);
                    setdaysSelected([]);
                  }}
                  menuStyle={{ height: 114 }}
                  name={
                    !daysPerWeekSelected
                      ? "Days Per Week"
                      : daysPerWeekSelected.name
                  }
                />
              </div>

              {daysPerWeekSelected ? (
                <div
                  className="flex-column d-flex"
                  style={{ marginBottom: 24 }}
                >
                  <span className="input-title d-flex justify-content-start align-items-center">
                    Days
                  </span>
                  <TabComponent
                    error={error && !daysSelected}
                    errorMessage={error && !daysSelected ? "days" : null}
                    data={[
                      { name: "Mon", value: "mon" },
                      { name: "Tue", value: "tue" },
                      { name: "Wed", value: "wed" },
                      { name: "Thu", value: "thu" },
                      { name: "Fri", value: "fri" },
                      { name: "Sat", value: "sat" },
                      { name: "Sun", value: "sun" },
                    ]}
                    style={{
                      padding: "0 17px",
                    }}
                    multi
                    tabSelected={daysSelected}
                    settabSelected={(a) => {
                      if (daysSelected.some((y) => y.value === a)) {
                        setdaysSelected(
                          daysSelected.filter((z) => z.value !== a)
                        );
                      } else {
                        if (
                          daysPerWeekSelected.daysPerWeek !==
                          daysSelected.length
                        ) {
                          daysSelectedfn(a);
                        }
                      }
                    }}
                  />
                </div>
              ) : null}
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Time
                </span>
                <TimePicker
                  text="00:00"
                  time={Starttime}
                  error={error && !Starttime}
                  errorMessage={error && !Starttime ? "time" : null}
                  data={(x) => {
                    setStarttime(x);
                  }}
                />
              </div>
              <FormInput
                placeholder="Zoom Link"
                name="zoomLink"
                type="text"
                style={{ marginBottom: 4 }}
                label="Zoom Link"
              />
              <FormInput
                placeholder="Zoom Password"
                name="zoomPassword"
                type="text"
                style={{ marginBottom: 4 }}
                label="Zoom Password"
              />

              <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                <ButtonComponent
                  disable={loading}
                  text="Create"
                  type="submit"
                  noOnclick
                />
                <ButtonComponent
                  text="Cancel"
                  style={{
                    marginLeft: 16,
                    color: "#3F4247",
                    backgroundColor: "#fff",
                    border: "1px solid #D7DFE9",
                  }}
                  onClick={() => props.onHide()}
                />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

// const style = {
//   box: {
//     background: "rgba(232, 233, 245, 0.25)",
//     border: "1px solid #E8E9F5",
//     boxSizing: "border-box",
//     padding: 20,
//     marginBottom: 16,
//     marginLeft: "-1em",
//     marginRight: "-1em",
//   },
//   name: {
//     fontFamily: "Public Sans",
//     fontStyle: "normal",
//     fontWeight: 600,
//     fontSize: 14,
//     color: "#333333",
//   },
//   text: {
//     fontFamily: "Public Sans",
//     fontStyle: "normal",
//     fontWeight: "normal",
//     fontSize: 14,
//     color: "#333333",
//   },
//   inputText: {
//     position: "absolute",
//     bottom: 0,
//     left: 0,
//     right: 0,
//     padding: 24,
//   },
//   title: {
//     fontFamily: "Public Sans",
//     fontStyle: "normal",
//     fontWeight: "normal",
//     fontSize: 14,
//     color: "#676767",
//   },
// };
