import React from "react";

function PlusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#7E858E"
        fillRule="evenodd"
        d="M7.25 7.25V2h1.5v5.25H14v1.5H8.75V14h-1.5V8.75H2v-1.5h5.25z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PlusIcon;
