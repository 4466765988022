import React from "react";
import { Button } from "react-bootstrap";

export default function ButtonComponent(props) {
  return (
    <Button
      disabled={props.disable}
      type={props.type}
      style={props.style}
      onClick={!props.noOnclick ? () => props.onClick() : null}
    >
      {props.text}
    </Button>
  );
}
