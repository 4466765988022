import React from "react";

function CalenderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M5.834 1.875c.345 0 .625.28.625.625v.833h7.083V2.5a.625.625 0 111.25 0v.833h1.042a2.5 2.5 0 012.5 2.5v10a2.5 2.5 0 01-2.5 2.5H4.167a2.5 2.5 0 01-2.5-2.5v-10a2.5 2.5 0 012.5-2.5h1.042V2.5c0-.345.28-.625.625-.625zm7.708 2.708v1.25a.625.625 0 101.25 0v-1.25h1.042c.69 0 1.25.56 1.25 1.25v2.709H2.917V5.833c0-.69.56-1.25 1.25-1.25h1.042v1.25a.625.625 0 101.25 0v-1.25h7.083zM2.917 9.792v6.041c0 .69.56 1.25 1.25 1.25h11.667c.69 0 1.25-.56 1.25-1.25V9.792H2.917z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CalenderIcon;
