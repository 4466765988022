import React from "react";

function SettingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      // className="icon-hover"
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M12 2a2 2 0 00-2 2c0 .148-.1.277-.243.319a7.951 7.951 0 00-1.601.664.335.335 0 01-.399-.054A2 2 0 104.93 7.757a.335.335 0 01.054.399c-.277.503-.5 1.04-.664 1.6A.334.334 0 014 10a2 2 0 100 4c.148 0 .277.1.319.243.163.562.387 1.098.664 1.601a.335.335 0 01-.054.399 2 2 0 002.828 2.828.335.335 0 01.399-.054c.503.277 1.04.5 1.601.664.142.042.243.17.243.319a2 2 0 104 0c0-.148.1-.277.243-.319a7.952 7.952 0 001.601-.664.335.335 0 01.399.054 2 2 0 002.828-2.828.335.335 0 01-.054-.399c.277-.503.5-1.04.664-1.6A.334.334 0 0120 14a2 2 0 100-4 .334.334 0 01-.319-.243 7.952 7.952 0 00-.664-1.601.335.335 0 01.054-.399 2 2 0 00-2.828-2.828.335.335 0 01-.399.054 7.952 7.952 0 00-1.6-.664A.334.334 0 0114 4a2 2 0 00-2-2zm0 13a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 14a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 100-8 4 4 0 000 8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SettingIcon;
