/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { FormCheck, Button } from "react-bootstrap";
import {
  deleteOptions,
  getDaysPerWeek,
  updateDiscount,
  updateOption,
} from "../Services/Service";
import DropDown from "./DropDown";
import DeleteModal from "../Models/DeleteModal";
import { db } from "../firebaseConfig";
import CheckoutModal from "../Models/CheckoutModal";

export default function OptionsTable(props) {
  const [daysPerWeek, setdaysPerWeek] = useState([]);
  const [daysPerWeekSelected, setdaysPerWeekSelected] = useState("");
  const [edit, setedit] = useState(false);
  const [price, setprice] = useState("");
  const [modalShow, setmodalShow] = useState(false);
  const [loading, setloading] = useState(false);
  const [optionId, setoptionId] = useState(null);
  const [status, setstatus] = useState("");
  const [discount, setdiscount] = useState("");
  const [discountAmount, setdiscountAmount] = useState([]);
  const [pricesArray, setpricesArray] = useState([]);
  const [originalPrice, setoriginalPrice] = useState("");
  const [selectedOpt, setselectedOpt] = useState({});
  const [discountPrice, setdiscountPrice] = useState("");

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const daysperweek = await getDaysPerWeek();
      setdaysPerWeek(daysperweek);
    }
    fetchData();
  }, []);

  const handleDiscountChange = (data, e) => {
    let originalData = data.discountAmount;
    const updatedData = originalData.map((x) =>
      x.discount === props.selectedDiscountType?.id &&
      props.selectedCurrency?.id === x.currency
        ? { ...x, price: parseInt(e.target.value) }
        : x
    );

    setdiscountAmount(updatedData);
    setdiscountPrice(parseInt(e.target.value));
    // setdiscount();
  };

  const handlePriceChange = (data, original, e) => {
    let originalData = data;
    let updatedData = [];
    if (original) {
      updatedData = originalData.map((x) =>
        x.currency === props.selectedCurrency?.id
          ? {
              ...x,
              price: x.price,
              currency: props.selectedCurrency.id,
              originalPrice: parseInt(e.target.value),
            }
          : x
      );
    } else {
      updatedData = originalData.map((x) =>
        x.currency === props.selectedCurrency?.id
          ? {
              ...x,
              currency: props.selectedCurrency.id,
              price: parseInt(e.target.value),
              originalPrice: x.originalPrice,
            }
          : x
      );
    }
    setpricesArray(updatedData);
  };

  return (
    <div>
      {props.tableData.length ? (
        <div className="table-responsive-xl options-table ">
          <table className="table table-bordered">
            <thead>
              <tr>
                {props.header?.map((x) => (
                  <th className="table-option-header">{x}</th>
                ))}
              </tr>
            </thead>
            <tbody className="option-table">
              {props.tableData.map((x, key) => (
                <>
                  {edit !== x.id ? (
                    <tr
                      key={key}
                      className="option-table"
                      style={{
                        background: "#FFFFFF",
                        boxShadow: "inset 0px -1px 0px #D7DFE9",
                      }}
                    >
                      <td className="option-table">
                        {daysPerWeek.map((a) => {
                          if (a.id === x.daysPerWeek) {
                            return a.name;
                          }
                        })}
                      </td>

                      <td className="option-table">{x.hrsPerday}</td>
                      {props.bulkEdit ? (
                        <td className="option-table">{x.duration.name}</td>
                      ) : null}
                      {props.discount ? null : (
                        <td className="option-table">
                          {x.price.map((a) => {
                            // console.log("jkbhjgfd", a);
                            if (props.selectedCurrency?.id === a.currency) {
                              return a.originalPrice;
                            }
                          })}
                        </td>
                      )}
                      <td className="option-table">
                        {props.discount
                          ? x.discountAmount.map((a) => {
                              // console.log(a);
                              if (
                                props.selectedDiscountType?.id === a.discount &&
                                props.selectedCurrency?.id === a.currency
                              ) {
                                return a.price;
                              }
                            })
                          : x.price.map((a) => {
                              // console.log("jkbhjgfd", a);
                              if (props.selectedCurrency?.id === a.currency) {
                                return a.price;
                              }
                            })}
                      </td>
                      <td className="option-table">
                        {" "}
                        <FormCheck custom type="switch">
                          <FormCheck.Input disabled={true} checked={x.status} />
                        </FormCheck>
                      </td>
                      {props.checkoutLink ? (
                        <td>
                          <span
                            style={{
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: 14,
                              color: "#0B69FF",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setselectedOpt(x);
                              setmodalShow("checkout");
                            }}
                          >
                            Book
                          </span>
                        </td>
                      ) : (
                        <td className="option-table">
                          <span
                            style={{
                              fontStyle: "normal",
                              fontWeight: 600,
                              fontSize: 14,
                              color: "#0B69FF",
                            }}
                            onClick={() => {
                              const price = x.price.filter((a) => {
                                if (props.selectedCurrency?.id === a.currency) {
                                  return a.price;
                                }
                              });
                              setoriginalPrice(price[0]?.originalPrice);
                              setprice(price[0]?.price);
                              // console.log(price);
                              if (props.discount) {
                                const data = x.discountAmount.filter((a) => {
                                  if (
                                    props.selectedDiscountType.id ===
                                      a.discount &&
                                    props.selectedCurrency?.id === a.currency
                                  ) {
                                    return a;
                                  }
                                });
                                setdiscount(data[0]?.price);
                                // setedit(x.id);
                              }
                              setstatus(x.status);
                              const days = daysPerWeek.filter((a) => {
                                if (x.daysPerWeek === a.id) {
                                  return a.name;
                                }
                              });
                              setdaysPerWeekSelected(days[0]);
                              setedit(x.id);
                            }}
                          >
                            Edit
                          </span>
                        </td>
                      )}
                    </tr>
                  ) : (
                    <tr
                      className="option-table"
                      style={{
                        background: "rgba(11, 105, 255, 0.1)",
                        boxShadow: "inset 0px -1px 0px #D7DFE9",
                      }}
                    >
                      <td className="option-table">
                        <DropDown
                          data={daysPerWeek}
                          toggleClass
                          onClick={async (a) => {
                            setdaysPerWeekSelected(a);
                          }}
                          menuStyle={{ height: 114 }}
                          name={
                            !daysPerWeekSelected ? "" : daysPerWeekSelected.name
                          }
                        />
                      </td>
                      <td className="option-table">
                        <input
                          className="input"
                          disabled
                          // onChange={(e) => setdaysPerWeek(e.target.value)}
                          value={x.hrsPerday}
                        />
                      </td>
                      {props.bulkEdit ? (
                        <td className="option-table">{x.duration.name}</td>
                      ) : null}
                      {props.discount ? null : (
                        <td className="option-table">
                          <input
                            className="input"
                            type="number"
                            onChange={(e) => {
                              let original = true;

                              setoriginalPrice(e.target.value);
                              handlePriceChange(
                                pricesArray.length ? pricesArray : x.price,
                                original,
                                e
                              );
                            }}
                            value={originalPrice}
                          />
                        </td>
                      )}
                      <td className="option-table">
                        {" "}
                        {props.discount ? (
                          <input
                            className="input"
                            type="number"
                            onChange={async (e) => {
                              setdiscount(e.target.value);

                              handleDiscountChange(x, e);
                            }}
                            value={discount}
                          />
                        ) : (
                          <input
                            className="input"
                            type="number"
                            onChange={(e) => {
                              let original = false;

                              setprice(e.target.value);
                              handlePriceChange(
                                pricesArray.length ? pricesArray : x.price,
                                original,
                                e
                              );
                            }}
                            value={price}
                          />
                        )}
                      </td>

                      <td className="option-table">
                        {" "}
                        <FormCheck custom type="switch">
                          <FormCheck.Input
                            disabled={props.discount ? true : loading}
                            onChange={async () => {
                              setstatus(!status);
                              // await updateOption(x.id, {
                              //   status: !status,
                              // });
                              // await db
                              //   .collection("clear_cache_price_update")
                              //   .doc("qoK4Mpz0lwoEKadSnYZW")
                              //   .update({
                              //     status: false,
                              //   });
                            }}
                            checked={status}
                          />
                        </FormCheck>
                      </td>
                      <td className="option-table">
                        <Button
                          disabled={loading}
                          style={{ padding: "2px 15px" }}
                          onClick={async () => {
                            setloading(true);
                            if (props.discount) {
                              await updateDiscount(x.id, {
                                course: props.courseSelected.name,
                                discountPrice: discountPrice,
                                type: props.selectedType.name,
                                discountType: props.selectedDiscountType.name,
                                duration: props.selectedDuration.name,
                                daysPerWeek: daysPerWeekSelected.name,
                                currency: props.selectedCurrency?.symbol,
                                hoursPerDay: x.hrsPerday,
                                discountAmount,
                              });
                              await db
                                .collection("clear_cache_home")
                                .doc("su3Zr6L4Q2d21lp0wDJL")
                                .update({
                                  status: false,
                                });
                              await db
                                .collection("clear_cache_pricing")
                                .doc("Ex02fRvGaBL5IGYjsbxT")
                                .update({
                                  status: false,
                                });
                              await db
                                .collection("clear_cache_checkout")
                                .doc("rk4HW8Rad7ju2jk7CEze")
                                .update({
                                  status: false,
                                });
                              await props.optionUpdated(true);
                              setdaysPerWeekSelected("");
                              setdiscount("");
                              setloading(false);
                              setedit(false);
                            } else {
                              await updateOption(x.id, {
                                course: props.courseSelected.name,
                                newPrice: price,
                                neworiginalPrice: originalPrice,
                                type: props.selectedType.name,
                                duration: props.selectedDuration.name,
                                daysPerWeek: daysPerWeekSelected.name,
                                currency: props.selectedCurrency?.symbol,
                                hoursPerDay: x.hrsPerday,
                                price: !pricesArray.length
                                  ? x.price
                                  : pricesArray,
                                status: status,
                              });
                              await db
                                .collection("clear_cache_home")
                                .doc("su3Zr6L4Q2d21lp0wDJL")
                                .update({
                                  status: false,
                                });
                              await db
                                .collection("clear_cache_pricing")
                                .doc("Ex02fRvGaBL5IGYjsbxT")
                                .update({
                                  status: false,
                                });
                              await db
                                .collection("clear_cache_checkout")
                                .doc("rk4HW8Rad7ju2jk7CEze")
                                .update({
                                  status: false,
                                });
                              await props.optionUpdated(false);
                              setdaysPerWeekSelected("");
                              setpricesArray([]);
                              setloading(false);
                              setedit(false);
                              // console.log(pricesArray);
                            }
                          }}
                        >
                          Save
                        </Button>
                        {props.discount ? null : (
                          <Button
                            disabled={loading}
                            style={{
                              padding: "2px 8px",
                              backgroundColor: "red",
                              borderColor: "red",
                              marginLeft: 5,
                            }}
                            onClick={async () => {
                              setoptionId(x.id);
                              setmodalShow("delete");
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}

      {modalShow === "delete" ? (
        <DeleteModal
          show={modalShow}
          name="Option"
          onHide={() => setmodalShow(false)}
          onClick={async () => {
            await deleteOptions(optionId);
            await props.optionUpdated();
          }}
        />
      ) : null}

      {modalShow === "checkout" ? (
        <CheckoutModal
          show={modalShow}
          selectedOpt={selectedOpt}
          selectedType={props.selectedType}
          selectedCurrency2={props.selectedCurrency2}
          courseSelected={props.courseSelected}
          name="Option"
          onHide={() => setmodalShow(false)}
          onClick={async () => {
            await deleteOptions(optionId);
            await props.optionUpdated();
          }}
        />
      ) : null}
    </div>
  );
}
