import { Formik, Form } from "formik";
import React, { useState } from "react";
import { FormInput } from "../Components/FormInput";
import * as Yup from "yup";
import logo from "../Assets/Icons/logo.svg";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { login } from "../Actions/AuthActions";
import { Navigate } from "react-router-dom";

function Login(props) {
  const [loading, setloading] = useState(false);
  var authToken = localStorage.getItem("authToken");

  if (props.isLoggedIn && authToken) {
    return <Navigate to="/classes" />;
  }
  return (
    <div
      className="row"
      style={{
        backgroundColor: "#F9FBFD",
        flexDirection: "column",
        overflow: "hidden",
        height: "100vh",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{ marginTop: 60 }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <img src={logo} alt="icon" />

        <div
          style={{
            width: 125,
            height: 28,
            background: "#E8E9F5",
            borderRadius: 14,
            marginTop: 16,
            fontFamily: "Cairo",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 14,
            color: "#676767",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          ⚙️ Backoffice
        </div>
      </div>

      <Formik
        enableReinitialize={true}
        initialValues={{ email: "" }}
        validationSchema={Yup.object({
          //   email: Yup.string().required("Enter Email"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setloading(true);

          await props.login({
            email: values.email,
            password: values.password,
          });
          setloading(false);
        }}
      >
        <div className="login-card col-md-4 col-md-offset-4">
          <div
            style={{ marginBottom: 40 }}
            className="d-flex justify-content-center align-items-center"
          >
            <span
              style={{
                fontFamily: "Cairo",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: 30,
                textAlign: "center",
                color: "#333333",
              }}
            >
              Sign In
            </span>
          </div>
          <Form>
            <FormInput noLabel placeholder="Email" name="email" type="text" />
            <FormInput name="password" type="password" placeholder="Password" />
            <Button
              type="submit"
              disabled={loading}
              style={{
                width: "100%",
                height: 52,
                marginTop: 40,
                background: "#299CF7",
                borderRadius: 8,
              }}
            >
              Sign In
            </Button>
          </Form>
        </div>
      </Formik>
      <div>{/* <img src={logo} alt='icon'/> */}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps, { login })(Login);
