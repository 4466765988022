import {  persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import Auth from './AuthReducer';
import Data from "./DataReducer";
// import loading from './LoadingReducer'
// import bugsnag from './bugsnagReducer'

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
   };

   export default persistCombineReducers(persistConfig,{
    auth: Auth,
    data: Data,
  });

 