import React from "react";

function ArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="6"
      fill="none"
      viewBox="0 0 7 6"
    >
      <path
        fill="#3F4247"
        d="M.143.199L1.515.195l2.043 4.53L5.52.183 6.863.18 4.136 5.955l-1.19.004L.143.199z"
      ></path>
    </svg>
  );
}

export default ArrowDown;
