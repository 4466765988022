import debounce from "debounce";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import SearchIcon from "../Assets/Icons/SearchIcon";
import DashboardCard from "../Components/DashboardCard/DashboardCard";
import Loader from "../Components/Loader/Loader";
import PaginationComponent from "../Components/PaginationComponent/PaginationComponent";
import PlacementTable from "../Components/PlacementTable";
import { getPlacements, searchPlacements } from "../Services/Service";
import Icon1 from "../Assets/Icons/Icon1.svg";
import ButtonComponent from "../Components/ButtonComponent";

export default function Placement() {
  const [placements, setplacements] = useState([]);
  const [totalPage, settotalPage] = useState("");
  const [page, setpage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [totalPlacements, settotalPlacements] = useState(0);
  const [limit, setlimit] = useState(10);
  const [loading, setloading] = useState(false);
  const [selstudentArr, setselstudentArr] = useState([]);

  useEffect(() => {
    async function fetchData() {
      // You can await hereby

      const res = await getPlacements({
        sortBy: "createdDate:desc",
        limit: limit,
        page: page,
      });

      settotalPlacements(res.totalResults);
      setplacements(res.results);
      settotalPage(res.totalPages || null);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = debounce(async (data) => {
    setsearchText(data.text);
    setloading(true);
    const res = await searchPlacements(
      { text: data.text },
      {
        sortBy: "createdDate:desc",
        limit: limit,
        page: data.page,
      }
    );
    settotalPage(res.totalPages || null);
    setplacements(res.results);
    setloading(false);
  }, 500);

  const getPlacementsData = async (data) => {
    setloading(true);
    const res = await getPlacements({
      sortBy: "createdDate:desc",
      limit: data,
      page: 1,
    });
    setpage(1);
    settotalPlacements(res.totalResults);
    setplacements(res.results);
    settotalPage(res.totalPages || null);
    setloading(false);
  };

  return (
    <div>
      <Row style={{ paddingTop: 24 }}>
        <DashboardCard
          text={totalPlacements
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          subtext="Total Placement test"
          icon={Icon1}
          color={"#299CF7"}
        />
        <Col md={8} className="d-flex justify-content-end">
          <div>
            <ButtonComponent
              onClick={() => {
                let phoneNos;
                let phone;
                if (selstudentArr.length) {
                  phoneNos = selstudentArr.map((x) =>
                    `${x.countryCode}${x.phoneNumber}`.replace(
                      /[^A-Z0-9]/gi,
                      ""
                    )
                  );
                  phone = [...new Set(phoneNos)];
                } else {
                  phoneNos = placements.map((x) =>
                    `${x.countryCode}${x.phoneNumber}`.replace(
                      /[^A-Z0-9]/gi,
                      ""
                    )
                  );
                  phone = [...new Set(phoneNos)];
                }

                navigator.clipboard.writeText(phone).then(
                  function () {
                    alert(" Copying to clipboard was successful!");
                  },
                  function (err) {
                    console.error("Async: Could not copy text: ", err);
                  }
                );
              }}
              text="Export Numbers"
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-row " style={{ position: "relative" }}>
        <div className="search-icon">
          <SearchIcon />
        </div>
        <input
          placeholder="Input Text"
          name="name"
          onChange={debounce(async (e) => {
            if (e.target.value === "") {
              const res = await getPlacements({
                sortBy: "createdDate:desc",
                limit: limit,
                page: page,
              });
              setplacements(res.results);
              settotalPage(res.totalPages || null);
            } else {
              setsearchText(e.target.value);

              const res = await searchPlacements(
                { text: e.target.value },
                {
                  status: "Paid",
                  sortBy: "createdDate:desc",
                  limit: limit,
                  page: page,
                }
              );
              settotalPage(res.totalPages || null);
              setplacements(res.results);
            }
          }, 500)}
          type="text"
          className="search-input"
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <PlacementTable
          header={[
            "Select",
            "Name",
            "Email",
            "Phone #",
            "DOB",
            "Test Type / Level",
          ]}
          tableData={!placements ? [] : placements}
          setstudentsArr={setselstudentArr}
          studentsArr={selstudentArr}
          // onClick={(x) => {
          //   setselectedBooking(x);
          //   setmodalShow(true);
          // }}
        />
      )}

      {placements.length ? (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Dropdown>
              <Dropdown.Toggle
                className="dd-custom-toggle"
                id="dropdown-custom-components"
                style={{ color: "#000" }}
              >
                {limit}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{ minWidth: "max-content" }}
                className="dd-custom-menu"
              >
                <Dropdown.Item
                  target="_blank"
                  className="dd-custom-items"
                  onClick={() => {
                    getPlacementsData(50);
                    setlimit(50);
                  }}
                >
                  50
                </Dropdown.Item>

                <Dropdown.Item
                  target="_blank"
                  className="dd-custom-items"
                  onClick={() => {
                    getPlacementsData(100);
                    setlimit(100);
                  }}
                >
                  100
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <PaginationComponent
            page={parseInt(page)}
            totalPage={totalPage}
            onPageClick={async (x) => {
              if (searchText) {
                search({ page: x, text: searchText });
              } else {
                setloading(true);
                const res = await getPlacements({
                  sortBy: "createdDate:desc",
                  limit: limit,
                  page: x,
                });
                setplacements(res.results);
                settotalPage(res.totalPages || null);
                setloading(false);
              }
              setpage(x);
            }}
            onChange={async () => {
              if (searchText) {
                search({ page: page + 1, text: searchText });
              } else {
                setloading(true);
                if (placements.length === limit) {
                  const res = await getPlacements({
                    sortBy: "createdDate:desc",
                    limit: limit,
                    page: page + 1,
                  });

                  setplacements(res.results);
                  settotalPage(res.totalPages || null);
                }
                setloading(false);
              }
              setpage(parseInt(page) + 1);
            }}
            onChangePrev={async () => {
              if (searchText) {
                search({ page: page - 1, text: searchText });
              } else {
                setloading(true);
                const res = await getPlacements({
                  sortBy: "createdDate:desc",
                  limit: limit,
                  page: page - 1,
                });
                setplacements(res.results);
                settotalPage(res.totalPages || null);
                setloading(false);
              }
              setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);
            }}
          />
          <div />
        </div>
      ) : null}
    </div>
  );
}
