import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.894 14.009a7.5 7.5 0 10-.884.884l2.215 2.215a.625.625 0 00.884-.884l-2.215-2.215zm.523-4.843a6.25 6.25 0 11-12.5 0 6.25 6.25 0 0112.5 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SearchIcon;
