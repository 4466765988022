import React, { Component } from "react";
import PaginationLeft from "../../Assets/Icons/PaginationLeft.svg";
import PaginationRight from "../../Assets/Icons/PaginationRight.svg";

export default class PaginationComponent extends Component {
  state = { selected: this.props.page };
  render() {
    const totalPage = new Array(this.props.totalPage);
    for (var i = 0; i < this.props.totalPage; i++) {
      totalPage[i] = (i + 1).toString();
    }
    // console.log(this.props.page);

    return (
      <div className="w-100 d-flex justify-content-center">
        {/* <span className="next_container d-flex justify-content-center align-items-center" style={{marginRight:12.5}}>-</span> */}
        <img
          onClick={() => this.props.onChangePrev()}
          className="next_container"
          src={PaginationLeft}
          alt="icon"
          style={{ marginRight: 12.5, cursor: "pointer" }}
        />
        <div className="pagination_con d-flex justify-content-between align-items-center">
          {totalPage
            .filter((a) => a <= 10)
            .map((x, key) => (
              <span
                key={key}
                onClick={() => this.props.onPageClick(x)}
                className={
                  // eslint-disable-next-line eqeqeq
                  this.props.page == x || (this.props.page > 10 && x == 10)
                    ? "page_selected d-flex justify-content-center align-items-center"
                    : "pagination-page d-flex justify-content-center align-items-center"
                }
              >
                {this.props.page > 10 && x === 10 ? this.props.page : x}
              </span>
            ))}
        </div>

        <img
          onClick={() => {
            if (this.props.totalPage !== this.props.page) {
              this.props.onChange();
            }
          }}
          className="next_container"
          src={PaginationRight}
          alt="icon"
          style={{ marginLeft: 12.5, cursor: "pointer" }}
        />
        {/* <span className="next_container d-flex justify-content-center align-items-center" style={{marginLeft:12.5}}>+</span> */}
      </div>
    );
  }
}
