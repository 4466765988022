/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DropDown from "../Components/DropDown";
import { FormCheck } from "react-bootstrap";

import {
  getCountries,
  getCourses,
  getDurations,
  getTypes,
  getOptions,
  getDiscountTypes,
  getCoupons,
  getCurrencies,
  getReviewEmails,
} from "../Services/Service";
import OptionsTable from "../Components/OptionsTable";
import AddOptions from "../Models/AddOptions";
import PlusIcon from "../Assets/Icons/PlusIcon";
import AddDiscount from "../Models/AddDiscount";
import AddCoupon from "../Models/AddCoupon";
import CouponTable from "../Components/CouponTable";
import CalenderIcon from "../Assets/Icons/CalenderIcon";
import DiscountIcon from "../Assets/Icons/DiscountIcon";
import EditCoupon from "../Models/EditCoupon";
import EditWelcomeEmail from "../Models/EditWelcomeEmail";
import SendWelcomeEmail from "../Models/SendWelcomeEmail";
import { Alert } from "react-bootstrap";
import ReviewEmail from "../Components/ReviewEmail";

function Setting(props) {
  const [courses, setcourses] = useState("");
  const [courseSelected, setcourseSelected] = useState("");
  const [courseSelected2, setcourseSelected2] = useState("");
  const [durations, setdurations] = useState("");
  const [selectedDuration, setselectedDuration] = useState("");
  const [selectedDuration2, setselectedDuration2] = useState("");
  const [currencies, setcurrencies] = useState([]);
  const [countries, setcountries] = useState([]);
  const [selectedCurrency, setselectedCurrency] = useState(null);
  const [selectedCurrency2, setselectedCurrency2] = useState(null);
  const [options, setoptions] = useState([]);
  const [types, settypes] = useState([]);
  const [selectedCoupon, setselectedCoupon] = useState("");
  const [show, setShow] = useState(false);
  const [selectedType, setselectedType] = useState({
    id: "6124bf6f703f370d8cbf2c11",
    name: "group",
    name_ar: "حصص جماعية",
    slug: "group",
  });
  const [selectedType2, setselectedType2] = useState({
    id: "6124bf6f703f370d8cbf2c11",
    name: "group",
    name_ar: "حصص جماعية",
    slug: "group",
  });
  const [selectedTypeDiscount, setselectedTypeDiscount] = useState("");
  const [selectedOption, setselectedOption] = useState([]);
  const [modalShow, setmodalShow] = useState(false);
  const [discountTypes, setdiscountTypes] = useState([]);
  const [selectedDiscountType, setselectedDiscountType] = useState("");
  const [selectedOptionDiscount, setselectedOptionDiscount] = useState();
  const [reviewEmails, setreviewEmails] = useState([]);
  const [coupons, setcoupons] = useState([]);
  const [selectedEmail, setselectedEmail] = useState({});
  const [bulkEdit, setbulkEdit] = useState(false);
  const [bulkEditDiscounts, setbulkEditDiscounts] = useState(false);
  var authToken = localStorage.getItem("authToken");

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const reviewEmail = await getReviewEmails();

      setreviewEmails(reviewEmail);
      const duration = await getDurations();
      const currencies = await getCurrencies();
      const types = await getTypes();
      const coupon = await getCoupons();
      const courses = await getCourses();
      const countries = await getCountries();
      setcourses(courses);
      setcoupons(coupon);
      const discountTypes = await getDiscountTypes();
      const opt = await getOptions();
      setdiscountTypes(discountTypes);
      setoptions(opt);
      settypes(types);
      setdurations(duration);
      setcountries(countries);
      setcurrencies(currencies);
    }
    fetchData();
  }, []);
  const discountUpdated = async () => {
    const discountTypes = await getDiscountTypes();
    setdiscountTypes(discountTypes);
  };
  const couponUpdated = async () => {
    const coupon = await getCoupons();
    setcoupons(coupon);
  };

  const emailUpdated = async () => {
    const email = await getReviewEmails();
    setreviewEmails(email);
  };
  const renderOptions = async (duration, type, course, discount) => {
    const optionData = options
      .filter((op) => {
        return duration.id === op.duration.id;
      })
      .filter((op) => {
        return course.id === op.course;
      })
      .filter((op) => {
        return type.id === op.type;
      });
    if (discount) {
      setselectedOptionDiscount(optionData);
    } else {
      setselectedOption(optionData);
    }
  };
  const renderOptionsBulk = async (type, course, discount) => {
    const optionData = options
      .filter((op) => {
        return course.id === op.course;
      })
      .filter((op) => {
        return type.id === op.type;
      });

    if (discount) {
      setselectedOptionDiscount(optionData);
    } else {
      setselectedOption(optionData);
    }
  };

  if (!props.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  const optionUpdated = async (discount) => {
    const opt = await getOptions();
    setoptions(opt);
    if (bulkEdit || bulkEditDiscounts) {
      const optionData = opt
        .filter((op) => {
          return courseSelected.id === op.course;
        })
        .filter((op) => {
          let type = selectedType;
          if (discount) {
            type = selectedTypeDiscount;
          }

          return type.id === op.type;
        });
      if (discount) {
        setselectedOptionDiscount(optionData);
      } else {
        setselectedOption(optionData);
      }
    } else {
      const optionData = opt
        .filter((op) => {
          return selectedDuration.id === op.duration.id;
        })
        .filter((op) => {
          return courseSelected.id === op.course;
        })
        .filter((op) => {
          let type = selectedType;
          if (discount) {
            type = selectedTypeDiscount;
          }

          return type.id === op.type;
        });
      if (discount) {
        setselectedOptionDiscount(optionData);
      } else {
        setselectedOption(optionData);
      }
    }
  };
  // eslint-disable-next-line no-unused-vars
  let discount = false;

  return (
    <div
      style={{
        background: "#F4F6FA",
        overflowY: "hidden",
        height: "100vh",
      }}
    >
      <Tabs
        defaultActiveKey="prices"
        transition={false}
        id="noanim-tab-example"
      >
        <Tab eventKey="prices" title="Prices">
          <div
            className="d-flex flrx-row align-items-center"
            style={{ paddingBottom: 10 }}
          >
            <span style={{ paddingRight: 8 }} className="table-option-header">
              Bulk Edit
            </span>
            <FormCheck custom type="switch">
              <FormCheck.Input
                onClick={() => {
                  setbulkEdit(!bulkEdit);

                  if (!bulkEdit) {
                    renderOptionsBulk(selectedType, courseSelected);
                  } else {
                    renderOptions(
                      selectedDuration,
                      selectedType,
                      courseSelected
                    );
                  }
                }}
                checked={bulkEdit}
              />
            </FormCheck>
          </div>
          <>
            <div className="d-flex flex-row">
              <DropDown
                data={!courses ? [] : courses}
                ddStyle={{ width: 180, marginRight: 24 }}
                onClick={async (x) => {
                  setcourseSelected(x);
                  if (bulkEdit) {
                    renderOptionsBulk(selectedType, x);
                  } else {
                    renderOptions(selectedDuration, selectedType, x);
                  }
                }}
                name={!courseSelected ? "Course" : courseSelected?.slug}
                courseIcon={courseSelected.flag}
                course
              />
              {bulkEdit ? null : (
                <DropDown
                  ddStyle={{ width: 180, marginRight: 24 }}
                  data={!durations ? [] : durations}
                  onClick={async (x) => {
                    setselectedDuration(x);
                    renderOptions(x, selectedType, courseSelected);
                  }}
                  icon={<CalenderIcon />}
                  name={!selectedDuration ? "Duration" : selectedDuration.slug}
                />
              )}
              <DropDown
                data={!currencies ? [] : currencies}
                ddStyle={{ width: 180, marginRight: 24 }}
                onClick={async (x) => {
                  setselectedCurrency(x);
                  if (bulkEdit) {
                    renderOptionsBulk(selectedType, courseSelected);
                  } else {
                    renderOptions(
                      selectedDuration,
                      selectedType,
                      courseSelected
                    );
                  }
                }}
                countryIcon={selectedCurrency?.flag}
                country
                name={
                  !selectedCurrency ? "Currency" : selectedCurrency.currency
                }
              />
              <div className="radiobuttons d-flex align-items-center justify-content-center flex-row">
                {/* <div className="rdio rdio-primary "> */}
                {types.map((x, key) => (
                  <label
                    className="rdio rdio-primary"
                    style={key === 0 ? { marginRight: 16 } : null}
                  >
                    <input
                      type="radio"
                      checked={selectedType.id === x.id}
                      onChange={(e) => {
                        setselectedType(x);
                        if (bulkEdit) {
                          renderOptionsBulk(x, courseSelected);
                        } else {
                          renderOptions(selectedDuration, x, courseSelected);
                        }
                      }}
                    />
                    <span className="radio-text"> {x.name}</span>
                  </label>
                ))}
                {/* </div> */}
              </div>
            </div>
            <div style={{ paddingTop: 25 }}>
              <OptionsTable
                selectedCurrency={!selectedCurrency ? null : selectedCurrency}
                header={
                  bulkEdit
                    ? [
                        "Days/week",
                        "Hours/day",
                        "Duration",
                        "Original Price",
                        "Price",
                        "Status",
                      ]
                    : [
                        "Days/week",
                        "Hours/day",
                        "Original Price",
                        "Price",
                        "Status",
                      ]
                }
                bulkEdit={bulkEdit}
                selectedDuration={selectedDuration}
                selectedType={selectedType}
                selectedDiscountType={selectedDiscountType}
                courseSelected={courseSelected}
                tableData={!selectedCurrency ? [] : selectedOption}
                optionUpdated={(data) => optionUpdated((discount = data))}
              />
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setmodalShow("option")}
            >
              <PlusIcon />
              <span style={{ marginLeft: 4 }} className="secondry-text">
                Create new price
              </span>
            </div>
          </>
        </Tab>
        <Tab eventKey="discount" title="Discount">
          <div
            className="d-flex flrx-row align-items-center"
            style={{ paddingBottom: 10 }}
          >
            <span style={{ paddingRight: 8 }} className="table-option-header">
              Bulk Edit
            </span>{" "}
            <FormCheck custom type="switch">
              <FormCheck.Input
                onClick={() => {
                  setbulkEditDiscounts(!bulkEditDiscounts);
                  if (!bulkEditDiscounts) {
                    renderOptionsBulk(
                      selectedTypeDiscount,
                      courseSelected,
                      (discount = true)
                    );
                  } else {
                    renderOptions(
                      selectedDuration,
                      selectedTypeDiscount,
                      courseSelected,
                      (discount = true)
                    );
                  }
                }}
                checked={bulkEditDiscounts}
              />
            </FormCheck>
          </div>
          <>
            <div className="d-flex flex-row">
              <DropDown
                data={!courses ? [] : courses}
                ddStyle={{ width: 180, marginRight: 24 }}
                menuStyle={{
                  height: "auto",
                }}
                onClick={async (x) => {
                  setcourseSelected(x);
                  if (bulkEditDiscounts) {
                    renderOptionsBulk(
                      selectedTypeDiscount,
                      x,
                      (discount = true)
                    );
                  } else {
                    renderOptions(
                      selectedDuration,
                      selectedTypeDiscount,
                      x,
                      (discount = true)
                    );
                  }
                }}
                courseIcon={courseSelected.flag}
                course
                name={!courseSelected ? "Course" : courseSelected.slug}
              />
              {bulkEditDiscounts ? null : (
                <DropDown
                  ddStyle={{ width: 180, marginRight: 24 }}
                  data={!durations ? [] : durations}
                  onClick={async (x) => {
                    setselectedDuration(x);
                    if (selectedDiscountType) {
                      renderOptions(
                        x,
                        selectedTypeDiscount,
                        courseSelected,
                        (discount = true)
                      );
                    }
                  }}
                  icon={<CalenderIcon />}
                  name={!selectedDuration ? "Duration" : selectedDuration.slug}
                />
              )}
              <DropDown
                data={!currencies ? [] : currencies}
                ddStyle={{ width: 180, marginRight: 24 }}
                onClick={async (x) => {
                  setselectedCurrency(x);
                  if (bulkEditDiscounts) {
                    renderOptionsBulk(
                      selectedTypeDiscount,
                      courseSelected,
                      (discount = true)
                    );
                  } else {
                    renderOptions(
                      selectedDuration,
                      selectedTypeDiscount,
                      courseSelected,
                      (discount = true)
                    );
                  }

                  // setselectedOption([]);
                }}
                countryIcon={selectedCurrency?.flag}
                country
                name={
                  !selectedCurrency ? "Currency" : selectedCurrency.currency
                }
              />
              <DropDown
                ddStyle={{ width: 180, marginRight: 24 }}
                discount
                data={discountTypes}
                menuStyle={{
                  height: "auto",
                }}
                icon={<DiscountIcon />}
                onClick={async (x) => {
                  setselectedDiscountType(x);
                  if (bulkEditDiscounts) {
                    renderOptionsBulk(
                      selectedTypeDiscount,
                      courseSelected,
                      (discount = true)
                    );
                  } else {
                    renderOptions(
                      selectedDuration,
                      selectedTypeDiscount,
                      courseSelected,
                      (discount = true)
                    );
                  }
                }}
                onClickAddDiscount={() => setmodalShow("discount")}
                name={
                  !selectedDiscountType
                    ? "Discount Type"
                    : selectedDiscountType.name
                }
              />
              <div className="radiobuttons d-flex align-items-center justify-content-center flex-row">
                {/* <div className="rdio rdio-primary "> */}
                {types.map((x, key) => (
                  <label
                    className="rdio rdio-primary"
                    style={key === 0 ? { marginRight: 16 } : null}
                  >
                    <input
                      type="radio"
                      checked={selectedTypeDiscount.id === x.id}
                      onChange={(e) => {
                        setselectedTypeDiscount(x);
                        if (bulkEditDiscounts) {
                          renderOptionsBulk(
                            x,
                            courseSelected,
                            (discount = true)
                          );
                        } else {
                          renderOptions(
                            selectedDuration,
                            x,
                            courseSelected,
                            (discount = true)
                          );
                        }
                      }}
                    />
                    <span className="radio-text"> {x.name}</span>
                  </label>
                ))}
                {/* </div> */}
              </div>
            </div>
            {selectedDiscountType && selectedTypeDiscount ? (
              <div style={{ paddingTop: 25 }}>
                <OptionsTable
                  header={
                    bulkEditDiscounts
                      ? [
                          "Days/week",
                          "Hours/day",
                          "Duration",
                          "Price",
                          "Status",
                        ]
                      : ["Days/week", "Hours/day", "Price", "Status"]
                  }
                  bulkEdit={bulkEditDiscounts}
                  tableData={selectedOptionDiscount}
                  selectedDuration={selectedDuration}
                  selectedType={selectedType}
                  courseSelected={courseSelected}
                  selectedDiscountType={selectedDiscountType}
                  selectedCurrency={!selectedCurrency ? null : selectedCurrency}
                  discount
                  optionUpdated={() => optionUpdated((discount = true))}
                />
              </div>
            ) : null}
            {/* <Button
              onClick={() => {
                let arr1 = options
                  .filter((op) => {
                    return courseSelected.id === op.course;
                  })
                  .filter((c) => selectedTypeDiscount.id === c.type)
                  .map((v) => v.discountAmount)
                  .map((z) =>
                    z
                      // .filter((c) => selectedCurrency?.id === c.currency)
                      .filter((c) => selectedDiscountType?.id === c.discount)
                  );
                const arr2 = options
                  .filter((op) => {
                    return "61617b8f1d01010dfe0b50b9" === op.course;
                  })
                  .filter((c) => selectedTypeDiscount.id === c.type)
                  .map((v) => v.discountAmount)
                  .map((z) =>
                    z
                      // .filter((c) => selectedCurrency?.id === c.currency)
                      .filter((c) => selectedDiscountType?.id === c.discount)
                  );

                let dataToBeEdited = [];
                for (let index = 0; index < arr2.length; index++) {
                  const element = arr2[index];
                  console.log(element);
                  dataToBeEdited.push(element);
                }
                console.log(...arr2);
              }}
            >
              Duplicate Prices
            </Button> */}
          </>
        </Tab>
        <Tab eventKey="coupon" title="Coupon">
          <div>
            {coupons.length > 0 ? (
              <div>
                <CouponTable
                  header={[
                    "Coupon name",
                    "Coupon description",
                    "Coupon code",
                    "Type of discount",
                    "Expiry date",
                    "Applied",
                    "Booked",
                    "Apply to webiste",
                    "Pre-Applied",
                    "Status",
                  ]}
                  couponUpdated={() => couponUpdated()}
                  optionUpdated={() => optionUpdated()}
                  countries={countries}
                  discountTypes={discountTypes}
                  tableData={coupons}
                  onClick={(data) => {
                    setselectedCoupon(data);
                    setmodalShow("editCoupon");
                  }}
                />
              </div>
            ) : null}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setmodalShow("coupon")}
            >
              <PlusIcon />
              <span style={{ marginLeft: 4 }} className="secondry-text">
                Create new coupon
              </span>
            </div>
          </div>
        </Tab>

        <Tab eventKey="sendEmail" title="Send Emails">
          <div>
            {show ? (
              <Alert
                variant="success"
                onClose={() => setShow(false)}
                dismissible
              >
                <Alert.Heading>
                  Email created for review successfully!!
                </Alert.Heading>
              </Alert>
            ) : null}
            <div
              style={{ cursor: "pointer", paddingBottom: 40 }}
              onClick={() => setmodalShow("sendEmail")}
            >
              <PlusIcon />
              <span style={{ marginLeft: 4 }} className="secondry-text">
                Create Welcome Email
              </span>
            </div>
            <div>
              <ReviewEmail
                header={[
                  "Name",
                  "Email",
                  "Course",
                  "Course Type  / Age Type",
                  "Course start date / Course end date",
                  "Time",
                ]}
                emailsUpdated={() => emailUpdated()}
                // optionUpdated={() => optionUpdated()}
                // countries={countries}
                // discountTypes={discountTypes}
                tableData={reviewEmails}
                onClick={(data) => {
                  setselectedEmail(data);
                  setmodalShow("editEmail");
                }}
              />
              {/* {reviewEmails.length && reviewEmails.length >= 10 ? (
                <PaginationComponent
                  page={page}
                  totalPage={totalPage}
                  onPageClick={async (x) => {
                    const data = await getReviewEmails({
                      page: x,
                    });
                    setreviewEmails(data.results);
                    settotalPage(data.totalPages);
                    setpage(x);
                  }}
                  onChange={async () => {
                    if (reviewEmails.length == 10) {
                      const data = await getReviewEmails({
                        page: page + 1,
                      });
                      setreviewEmails(data.results);
                      settotalPage(data.totalPages);
                      setpage(page + 1);
                    }
                  }}
                  onChangePrev={async () => {
                    setpage(page > 1 ? page - 1 : 1);

                    const data = await getReviewEmails({
                      page: page - 1,
                    });
                    setreviewEmails(data.results);
                    settotalPage(data.totalPages);
                  }}
                />
              ) : null} */}
            </div>
          </div>
        </Tab>

        <Tab eventKey="checkout" title="Checkout Link">
          <>
            <div className="d-flex flex-row">
              <DropDown
                data={!courses ? [] : courses}
                ddStyle={{ width: 180, marginRight: 24 }}
                onClick={async (x) => {
                  setcourseSelected2(x);
                  renderOptions(selectedDuration2, selectedType2, x);
                }}
                name={!courseSelected2 ? "Course" : courseSelected2?.slug}
                courseIcon={courseSelected2.flag}
                course
              />
              <DropDown
                ddStyle={{ width: 180, marginRight: 24 }}
                data={!durations ? [] : durations}
                onClick={async (x) => {
                  setselectedDuration2(x);
                  renderOptions(x, selectedType2, courseSelected2);
                }}
                icon={<CalenderIcon />}
                name={!selectedDuration2 ? "Duration" : selectedDuration2.slug}
              />
              <DropDown
                data={!currencies ? [] : currencies}
                ddStyle={{ width: 180, marginRight: 24 }}
                onClick={async (x) => {
                  setselectedCurrency2(x);
                  renderOptions(
                    selectedDuration2,
                    selectedType2,
                    courseSelected2
                  );
                }}
                countryIcon={selectedCurrency2?.flag}
                country
                name={
                  !selectedCurrency2 ? "Currency" : selectedCurrency2.currency
                }
              />
              <div className="radiobuttons d-flex align-items-center justify-content-center flex-row">
                {/* <div className="rdio rdio-primary "> */}
                {types.map((x, key) => (
                  <label
                    className="rdio rdio-primary"
                    style={key === 0 ? { marginRight: 16 } : null}
                  >
                    <input
                      type="radio"
                      checked={selectedType2.id === x.id}
                      onChange={(e) => {
                        setselectedType2(x);
                        renderOptions(selectedDuration2, x, courseSelected2);
                      }}
                    />
                    <span className="radio-text"> {x.name}</span>
                  </label>
                ))}
                {/* </div> */}
              </div>
            </div>
            <div style={{ paddingTop: 25 }}>
              <OptionsTable
                selectedCurrency={!selectedCurrency2 ? null : selectedCurrency2}
                header={[
                  "Days/week",
                  "Hours/day",
                  bulkEdit ? "Duration" : null,
                  "Original Price",
                  "Price",
                  "Status",
                ]}
                selectedCurrency2={selectedCurrency2}
                checkoutLink
                bulkEdit={bulkEdit}
                selectedDuration={selectedDuration2}
                selectedType={selectedType2}
                courseSelected={courseSelected2}
                tableData={!selectedCurrency2 ? [] : selectedOption}
                optionUpdated={(data) => optionUpdated((discount = data))}
              />
            </div>
          </>
        </Tab>
        {/* <Tab eventKey="timer" title="Timer & content"></Tab> */}
      </Tabs>

      {modalShow === "coupon" ? (
        <AddCoupon
          couponUpdated={() => couponUpdated()}
          show={modalShow}
          onHide={() => setmodalShow(false)}
          optionUpdated={() => optionUpdated()}
        />
      ) : null}

      {modalShow === "editEmail" ? (
        <EditWelcomeEmail
          show={modalShow}
          setShow={setShow}
          onHide={() => setmodalShow(false)}
          data={selectedEmail}
          emailsUpdated={() => emailUpdated()}
        />
      ) : null}

      {modalShow === "sendEmail" ? (
        <SendWelcomeEmail
          setShow={setShow}
          emailsUpdated={() => emailUpdated()}
          show={modalShow}
          onHide={() => setmodalShow(false)}
        />
      ) : null}
      {modalShow === "editCoupon" ? (
        <EditCoupon
          selectedCoupon={selectedCoupon}
          couponUpdated={() => couponUpdated()}
          optionUpdated={() => optionUpdated()}
          show={modalShow}
          onHide={() => setmodalShow(false)}
        />
      ) : null}
      {modalShow === "discount" ? (
        <AddDiscount
          show={modalShow}
          onHide={() => setmodalShow(false)}
          discountUpdated={() => discountUpdated()}
          // eslint-disable-next-line no-unused-vars
          optionUpdated={() => optionUpdated((discount = true))}
        />
      ) : null}

      {modalShow === "option" ? (
        <AddOptions
          show={modalShow}
          selectedDuration={selectedDuration}
          selectedType={selectedType}
          courseSelected={courseSelected}
          option={options[0]}
          onHide={() => setmodalShow(false)}
          optionUpdated={() => optionUpdated()}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(Setting);
