import axios from "axios";

//  export const config = {
//   url: {
//    API_URL: `${process.env.REACT_APP_API_ENDPOINT}`,
//    BASE_URL: process.env.REACT_APP_BASEURL
//  }};p

var authToken = localStorage.getItem("authToken");

const axiosOptions = {
  // withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: {
      toString() {
        // return `Bearer `;
        return `Bearer ${authToken}`;
      },
    },
  },
};

export const ApiV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
  ...axiosOptions,
});

console.log(process.env.REACT_APP_API_ENDPOINT);

// export const SIGN_IN = `${config.url.API_URL}/hostLogin`
export const GET_BOOKINGS = `/bookings/`;
export const GET_COURSES = `/courses`;
export const LOGIN = `/auth/login`;
export const GET_ROLE = `/roles`;
export const GET_DURATION = `/durations`;
export const BOOKINGS = `/bookings`;
export const GET_PAYMENTMODE = `/payments/get-payment-methods`;
export const USERS = `/users`;
export const UPDATEBOOKING = `/updateBooking`;
export const EXPORT = `/exportFile`;
export const PARTNER = `/partners`;
export const INVITE = `/send-invite/admin`;
export const COUNTRY = `/countries`;
export const CLASSES = `/classes`;
export const LEVEL = `/levels`;
export const DAYSPERWEEK = `/daysPerWeek`;
export const AGEGROUP = `/optionAgeGroups`;
export const TYPES = `/optionTypes`;
export const OPTIONS = `/options`;
export const DISCOUNT = `/discounts`;
export const COUPON = `/coupons`;
export const CURRENCY = `/currency`;
export const ReviewEmail = `/reviewEmail`;
export const PRIVATE_CLASSES = `/privateClass`;
export const PLACEMENT = `/placements`;
export const MAJORS = `/majors`;
