import React from "react";

export default function TabComponent(props) {
  return (
    <div>
      <div className="age-tab-con w-100">
        {props.data.map((x, key) => (
          <div
            // className=""
            key={key}
            style={{
              ...props.style,
              backgroundColor: !props.multi
                ? props.tabSelected?.value === x.value
                  ? "#0B69FF1A"
                  : "transparent"
                : props.tabSelected.some((a) => a.value === x.value)
                ? "#0B69FF1A"
                : "transparent",
            }}
            onClick={(value) =>
              props.settabSelected(!props.multi ? x : x.value)
            }
          >
            <span
              style={{
                color: !props.multi
                  ? props.tabSelected?.value === x.value
                    ? "#0B69FF"
                    : "#000000"
                  : props.tabSelected.some((a) => a.value === x.value)
                  ? "#0B69FF"
                  : "#000000",
              }}
              className="tab-text"
            >
              {x.name}
            </span>
          </div>
        ))}
      </div>

      {props.error ? (
        <div>
          <span
            style={style.error}
            className="error"
          >{`Please select the ${props.errorMessage}  `}</span>
        </div>
      ) : null}
    </div>
  );
}

const style = {
  error: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    color: "#FB000A",
    marginTop: -8,
  },
};
