import React from "react";

function LogoutIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path fill={props.color} d="M15 9l-4-4v3H4v2h7v3l4-4z"></path>
      <path
        fill={props.color}
        d="M0 1v16a1 1 0 001 1h7v-2H2V2h6V0H1a1 1 0 00-1 1z"
      ></path>
    </svg>
  );
}

export default LogoutIcon;
