import React from "react";
import { Col } from "react-bootstrap";

export default function DashboardCard(props) {
  return (
    <Col md={4} style={{paddingBottom:20}}>
      <div className="dashboard-card d-flex justify-content-between" style={{borderLeft:`1px solid ${props.color}`}}>
        <div style={style.con}>
          <span style={style.text}>{props.text}</span>
          <span style={style.subtext}>{props.subtext}</span>
        </div>
        <div className=" d-flex justify-content-start align-items-start" style={{position:"relative", top:15}}>
        <img src={props.icon} alt="img" />
        </div>
      </div>
    </Col>
  );
}

const style = {
  con: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  text: {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 48,
    color: "#333333",
  },
  subtext: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    color: "#333333",
  },
};
