import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Views/Login";
import { connect } from "react-redux";
import Header from "../Components/Header/Header";
import Hamburger from "../Assets/Icons/Hamburger";
import Classes from "../Views/Classes";
import Setting from "../Views/Setting";
import Booking from "../Views/Booking";
import Placement from "../Views/Placement";
import Students from "../Views/Students";
import Statistic from "../Views/Statistic";
import Uapply from "../Views/Uapply";
import Major from "../Views/Major";

const Router = (props) => {
  const [toggle, settoggle] = useState(false);
  var authToken = localStorage.getItem("authToken");

  return (
    <div
      id={props.isLoggedIn && authToken ? "wrapper" : ""}
      className={toggle ? "toggled" : ""}
    >
      <div>
        {props.isLoggedIn && authToken ? (
          <Header toggle={toggle} activePath={window.location.pathname} />
        ) : null}
      </div>
      {props.isLoggedIn && authToken ? (
        <div id="navbar-wrapper">
          <nav className="navbar navbar-inverse">
            <div className="container-fluid">
              <div className="navbar-header d-flex flex-row align-items-center justify-content-start">
                <div
                  onClick={() => settoggle(!toggle)}
                  className="navbar-brand"
                  id="sidebar-toggle"
                >
                  <Hamburger />
                </div>
                <h3
                  style={{
                    margin: 0,
                    fontWeight: 600,
                    fontSize: 22,
                    color: "#3F4247",
                  }}
                >
                  {window.location.pathname === "/classes" ||
                  window.location.pathname === "/"
                    ? "Class List"
                    : window.location.pathname === "/setting"
                    ? "Website Settings"
                    : null}
                </h3>
              </div>
            </div>
          </nav>
        </div>
      ) : null}
      <section id={props.isLoggedIn && authToken ? "content-wrapper" : ""}>
        <div className="row">
          <div className="col-lg-12">
            {/* <Suspense fallback={<p>Loading...</p>}> */}
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/classes" element={<Classes />} />
              <Route path="/setting" element={<Setting />} />
              <Route path="/bookings" element={<Booking />} />
              <Route path="/placements" element={<Placement />} />

              <Route
                path="/students"
                element={(props) => <Students {...props} />}
              />
              <Route
                path="/statistics"
                element={(props) => <Statistic {...props} />}
              />
              <Route path="/majors" element={<Major />} />
              <Route exact path="/uapply" element={<Uapply />} />
            </Routes>
            {/* </Suspense> */}
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(Router);
