import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { Formik, Form } from "formik";

export default function DeleteModal(props) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Formik enableReinitialize={true}>
        <Modal
          className="custom-modal-width d-flex align-items-center"
          show={props.show}
          onHide={props.onHide}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title style={style.header}>Delete {props.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className=" d-flex flex-column">
              <span>Do you want to delete ?</span>
              <div style={{ marginTop: 25 }}>
                <Button
                  onClick={() => {
                    props.onClick();
                    props.onHide();
                  }}
                  disabled={loading}
                  className="complete-modal-btn"
                  style={{ marginRight: 15 }}
                >
                  {"Yes"}
                </Button>
                <Button
                  onClick={() => props.onHide()}
                  className="cancel-modal-btn"
                >
                  {"No"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </Formik>
    </div>
  );
}

const style = {
  btn: {
    width: 87,
    height: 52,
    background: "#299CF7",
    borderRadius: 4,
    border: "none",
  },
  permission: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    color: "#333333",
  },
  text: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 21,
    color: "#333333",
  },
  subtext: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    color: "#333333",
  },
  header: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 21,
    color: "#333333",
  },
};
