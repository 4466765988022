import React from "react";

export default function BookingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.333 4.667H2.667c-.737 0-1.334.597-1.334 1.333v6.667c0 .736.597 1.333 1.334 1.333h10.666c.737 0 1.334-.597 1.334-1.333V6c0-.736-.597-1.333-1.334-1.333z"
      ></path>
      <path
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.667 14V3.333A1.334 1.334 0 009.333 2H6.667a1.333 1.333 0 00-1.334 1.333V14"
      ></path>
    </svg>
  );
}
