/* eslint-disable import/no-anonymous-default-export */
import { LOGGED_IN, USER_DETAILS } from "../Actions/types";

const INTIAL_STATE = { isLoggedIn: false, userDetails: {} };

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    default:
      return state;
  }
};
