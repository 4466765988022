// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 2px;
`;
export const Required = styled.span`
  color: #ba1b23;
`;
