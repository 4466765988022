import moment from "moment/moment";
import React from "react";
import { useState } from "react";
import DotIcon from "../Assets/Icons/DotIcon";

export default function PlacementTable(props) {
  const [showMenu, setshowMenu] = useState(false);
  const { setstudentsArr, studentsArr } = props;

  return (
    <div>
      <div className="table-responsive-xl">
        <table className="table">
          {props.tableData.length ? (
            <thead>
              <tr>
                {props.header.map((x, key) => (
                  <th key={key} className="table-header">
                    {x}
                  </th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {props.tableData
              .sort(function (a, b) {
                return b - a;
              })
              ?.map((x, key) => {
                // console.log(
                //   props.durations.filter((a) => a.id == x.duration)[0]?.name
                // );
                return (
                  <tr
                    key={key}
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "inset 0px -1px 0px #D7DFE9",
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        id={`custom-checkbox-${key}`}
                        name={x.name}
                        value={x.name}
                        checked={setstudentsArr[x.id]}
                        onChange={() => {
                          if (studentsArr.some((a) => a.id === x.id)) {
                            const updatedData = studentsArr.filter(
                              (z) => z.id !== x.id
                            );
                            setstudentsArr(updatedData);
                          } else {
                            setstudentsArr((oldMessages) => [
                              ...oldMessages,
                              x,
                            ]);
                          }
                        }}
                      />
                    </td>
                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div className="d-flex flex-column">
                        <div className="booking-table-text"> {x.name}</div>
                        <div className="booking-table-subtext">
                          {moment(x.createdDate).format(
                            "DD/MM/YYYY [at] hh:mm:a"
                          )}
                        </div>
                      </div>
                    </td>

                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div className="d-flex flex-column">
                        <div className="booking-table-text"> {x.email}</div>
                      </div>
                    </td>

                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div className="d-flex flex-column">
                        <div className="booking-table-text">
                          {x.countryCode}
                          {x.phoneNumber}
                        </div>
                      </div>
                    </td>

                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div
                        className="d-flex flex-column"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="booking-table-text">
                          {moment(x.dob).format("MM/DD/YYYY")}
                        </div>
                      </div>{" "}
                    </td>
                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div
                        className="d-flex flex-column"
                        style={{ textTransform: "capitalize" }}
                      >
                        <div className="booking-table-text">
                          {!x.TestType ? "N/A" : x.TestType}
                        </div>
                        <div className="booking-table-subtext">
                          {!x.level ? "N/A" : x.level}
                        </div>
                      </div>{" "}
                    </td>

                    <td>
                      <div
                        style={{ cursor: "pointer" }}
                        onBlur={() => setshowMenu(false)}
                        onClick={() => setshowMenu(showMenu ? !x.id : x.id)}
                      >
                        <DotIcon />
                      </div>
                      {showMenu === x.id ? (
                        <div
                          className="d-flex flex-column"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            position: "absolute",
                            border: "1px solid #D7DFE9",
                            boxSizing: "border-box",
                            borderRadius: 4,
                            padding: 10,
                            marginTop: 10,
                            zIndex: 99,
                          }}
                        >
                          <span
                            onClick={() => {
                              props.onClick(x);
                              setshowMenu(false);
                            }}
                          >
                            Send Email
                          </span>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
