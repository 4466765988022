import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ButtonComponent from "../Components/ButtonComponent";
import { FormInput } from "../Components/FormInput";
import { sendWelcomeEmail, updateReviewEmail } from "../Services/Service";

import TextArea from "../Components/TextArea";

export default function EditWelcomeEmail(props) {
  const [startDate, setStartDate] = useState(new Date(props.data.startDate));
  const [endDate, setEndDate] = useState(new Date(props.data.endDate));
  const [loading, setloading] = useState(false);

  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} size="xl">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: props.data.name,
            email: props.data.email,
            course: props.data.course || "",
            time: props.data.time || "",
            tutorialLink: props.data.tutorialLink || "",
            classLink: props.data.classLink || "",
            classUser: props.data.classUser || "",
            classPassword: props.data.classPassword || "",
            courseTutorialLink: props.data.courseTutorialLink || "",
            materialLink: props.data.materialLink || "",
            ebookLink: props.data.ebookLink || "",
            courseType: props.data.courseType || "",
          }}
          // validationSchema={Yup.object({
          //   //   email: Yup.string().required("Enter Email"),
          // })}
          onSubmit={async (values, { setSubmitting }) => {
            var answer = window.confirm("Send Email?");
            if (answer) {
              try {
                setloading(true);

                await sendWelcomeEmail({
                  name: values.name,
                  email: values.email,
                  course: values.course,
                  courseType: values.courseType,
                  startDate: startDate,
                  endDate: endDate,
                  time: values.time,
                  tutorialLink: values.tutorialLink,
                  classLink: values.classLink,
                  classUser: values.classUser,
                  classPassword: values.classPassword,
                  courseTutorialLink: values.courseTutorialLink,
                  materialLink: values.materialLink,
                  ebookLink: values.ebookLink,
                });

                await updateReviewEmail(props.data.id, { emailSent: true });

                props.emailsUpdated();
                props.setShow(true);
                setTimeout(() => props.setShow(false), 5000);
                setloading(false);
                props.onHide();
              } catch (error) {
                setloading(false);
                alert(error.message);
              }
            } else {
              //some code
            }
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Send Welcome Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ paddingBottom: 20 }}>
                <span className="send-email-header">المعلومات الشخصية</span>
                <div className="d-flex flex-row">
                  <FormInput
                    placeholder="Name"
                    name="name"
                    type="text"
                    containerStyle={{ marginRight: 10 }}
                    style={{ marginBottom: 4, color: "#333" }}
                    label="الاسم"
                  />
                  <FormInput
                    placeholder="Email"
                    name="email"
                    containerStyle={{ marginRight: 10 }}
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="الإيميل"
                  />
                </div>
              </div>
              <div style={{ paddingBottom: 20 }}>
                <span className="send-email-header">تفاصيل الدورة</span>
                <div className="d-flex flex-row">
                  <FormInput
                    placeholder="Course"
                    containerStyle={{ marginRight: 10 }}
                    name="course"
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="نوع الدورة"
                  />
                  <FormInput
                    placeholder="Course Type"
                    containerStyle={{ marginRight: 10 }}
                    name="courseType"
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="نوع الدراسة"
                  />
                  <div style={{ margin: "5px 0 10px 12px" }}>
                    <div
                      className="input-title"
                      style={{ marginBottom: 4, color: "#333" }}
                    >
                      {"موعد بداية الدورة"}
                    </div>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div style={{ margin: "5px 0 10px 12px" }}>
                    <div
                      className="input-title"
                      style={{ marginBottom: 4, color: "#333" }}
                    >
                      {"موعد نهاية الدورة"}
                    </div>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                  {/* <FormInput
                    placeholder="Time"
                    name="time"
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="الأيام والوقت"
                  /> */}
                  <TextArea
                    name="time"
                    type="text"
                    placeholder={"Time"}
                    rows={"1"}
                    style={{ marginBottom: 4, color: "#333" }}
                    onChange={() => {
                      // someFuncton();
                    }}
                    label="الأيام والوقت"
                  />
                </div>
              </div>
              <div style={{ paddingBottom: 20 }}>
                <span className="send-email-header"> خطوات الانضمام للحصص</span>{" "}
                <div className="d-flex flex-row">
                  <FormInput
                    placeholder="Tutorial Video Link"
                    name="tutorialLink"
                    containerStyle={{ marginRight: 10 }}
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="مشاهدة الفيديو الإرشادي"
                  />

                  <FormInput
                    placeholder="Class Link"
                    containerStyle={{ marginRight: 10 }}
                    name="classLink"
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="الدخول على الرابط التالي"
                  />
                  <FormInput
                    placeholder="Class User Name"
                    name="classUser"
                    containerStyle={{ marginRight: 10 }}
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="اسم المستخدم"
                  />
                  <FormInput
                    placeholder="Class Password"
                    containerStyle={{ marginRight: 10 }}
                    name="classPassword"
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="كلمة السر "
                  />
                </div>
              </div>
              <div style={{ paddingBottom: 20 }}>
                <span className="send-email-header">
                  المواد الالكترونية الخاصة بالدورة
                </span>
                <div className="d-flex flex-row">
                  <FormInput
                    placeholder="Course Tutorial Link"
                    containerStyle={{ marginRight: 10 }}
                    name="courseTutorialLink"
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="مشاهدة الفيديو الإرشادي"
                  />

                  <FormInput
                    placeholder="Material Link"
                    containerStyle={{ marginRight: 10 }}
                    name="materialLink"
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="رابط المواد الاكترونية"
                  />
                  <FormInput
                    placeholder="E-book Link"
                    containerStyle={{ marginRight: 10 }}
                    name="ebookLink"
                    type="text"
                    style={{ marginBottom: 4, color: "#333" }}
                    label="رابط الكتب"
                  />
                </div>
              </div>
              <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                <ButtonComponent
                  disable={loading}
                  text="Create"
                  type="submit"
                  noOnclick
                  // onClick={() => setmodalShow(true)}
                />
                <ButtonComponent
                  text="Cancel"
                  style={{
                    marginLeft: 16,
                    color: "#3F4247",
                    backgroundColor: "#fff",
                    border: "1px solid #D7DFE9",
                  }}
                  onClick={() => props.onHide()}
                />
              </div>
              {/* <YNModels
                loading={loading}
                show={modalShow}
                onHide={() => setmodalShow(false)}
              /> */}
            </Modal.Body>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
}

// const style = {
//   error: {
//     fontStyle: "normal",
//     fontWeight: "normal",
//     fontSize: "12px",
//     color: "#FB000A",
//     marginTop: -8,
//   },
// };
