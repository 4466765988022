import React from "react";

function LogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="72"
      fill="none"
      viewBox="0 0 80 72"
    >
      <path
        fill="#FF0B37"
        d="M46.912 22.17l.106.116c1.087 1.183 2.85 1.183 3.936 0 1.087-1.183 1.087-3.101 0-4.284l-.105-.115c-1.087-1.183-2.85-1.183-3.937 0-1.087 1.183-1.087 3.101 0 4.284z"
      ></path>
      <path
        fill="#FFB800"
        stroke="#0B69FF"
        strokeWidth="0.003"
        d="M30.842 17.004l2.365 6.185h-4.736l2.37-6.185z"
      ></path>
      <path
        fill="#0B69FF"
        d="M28 28.006l.02 13.403c0 3.56 1.138 6.577 3.413 9.053 2.304 2.476 5.12 3.714 8.447 3.714 3.357 0 6.172-1.238 8.448-3.714 2.303-2.476 3.455-5.494 3.455-9.053l-.02-13.403h-5.674v13.403c0 1.981-.563 3.374-1.729 4.736-1.166 1.33-2.66 1.996-4.48 1.996-1.82 0-3.313-.666-4.479-1.996-1.138-1.362-1.727-2.724-1.727-4.736V28.006H28z"
      ></path>
      <circle cx="49" cy="20" r="3" fill="#FF0B37"></circle>
    </svg>
  );
}

export default LogoIcon;
