/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import {
  bookings,
  createprivateClass,
  getAgeGroup,
  getClassesWithoutPagination,
  getCoupons,
  getCurrencies,
  getDaysPerWeekbyId,
  getLevels,
  getPaymentMode,
  getTypebyId,
} from "../Services/Service";
import DropDown from "../Components/DropDown";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { FormInput } from "../Components/FormInput";
import { DobComponent } from "../Components/DobComponent";

export default function CheckoutModal(props) {
  const [ageGroup, setageGroup] = useState([]);
  const [selectedAge, setselectedAge] = useState("");
  const [selectedCoupon, setselectedCoupon] = useState("");
  const [levels, setlevels] = useState([]);
  const [levelSelected, setlevelSelected] = useState("");
  const [classes, setclasses] = useState([]);
  const [country, setcountry] = useState({});
  const [startDate, setStartDate] = useState("");
  const [coupons, setcoupons] = useState([]);
  const [calendarClick, setcalendarClick] = useState(false);
  const [daysPerWeeks, setdaysPerWeeks] = useState({});
  const [skillsToImprove, setskillsToImprove] = useState([]);
  const [timingSelected, settimingSelected] = useState([]);
  const [generatedLink, setgeneratedLink] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [calendarClick1, setcalendarClick1] = useState(false);
  const [dob, setdob] = useState("");
  const [selectedType, setselectedType] = useState("");
  const [paymentMode, setpaymentMode] = useState("");
  const [CountryIso, setCountryIso] = useState("");
  const [paymentModeName, setpaymentModeName] = useState("");
  const [paymentModeId, setpaymentModeId] = useState("");

  const [selectedDays, setselectedDays] = useState([
    { day: "sat", key: 6, status: false },
    { day: "sun", key: 0, status: false },
    { day: "mon", key: 1, status: false },
    { day: "tue", key: 2, status: false },
    { day: "wed", key: 3, status: false },
    { day: "thu", key: 4, status: false },
    { day: "fri", key: 5, status: false },
  ]);

  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const ageGroup = await getAgeGroup();
      const levels = await getLevels();
      const classes = await getClassesWithoutPagination();
      const daysperweek = await getDaysPerWeekbyId(
        props.selectedOpt.daysPerWeek
      );
      const paymentMode = await getPaymentMode(props.selectedCurrency2.id);
      setpaymentMode(paymentMode);

      const coupons = await getCoupons();
      const types = await getTypebyId(props.selectedOpt.type);

      setselectedType(types);
      setcoupons(coupons);
      setdaysPerWeeks(daysperweek);
      setcountry(country);
      setclasses(classes);
      setlevels(levels);
      setageGroup(ageGroup);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let levelsArrayKids = levels.filter((a, key) => {
    if (key === 0 || key === 4 || key === 7) {
      return {
        ...a,
      };
    }
  });
  // console.log(timingSelected);
  const classesArray = classes
    .filter((op) => {
      return props.selectedOpt.course === op.course;
    })
    .filter((op) => {
      return op.private === false;
    })
    .filter((op) => {
      return selectedAge.id === op.optionAge;
    })
    .filter((op) => {
      return op.level.indexOf(levelSelected.id) !== -1;
    })
    .filter(() => {
      return props.selectedOpt.type === "6124bf6f703f370d8cbf2c11";
    })
    .filter((op) => {
      return op.status === true;
    })
    .filter((op) => {
      if (props.selectedOpt.daysPerWeek === "612de0e5c00f8306c5a96ba5") {
        return op.daysPerWeek === props.selectedOpt.daysPerWeek;
      }
      if (props.selectedOpt.daysPerWeek === "612de0d0c00f8306c5a96ba4") {
        return (
          op.daysPerWeek === props.selectedOpt.daysPerWeek ||
          op.daysPerWeek === "612de0e5c00f8306c5a96ba5"
        );
      }
      return op.daysPerWeek;
    });

  const getExpiryDate = (startDate, weeks, daysPerWeek, selectedDay) => {
    const dates = [];
    let newdate = moment(startDate);
    let weekAdded = 0;

    while (weeks !== weekAdded) {
      for (let j = 0; j <= 6; j += 1) {
        // eslint-disable-next-line no-loop-func
        selectedDay.map((a) => {
          if (
            a.replace(
              /(^\w|\s\w)(\S*)/g,
              (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
            ) === newdate.locale("en").clone().add(j, "day").format("ddd")
          ) {
            dates.push(newdate.clone().add(j, "days").toDate());
          }
        });
      }

      weekAdded += 1;
      newdate = newdate.clone().add(1, "week");
    }

    return dates.pop();
  };
  const actTime = moment.tz(
    moment(timingSelected.time).format("HH:mm"),
    "HH:mm",
    "Europe/London"
  );
  const kuwaitTime = actTime
    .clone()
    .tz(
      country === "ae"
        ? "Asia/Dubai"
        : country === "om"
        ? "Asia/Muscat"
        : "Asia/Kuwait"
    );
  const sortByTimeAsc = function (lhs, rhs) {
    // eslint-disable-next-line no-shadow
    let results;

    results =
      new Date(lhs.time).getHours() > new Date(rhs.time).getHours()
        ? 1
        : new Date(lhs.time).getHours() < new Date(rhs.time).getHours()
        ? -1
        : 0;

    if (results === 0)
      results =
        new Date(lhs.time).getMinutes() > new Date(rhs.time).getMinutes()
          ? 1
          : new Date(lhs.time).getMinutes() < new Date(rhs.time).getMinutes()
          ? -1
          : 0;

    if (results === 0)
      results =
        new Date(lhs.time).getSeconds() > new Date(rhs.time).getSeconds()
          ? 1
          : new Date(lhs.time).getSeconds() < new Date(rhs.time).getSeconds()
          ? -1
          : 0;

    return results;
  };

  const TimingArray = [
    { time: moment("9:00 AM", "HH:mm"), id: 1 },
    { time: moment("10:00 AM", "HH:mm"), id: 2 },
    { time: moment("11:00 AM", "HH:mm"), id: 3 },
    { time: moment("12:00 PM", "HH:mm"), id: 4 },
    { time: moment("13:00 PM", "HH:mm"), id: 5 },
    { time: moment("14:00 PM", "HH:mm"), id: 6 },
    { time: moment("15:00 PM", "HH:mm"), id: 7 },
    { time: moment("16:00 PM", "HH:mm"), id: 8 },
    { time: moment("17:00 PM", "HH:mm"), id: 9 },
    { time: moment("18:00 PM", "HH:mm"), id: 10 },
  ];

  const today = new Date();

  const disableDays = selectedDays.filter((a) => {
    if (a.status === false) {
      return a.key;
    }
  });

  const tomorrow = moment().add(1, "days").toDate();
  const onClickSkills = (a) => {
    if (skillsToImprove.includes(a)) {
      const filter = skillsToImprove.filter((x) => x !== a);
      setskillsToImprove(filter);
    } else {
      setskillsToImprove([...skillsToImprove, a]);
    }
  };
  console.log(selectedDays);
  return (
    <div>
      <Modal show={props.show} onHide={props.onHide} size="xl">
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: "",
            email: "",
          }}
          // validationSchema={Yup.object({
          //   //   email: Yup.string().required("Enter Email"),
          // })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const currencyCodes = {
                KWD: "kw",
                SAR: "sa",
                AED: "ae",
                BHD: "ba",
                OMR: "om",
                QAR: "qa",
              };
              const selectedCon =
                currencyCodes[props.selectedCurrency2.name] || "";

              if (!startDate) {
                alert("Select all options");
                return;
              }

              const commonBookingData = {
                fullname: values.name,
                phoneNumber: formatPhoneNumberIntl(phoneNo)
                  .split(" ")
                  .slice(1)
                  .join(" ")
                  .replace(/\s/g, ""),
                mobileCountryCode: formatPhoneNumberIntl(phoneNo).replace(
                  / .*/,
                  ""
                ),
                email: values.email,
                dob: dob,
                couponId: selectedCoupon?.id || undefined,
                courseStartDate: startDate,
                time: kuwaitTime.toDate(),
                queryString: "No Ads",
                ukTime: actTime.locale("en").format("HH:mm"),
                localTime: kuwaitTime.locale("en").format("HH:mm"),
                modeOfPayment: paymentModeName,
                courseId: props.selectedOpt.course,
                discountType: selectedCoupon?.discountType,
                level: levelSelected?.id,
                optionType: selectedType.name,
                duration: props.selectedOpt.duration.id,
                country: "kw",
                currencyId: props.selectedCurrency2.id,
                optionId: props.selectedOpt.id,
                source: "No Ads",
                campaign: "No Ads",
                medium: "No Ads",
                content: "No Ads",
                paymentCurrency: CountryIso,
                paymentMethodId: paymentModeId.toString(),
                paymentMode: paymentModeName,
              };

              if (props.selectedType.slug === "private") {
                const availableDays = selectedDays
                  .filter((a) => a.status)
                  .map((a) => a.day);

                const cls = await createprivateClass({
                  optionAge: selectedAge.id,
                  optionAgeName: selectedAge.name,
                  level: [levelSelected.id],
                  course: props.selectedOpt.course,
                  courseName: props.courseSelected.slug,
                  partner: "619b9774d8e0f810ebf0c7d5",
                  partnerName: "ulearn",
                  zoomLink: "https://zoom.us/",
                  zoomPassword: "test",
                  time: moment(timingSelected.time).format(),
                  status: true,
                  availableDays,
                  daysPerWeek: daysPerWeeks.id,
                  private: true,
                });

                const endDate = getExpiryDate(
                  new Date(startDate),
                  props.selectedOpt.duration.weeks,
                  daysPerWeeks.daysPerWeek,
                  cls.availableDays
                );

                const redirect = await bookings({
                  ...commonBookingData,
                  courseEndDate: endDate,
                  classId: cls.id.toString(),
                  daySelected: cls.availableDays,
                  ageGroup: cls.optionAge,
                  focusIfPrivate: skillsToImprove.filter((v) =>
                    ["grammar", "speaking", "reading", "writing"].includes(v)
                  ),
                  commemntPrivate: skillsToImprove.filter(
                    (v) =>
                      !["grammar", "speaking", "reading", "writing"].includes(v)
                  ),
                });

                setgeneratedLink(redirect.paymentUrl);
              } else {
                const endDate = getExpiryDate(
                  new Date(startDate),
                  props.selectedOpt.duration.weeks,
                  daysPerWeeks.daysPerWeek,
                  timingSelected.availableDays
                );

                const redirect = await bookings({
                  ...commonBookingData,
                  courseEndDate: endDate,
                  classId: timingSelected.id.toString(),
                  daySelected: timingSelected.availableDays,
                  ageGroup: timingSelected.optionAge,
                  focusIfPrivate: [],
                  commemntPrivate: [],
                });

                setgeneratedLink(redirect.paymentUrl);
              }
            } catch (error) {
              if (error.response && error.response.status === 401) {
                alert("Unauthorized access. Please log in again.");
              } else {
                console.error("An error occurred:", error);
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Create checkout link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!generatedLink ? (
                <Col md={8}>
                  <Row>
                    <Col md={4}>
                      <DropDown
                        data={ageGroup}
                        toggleClass
                        onClick={async (a) => {
                          setselectedAge(a);
                          settimingSelected({});
                        }}
                        menuStyle={{ height: 114 }}
                        name={!selectedAge ? "Select Age" : selectedAge.name}
                      />
                    </Col>
                    {selectedAge ? (
                      <Col md={4}>
                        <DropDown
                          data={
                            selectedAge.slug === "adults"
                              ? levels
                              : selectedAge.slug === "kids" ||
                                selectedAge.slug === "teens"
                              ? levelsArrayKids
                              : []
                          }
                          toggleClass
                          onClick={async (a) => {
                            setlevelSelected(a);
                            settimingSelected({});
                          }}
                          menuStyle={{ height: 114 }}
                          name={
                            !levelSelected ? "Select Level" : levelSelected.name
                          }
                        />
                      </Col>
                    ) : null}
                    {levelSelected && selectedAge ? (
                      <div style={style.label}>Select Time</div>
                    ) : null}
                    {!classesArray.length && levelSelected && selectedAge ? (
                      <div className="d-flex flex-row flex-wrap w-100">
                        {TimingArray.sort(sortByTimeAsc).map((a) => {
                          const actTime = moment.tz(
                            moment(a.time).format("HH:mm"),
                            "HH:mm",
                            "Europe/London"
                          );

                          const kuwaitTime = actTime
                            .clone()
                            .tz(
                              props.selectedCurrency2.name === "AED"
                                ? "Asia/Dubai"
                                : props.selectedCurrency2.name === "OMR"
                                ? "Asia/Muscat"
                                : "Asia/Kuwait"
                            );

                          return (
                            <div
                              style={{
                                ...style.timeCon,

                                border:
                                  timingSelected.id === a.id
                                    ? "1px solid #299CF7"
                                    : "",
                                background:
                                  timingSelected.id === a.id
                                    ? "#F8FBFE"
                                    : "#fff",
                                position: "relative",
                              }}
                              onClick={async () => {
                                settimingSelected(a);
                                setselectedDays([
                                  { day: "sat", key: 6, status: false },
                                  { day: "sun", key: 0, status: false },
                                  { day: "mon", key: 1, status: false },
                                  { day: "tue", key: 2, status: false },
                                  { day: "wed", key: 3, status: false },
                                  { day: "thu", key: 4, status: false },
                                  { day: "fri", key: 5, status: false },
                                ]);
                              }}
                            >
                              <div style={style.timeText}>
                                {kuwaitTime.format("hh:mm a")}
                              </div>
                              <input
                                type="checkbox"
                                id="customCheckbox2"
                                name="customCheck"
                                className="custom-control-input"
                                checked={timingSelected.id === a.id}
                              />
                              <label className="custom-control-label"></label>
                            </div>
                          );
                        })}
                      </div>
                    ) : classesArray.length && levelSelected && selectedAge ? (
                      <div className="d-flex flex-row flex-wrap w-100">
                        {classesArray.sort(sortByTimeAsc).map((a) => {
                          const actTime = moment.tz(
                            moment(a.time).format("HH:mm"),
                            "HH:mm",
                            "Europe/London"
                          );

                          const kuwaitTime = actTime
                            .clone()
                            .tz(
                              props.selectedCurrency2.name === "AED"
                                ? "Asia/Dubai"
                                : props.selectedCurrency2.name === "OMR"
                                ? "Asia/Muscat"
                                : "Asia/Kuwait"
                            );

                          return (
                            <div
                              style={{
                                ...style.timeCon,
                                border:
                                  timingSelected.id === a.id
                                    ? "1px solid #299CF7"
                                    : "",
                                background:
                                  timingSelected.id === a.id
                                    ? "#F8FBFE"
                                    : "#fff",
                                position: "relative",
                              }}
                              onClick={async () => {
                                settimingSelected(a);
                                setselectedDays([
                                  { day: "sat", key: 6, status: false },
                                  { day: "sun", key: 0, status: false },
                                  { day: "mon", key: 1, status: false },
                                  { day: "tue", key: 2, status: false },
                                  { day: "wed", key: 3, status: false },
                                  { day: "thu", key: 4, status: false },
                                  { day: "fri", key: 5, status: false },
                                ]);
                              }}
                            >
                              <div style={style.timeText}>
                                {kuwaitTime.format("hh:mm a")}
                              </div>
                              <input
                                type="checkbox"
                                id="customCheckbox2"
                                name="customCheck"
                                className="custom-control-input"
                                checked={timingSelected.id === a.id}
                              />
                              <label className="custom-control-label"></label>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}

                    <div>
                      {levelSelected && selectedAge ? (
                        <div style={style.label}>Select days</div>
                      ) : null}
                      {classesArray.length && levelSelected && selectedAge ? (
                        <div className="d-flex flex-wrap flex-row">
                          {selectedDays.map((a) => (
                            <div
                              style={{
                                width: 70,
                                marginRight: 10,
                                height: 30,
                                borderRadius: 8,
                                padding: 5,
                                border: timingSelected.availableDays?.includes(
                                  a.day
                                )
                                  ? "1px solid #299CF7"
                                  : "1px solid #EBEBF0",
                                background:
                                  a.status === true ? "#F8FBFE" : "#fff",
                              }}
                            >
                              <div
                                className="d-flex flex-row"
                                onClick={async () => {
                                  if (
                                    timingSelected &&
                                    timingSelected?.availableDays?.includes(
                                      a.day
                                    ) &&
                                    a.status === false &&
                                    selectedDays.filter((z) => {
                                      return z.status === true;
                                    }).length < daysPerWeeks.daysPerWeek
                                  ) {
                                    const data = selectedDays.map((x) => {
                                      if (x.key === a.key) {
                                        x.status = true;
                                      }
                                      return x;
                                    });

                                    setselectedDays(data);
                                  } else {
                                    const data = selectedDays.map((x) => {
                                      if (x.key === a.key) {
                                        x.status = false;
                                      }
                                      return x;
                                    });
                                    setselectedDays(data);
                                  }
                                }}
                                style={{
                                  opacity:
                                    timingSelected.availableDays?.includes(
                                      a.day
                                    )
                                      ? "1"
                                      : "0.5",
                                }}
                              >
                                <div style={style.daysText}>{a.day}</div>
                                <input
                                  type="checkbox"
                                  id="customCheckbox2"
                                  name="customCheck"
                                  // className="custom-control-input"
                                  checked={a.status === true}
                                />
                                <label className="custom-control-label"></label>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : !classesArray.length &&
                        levelSelected &&
                        selectedAge ? (
                        <div className="d-flex flex-wrap flex-row">
                          {selectedDays.map((a) => (
                            <div
                              style={{
                                width: 70,
                                marginRight: 10,
                                height: 30,
                                borderRadius: 8,
                                padding: 5,
                                border: timingSelected
                                  ? "1px solid #299CF7"
                                  : "1px solid #EBEBF0",
                                background:
                                  a.status === true ? "#F8FBFE" : "#fff",
                              }}
                            >
                              <div
                                onClick={async () => {
                                  if (
                                    a.status === false &&
                                    selectedDays.filter((z) => {
                                      return z.status === true;
                                    }).length < daysPerWeeks.daysPerWeek
                                  ) {
                                    const data = selectedDays.map((x) => {
                                      if (x.key === a.key) {
                                        x.status = true;
                                      }
                                      return x;
                                    });

                                    setselectedDays(data);
                                  } else {
                                    const data = selectedDays.map((x) => {
                                      if (x.key === a.key) {
                                        x.status = false;
                                      }
                                      return x;
                                    });
                                    setselectedDays(data);
                                  }
                                }}
                                className="custom-control custom-checkbox d-flex flex-row"
                                style={{
                                  textTransform: "uppercase",
                                  fontSize: 12,
                                }}
                              >
                                <div style={style.daysText}> {a.day}</div>
                                <input
                                  type="checkbox"
                                  id="customCheckbox2"
                                  name="customCheck"
                                  // className="custom-control-input"
                                  checked={a.status === true}
                                />
                                <label
                                  className={"custom-control-label"}
                                ></label>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    {selectedDays.filter((z) => {
                      return z.status === true;
                    }).length === daysPerWeeks.daysPerWeek ? (
                      <>
                        <div className="col-md-4" style={{ margin: "40px 0" }}>
                          <div
                            className="dd-text d-flex align-items-center"
                            style={style.startDateInput}
                            onClick={() => setcalendarClick(!calendarClick)}
                          >
                            {!startDate
                              ? "Select start date"
                              : moment(startDate).format("DD/MM/YYYY")}
                          </div>
                          {calendarClick ? (
                            <DayPicker
                              selectedDays={startDate}
                              // overlayComponent={CustomOverlay}
                              onDayClick={(date, { selected, disabled }) => {
                                // setdateErr();
                                if (disabled) {
                                  // Day is disabled, do nothing
                                  return;
                                }
                                if (selected) {
                                  // Unselect the day if already selected
                                  setStartDate(undefined);
                                  return;
                                }
                                setStartDate(date);
                                setcalendarClick(false);
                                // console.log.*$
                              }}
                              disabled={[
                                { dayOfWeek: disableDays.map((a) => a.key) },
                                today,
                                tomorrow,
                                {
                                  before: today,
                                },
                              ]}
                              // disableDays={{
                              //   daysOfWeek: [...disableDays],
                              // }}
                            />
                          ) : null}
                        </div>
                        <div style={{ margin: "0 0 40px 0" }}>
                          <DropDown
                            data={coupons}
                            toggleClass
                            onClick={async (a) => {
                              setselectedCoupon(a);
                            }}
                            menuStyle={{ height: 114 }}
                            name={
                              !selectedCoupon
                                ? "Select a coupon"
                                : selectedCoupon.name
                            }
                          />
                        </div>
                        {props.selectedType.slug === "private" ? (
                          <div>
                            <div
                              style={{ marginBottom: 20 }}
                              className="d-flex flex-row justify-content-between flex-wrap"
                            >
                              <div style={{ position: "relative" }}>
                                <div
                                  style={{
                                    border: skillsToImprove.includes("writing")
                                      ? "1px solid #299CF7"
                                      : "1px solid #EBEBF0",
                                    background: skillsToImprove.includes(
                                      "writing"
                                    )
                                      ? "#F8FBFE"
                                      : "#fff",
                                    padding: 5,
                                    borderRadius: 8,
                                  }}
                                  onClick={() => onClickSkills("writing")}
                                >
                                  writing
                                </div>
                              </div>
                              <div style={{ position: "relative" }}>
                                <div
                                  style={{
                                    border: skillsToImprove.includes("reading")
                                      ? "1px solid #299CF7"
                                      : "1px solid #EBEBF0",
                                    background: skillsToImprove.includes(
                                      "reading"
                                    )
                                      ? "#F8FBFE"
                                      : "#fff",
                                    padding: 5,
                                    borderRadius: 8,
                                  }}
                                  onClick={() => onClickSkills("reading")}
                                >
                                  reading
                                </div>
                              </div>
                              <div style={{ position: "relative" }}>
                                <div
                                  style={{
                                    border: skillsToImprove.includes("speaking")
                                      ? "1px solid #299CF7"
                                      : "1px solid #EBEBF0",
                                    background: skillsToImprove.includes(
                                      "speaking"
                                    )
                                      ? "#F8FBFE"
                                      : "#fff",
                                    padding: 5,
                                    borderRadius: 8,
                                  }}
                                  onClick={() => onClickSkills("speaking")}
                                >
                                  talking
                                </div>
                              </div>
                              <div style={{ position: "relative" }}>
                                <div
                                  style={{
                                    border: skillsToImprove.includes("grammar")
                                      ? "1px solid #299CF7"
                                      : "1px solid #EBEBF0",
                                    background: skillsToImprove.includes(
                                      "grammar"
                                    )
                                      ? "#F8FBFE"
                                      : "#fff",
                                    padding: 5,
                                    borderRadius: 8,
                                  }}
                                  onClick={() => onClickSkills("grammar")}
                                >
                                  grammar
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100%" }}>
                              <div style={{ position: "relative" }}>
                                <div
                                  onClick={() => {
                                    setskillsToImprove([]);
                                    if (
                                      skillsToImprove.includes(
                                        "grammar",
                                        "speaking",
                                        "reading",
                                        "writing"
                                      )
                                    ) {
                                      setskillsToImprove([]);
                                    } else {
                                      setskillsToImprove([
                                        "grammar",
                                        "speaking",
                                        "reading",
                                        "writing",
                                      ]);
                                    }
                                  }}
                                  style={{
                                    border:
                                      skillsToImprove.includes(
                                        "grammar" &&
                                          "speaking" &&
                                          "reading" &&
                                          "writing"
                                      ) && skillsToImprove.length === 4
                                        ? "1px solid #299CF7"
                                        : "1px solid #EBEBF0",
                                    background:
                                      skillsToImprove.includes(
                                        "grammar" &&
                                          "speaking" &&
                                          "reading" &&
                                          "writing"
                                      ) && skillsToImprove.length === 4
                                        ? "#F8FBFE"
                                        : "#fff",
                                    width: "100%",
                                    padding: 5,
                                    borderRadius: 8,
                                  }}
                                >
                                  all the above
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {startDate ? (
                      <>
                        <div className="col-md-4" style={{ marginTop: 20 }}>
                          <FormInput
                            placeholder="Name"
                            name="name"
                            type="text"
                            style={{ marginBottom: 4 }}
                            label="Name"
                          />{" "}
                        </div>
                        <div className="col-md-4" style={{ marginTop: 20 }}>
                          <FormInput
                            placeholder="Email"
                            name="email"
                            type="email"
                            style={{ marginBottom: 4 }}
                            label="Email"
                          />
                        </div>
                        <div className="col-md-4" style={{ marginTop: 24 }}>
                          <div
                            className="input-title"
                            style={{ marginBottom: 4 }}
                          >
                            DOB
                          </div>

                          <DobComponent
                            monthLabel={""}
                            yearLabel={""}
                            date={dob}
                            dateFromChild={(data) => {
                              setdob(data);
                            }}
                            label={""}
                          />
                        </div>
                        <div className="col-md-4">
                          <div
                            className="input-title"
                            style={{ marginBottom: 4 }}
                          >
                            Phone Number
                          </div>
                          <PhoneInput
                            international={false}
                            defaultCountry="KW"
                            value={phoneNo}
                            className={"phoneInput-en"}
                            onChange={(e) => setphoneNo(e)}
                            // countryCallingCode={false}
                            style={{
                              ...style.startDateInput,
                              direction: "ltr",
                              marginBottom: 12,
                            }}
                          />
                        </div>
                        {paymentMode
                          .filter((b) =>
                            props.selectedCurrency2.currency !== "KWD" &&
                            props.selectedCurrency2.currency !== "SAR"
                              ? b.PaymentMethodCode !== "kn"
                              : b
                          )
                          .map((a) => (
                            <div
                              onClick={() => {
                                setpaymentModeName(a.PaymentMethodEn);
                                setpaymentModeId(a.PaymentMethodId);
                                setCountryIso(a.CurrencyIso);
                              }}
                              style={{ padding: 10 }}
                              className="d-flex flex-row align-items-center"
                            >
                              <input
                                type="radio"
                                value="option1"
                                checked={paymentModeId === a.PaymentMethodId}
                                onChange={() => {}}
                              />
                              <div
                                style={{
                                  width: 32,
                                  borderRadius: 4,
                                  height: 23,
                                  margin: "0 15px",
                                  background: `url(${a.ImageUrl})`,
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                }}
                              />
                              {a.PaymentMethodEn}
                            </div>
                          ))}
                      </>
                    ) : null}

                    <Button
                      style={{ width: "max-content", margin: "20px 15px" }}
                      type="submit"
                      disabled={!paymentModeName || !phoneNo}
                    >
                      Generate Link
                    </Button>
                  </Row>
                </Col>
              ) : (
                // eslint-disable-next-line react/jsx-no-target-blank
                <div className="d-flex flex-row justify-content-around">
                  <a target="_blank" href={generatedLink}>
                    Payment Link
                  </a>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(generatedLink).then(
                        function () {
                          alert(" Copying to clipboard was successful!");
                        },
                        function (err) {
                          console.error("Async: Could not copy text: ", err);
                        }
                      );
                    }}
                  >
                    Click to copy link
                  </div>
                </div>
              )}
            </Modal.Body>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
}

const style = {
  btn: {
    width: 87,
    height: 52,
    background: "#299CF7",
    borderRadius: 4,
    border: "none",
  },
  label: {
    fontFamily: "Cairo",
    fontSize: 16,
    margin: "30px 0 10px 0",
    lineHeight: "20px",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#000",
  },
  permission: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    color: "#333333",
  },
  text: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 21,
    color: "#333333",
  },
  subtext: {
    fontFamily: "Cairo",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    color: "#333333",
  },
  header: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 21,
    color: "#333333",
  },
  startDateInput: {
    width: "100%",
    height: "40px",
    background: "rgb(255, 255, 255)",
    border: "1px solid rgb(215, 223, 233)",
    borderRadius: 4,
    marginBottom: 4,
    padding: 5,
  },
  daysText: {
    fontFamily: "Cairo",
    fontSize: 12,
    lineHeight: "20px",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "rgba(30, 36, 50, 0.5)",
    position: "relative",
    paddingRight: 20,
    top: 2,
  },
  timeCon: {
    width: 100,
    marginTop: 10,
    marginRight: 10,
    height: 30,
    borderRadius: 8,
    padding: 5,
  },
  timeText: {
    fontFamily: "Cairo",
    fontSize: 12,
    lineHeight: "20px",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "rgba(30, 36, 50, 0.5)",
  },
};
