import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Label, Required } from "./style";
import moment from "moment";

export const DobComponent = (props) => {
  const currentDate = props.date ? new Date(props.date) : null;

  const [myMonth, setMyMonth] = useState(currentDate || null);
  const [myYear, setMyYear] = useState(currentDate || null);
  const [myDay, setMyDay] = useState(currentDate || null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const updateDateBoundaries = (year, month) => {
    if (year && month) {
      const newMinDate = new Date(year.getFullYear(), month.getMonth(), 1);
      const newMaxDate = new Date(year.getFullYear(), month.getMonth() + 1, 0);
      setMinDate(newMinDate);
      setMaxDate(newMaxDate);

      // Reset the day if it is out of range of the new month/year
      if (myDay && (myDay < newMinDate || myDay > newMaxDate)) {
        setMyDay(newMinDate);
        updateChildDates(year, month, newMinDate);
      }
    }
  };

  useEffect(() => {
    updateDateBoundaries(myYear, myMonth);
  }, [myYear, myMonth]);

  const renderDayContents = (day, date) => {
    if (!date || !minDate || !maxDate || date < minDate || date > maxDate) {
      return <span className="text-muted"></span>;
    }
    return <span>{date.getDate()}</span>;
  };

  const handleYearChange = async (date) => {
    if (date) {
      setMyYear(date);
      if (props.onYearFromChild) await props.onYearFromChild(date);
      if (myMonth) updateDateBoundaries(date, myMonth);
    }
  };

  const handleMonthChange = async (date) => {
    if (date) {
      setMyMonth(date);
      if (props.onMonthFromChild) await props.onMonthFromChild(date);
      if (myYear) updateDateBoundaries(myYear, date);
    }
  };

  const handleDayChange = (date) => {
    if (date) {
      setMyDay(date);
      if (myYear && myMonth) updateChildDates(myYear, myMonth, date);
    }
  };

  const updateChildDates = (year, month, day) => {
    const dateString = `${moment(year).format("YYYY")}-${moment(month).format(
      "MM"
    )}-${day ? moment(day).format("DD") : "01"}`;
    if (props.leavingDateFromChild) props.leavingDateFromChild(dateString);
    if (props.joiningDateFromChild) props.joiningDateFromChild(dateString);
    if (props.dateFromChild) props.dateFromChild(dateString);
  };

  return (
    <div className="d-flex justify-content-between">
      <div style={{ paddingRight: 10 }} className="input-container">
        <div>
          {props.yearLabel ? <Required>*</Required> : ""}
          <Label>{props.yearLabel}</Label>
          <DatePicker
            selected={myYear}
            onChange={handleYearChange}
            showYearPicker
            placeholderText="Year"
            dateFormat="yyyy"
          />
        </div>
      </div>
      <div style={{ paddingRight: 10 }}>
        {props.monthLabel ? <Required>*</Required> : ""}
        <Label>{props.monthLabel}</Label>
        <DatePicker
          showMonthYearPicker
          dateFormat="MMMM"
          selected={myMonth}
          onChange={handleMonthChange}
          placeholderText="Month"
        />
      </div>
      {props.noDate ? null : (
        <div>
          <Label>{props.label}</Label>
          <DatePicker
            dateFormat="dd"
            selected={myDay}
            renderDayContents={renderDayContents}
            onChange={handleDayChange}
            placeholderText="Day"
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      )}
    </div>
  );
};
