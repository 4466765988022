import React from "react";

function ClassIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M6 3a1 1 0 012 0v1h8V3a1 1 0 112 0v1h1a3 3 0 013 3v12a3 3 0 01-3 3H5a3 3 0 01-3-3V7a3 3 0 013-3h1V3z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10 12a1 1 0 100 2h7a1 1 0 100-2h-7zm-3 4a1 1 0 100 2h6a1 1 0 100-2H7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ClassIcon;
