import React from "react";

export default function StatsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill={props.color}
        d="M0 1.492v11.017a1.227 1.227 0 001.212 1.222H1.7a1.222 1.222 0 001.22-1.222V1.492A1.222 1.222 0 001.7.27h-.489A1.227 1.227 0 000 1.492zM5.538 7.917v4.592a1.227 1.227 0 001.212 1.222h.492a1.222 1.222 0 001.22-1.222V7.917A1.222 1.222 0 007.242 6.7h-.493a1.227 1.227 0 00-1.211 1.217zM11.076 4.428v8.081a1.227 1.227 0 001.212 1.222h.492A1.222 1.222 0 0014 12.509V4.428a1.222 1.222 0 00-1.22-1.221h-.493a1.226 1.226 0 00-1.211 1.221z"
      ></path>
    </svg>
  );
}
