import moment from "moment";
import React from "react";
import { useState } from "react";
import DotIcon from "../Assets/Icons/DotIcon";

export default function ReviewEmail(props) {
  const [showMenu, setshowMenu] = useState(false);

  function reverse(array) {
    var output = [];
    for (var i = array.length - 1; i > -1; i--) {
      output.push(array[i]);
    }

    return output;
  }
  return (
    <div>
      {" "}
      <div className="table-responsive-xl">
        <table className="table">
          {props.tableData?.length ? (
            <thead>
              <tr>
                {props.header.map((x) => (
                  <th className="table-header">{x}</th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {reverse(
              props.tableData
                .filter((z) => z.emailSent === false)
                ?.map((x, key) => {
                  // console.log(
                  //   props.durations.filter((a) => a.id == x.duration)[0]?.name
                  // );
                  return (
                    <tr
                      style={{
                        background: "#FFFFFF",
                        boxShadow: "inset 0px -1px 0px #D7DFE9",
                      }}
                    >
                      <td
                        style={{ opacity: x.status ? 1 : 0.5 }}
                        className="table-body-text"
                      >
                        <div className="d-flex flex-column">
                          <div className="booking-table-text"> {x.name}</div>
                        </div>
                      </td>
                      <td
                        style={{ opacity: x.status ? 1 : 0.5 }}
                        className="table-body-text"
                      >
                        <div className="d-flex flex-column">
                          <div className="booking-table-text"> {x.email}</div>
                        </div>
                      </td>
                      <td
                        style={{ opacity: x.status ? 1 : 0.5 }}
                        className="table-body-text"
                      >
                        <div className="d-flex flex-column">
                          <div className="booking-table-text">{x.course}</div>
                        </div>
                      </td>

                      <td
                        style={{ opacity: x.status ? 1 : 0.5 }}
                        className="table-body-text"
                      >
                        <div
                          className="d-flex flex-column"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="booking-table-text">
                            {x.courseType}
                          </div>
                        </div>{" "}
                      </td>
                      <td
                        style={{ opacity: x.status ? 1 : 0.5 }}
                        className="table-body-text"
                      >
                        <div
                          className="d-flex flex-column"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="booking-table-text">
                            {moment(x.startDate).format("DD-MMM-YYYY")}
                          </div>
                          <div className="booking-table-subtext">
                            {moment(x.endDate).format("DD-MMM-YYYY")}
                          </div>
                        </div>{" "}
                      </td>

                      <td
                        style={{ opacity: x.status ? 1 : 0.5 }}
                        className="table-body-text"
                      >
                        <div
                          className="d-flex flex-column"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div className="table-body-text">{x.time}</div>
                        </div>{" "}
                      </td>

                      <td>
                        <div
                          style={{ cursor: "pointer" }}
                          onBlur={() => setshowMenu(false)}
                          onClick={() => setshowMenu(showMenu ? !x.id : x.id)}
                        >
                          <DotIcon />
                        </div>
                        {showMenu === x.id ? (
                          <div
                            className="d-flex flex-column"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#fff",
                              position: "absolute",
                              border: "1px solid #D7DFE9",
                              boxSizing: "border-box",
                              borderRadius: 4,
                              padding: 10,
                              marginTop: 10,
                              zIndex: 99,
                            }}
                          >
                            <span
                              onClick={() => {
                                props.onClick(x);
                                setshowMenu(false);
                              }}
                            >
                              Send Email
                            </span>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  );
                })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
