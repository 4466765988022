import React from "react";

function DiscountIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#4F4F4F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.443 10.057l-5.378 5.378a1.5 1.5 0 01-2.122 0L1.5 9V1.5H9l6.443 6.442a1.5 1.5 0 010 2.115v0zM5.25 5.25h.008"
      ></path>
    </svg>
  );
}

export default DiscountIcon;
