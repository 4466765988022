import moment from "moment/moment";
import React from "react";
import { useState } from "react";
import DotIcon from "../Assets/Icons/DotIcon";
import { updateBooking } from "../Services/Service";
import ArrowDown from "../Assets/Icons/ArrowDown";

function StudentsTable(props) {
  const [showMenu, setshowMenu] = useState(false);
  const [showPartners, setshowPartners] = useState(false);
  const [idSelected, setidSelected] = useState("");
  const [showStage, setshowStage] = useState(false);

  // function reverse(array) {
  //   var output = [];
  //   for (var i = array.length - 1; i > -1; i--) {
  //     output.push(array[i]);
  //   }

  //   return output;
  // }
  // console.log(props.tableData[0].assignedTo);
  let stages = [
    { name: "Active", color: "green" },
    { name: "Inactive", color: "orange" },
    { name: "Paused", color: "blue" },
    { name: "Refunded", color: "red" },
    { name: "Pending", color: "#000" },
    { name: "Incoming", color: "#000" },
  ];
  return (
    <div className="table-responsive-xl">
      <table className="table">
        {props.tableData?.length ? (
          <thead>
            <tr>
              {props.header.map((x, key) => (
                <th key={key} className="table-header">
                  {x}
                </th>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {props.tableData
            .sort(function (a, b) {
              return b - a;
            })
            ?.map((x, key) => {
              // console.log(
              //   props.durations.filter((a) => a.id == x.duration)[0]?.name
              // );
              return (
                <tr
                  key={key}
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "inset 0px -1px 0px #D7DFE9",
                  }}
                >
                  <td style={{ position: "relative" }}>
                    {" "}
                    <div
                      onClick={() => {
                        setshowStage(!showStage);
                        setidSelected(x.id);
                      }}
                      style={{
                        cursor: "pointer",
                        color: stages?.filter((a) => a.name === x.stage)[0]
                          ?.color,
                      }}
                    >
                      {!x.stage
                        ? "Select Stage"
                        : stages?.filter((a) => a.name === x.stage)[0]
                            ?.name}{" "}
                      <ArrowDown />
                    </div>
                    {showStage && idSelected === x.id ? (
                      <div
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          background: "#fff",
                          borderRadius: 4,
                          top: 50,
                          padding: 10,
                          boxShadow: "0px 8px 24px rgba(232, 233, 245, 0.7)",
                          zIndex: 9,
                        }}
                        className="d-flex flex-column"
                      >
                        {stages.map((a) => (
                          <div
                            onClick={async () => {
                              await updateBooking(x.id, { stage: a.name });
                              await props.getBookingsfn(props.stage);
                              setshowStage(false);
                            }}
                            style={{ padding: "6px 0" }}
                          >
                            {a.name}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </td>
                  <td
                    style={{ opacity: x.status ? 1 : 0.5 }}
                    className="table-body-text"
                  >
                    <div className="d-flex flex-column">
                      <div className="booking-table-text"> {x.fullname}</div>
                      <div className="booking-table-subtext">
                        {" "}
                        {moment(
                          new Date(
                            parseInt(x.id.toString().substring(0, 8), 16) * 1000
                          )
                        )
                          .tz("Asia/Kuwait")
                          .format("DD/MM/YYYY [at] hh:mm:a")}
                      </div>{" "}
                      <div className="booking-table-subtext"> {x.orderNo}</div>
                    </div>
                  </td>

                  <td
                    style={{ opacity: x.status ? 1 : 0.5 }}
                    className="table-body-text"
                  >
                    <div className="d-flex flex-column">
                      <div className="booking-table-text">
                        {
                          props.courses.filter((a) => a.id === x.course)[0]
                            ?.name
                        }
                      </div>
                      <div className="booking-table-subtext">
                        {
                          props.durations.filter((a) => a.id === x.duration)[0]
                            ?.name
                        }
                      </div>
                    </div>
                  </td>

                  <td
                    style={{ opacity: x.status ? 1 : 0.5 }}
                    className="table-body-text"
                  >
                    <div
                      className="d-flex flex-column"
                      style={{ textTransform: "capitalize" }}
                    >
                      <div className="booking-table-text">
                        {x.daySelected.reduce(function (prevVal, currVal, idx) {
                          return idx === 0 ? currVal : prevVal + "- " + currVal;
                        }, "") || "N/A"}{" "}
                      </div>
                      <div className="booking-table-subtext">
                        {" "}
                        {x.ukTime || x.timeOftheDay}
                      </div>
                    </div>{" "}
                  </td>

                  <td style={{ position: "relative" }}>
                    <div
                      onClick={() => {
                        setshowPartners(!showPartners);
                        setidSelected(x.id);
                      }}
                      style={{
                        cursor: "pointer",
                        color: !x.assignedTo ? "#7E858E" : "#000",
                      }}
                    >
                      {!x.assignedTo
                        ? "Select Partner"
                        : props.partners.filter((a) => a.id === x.assignedTo)[0]
                            ?.name}{" "}
                      <ArrowDown />
                    </div>
                    {showPartners && idSelected === x.id ? (
                      <div
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          background: "#fff",
                          borderRadius: 4,
                          top: 50,
                          padding: 10,
                          boxShadow: "0px 8px 24px rgba(232, 233, 245, 0.7)",
                          zIndex: 9,
                        }}
                        className="d-flex flex-column"
                      >
                        {props.partners.map((a) => (
                          <div
                            onClick={async () => {
                              await updateBooking(x.id, { assignedTo: a.id });
                              await props.getBookingsfn(props.stage);
                              setshowPartners(false);
                            }}
                            style={{
                              padding: "6px 0",
                            }}
                          >
                            {a.name}
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </td>

                  <td>
                    <div
                      style={{ cursor: "pointer" }}
                      onBlur={() => setshowMenu(false)}
                      onClick={() => setshowMenu(showMenu ? !x.id : x.id)}
                    >
                      <DotIcon />
                    </div>
                    {showMenu === x.id ? (
                      <div
                        className="d-flex flex-column"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#fff",
                          position: "absolute",
                          border: "1px solid #D7DFE9",
                          boxSizing: "border-box",
                          borderRadius: 4,
                          marginTop: 10,
                          zIndex: 99,
                          right: 43,
                        }}
                      >
                        <span className="menu-text">View</span>
                        <span className="menu-text">Edit</span>
                        <span className="menu-text">Email</span>
                        <span className="menu-text">Comments</span>
                        <span className="menu-text">Send Welcome letter</span>
                        <span className="menu-text">Refund / Cancel</span>
                        <span className="menu-text">Comments</span>
                        <span className="menu-text">Log</span>
                      </div>
                    ) : null}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default StudentsTable;
