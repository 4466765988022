import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormInput } from "../Components/FormInput";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import DropDown from "../Components/DropDown";
import ButtonComponent from "../Components/ButtonComponent";
import {
  getCourses,
  getDaysPerWeek,
  getDurations,
  getTypes,
  createOptions,
  getDiscountTypes,
  getCurrencies,
} from "../Services/Service";
import CalenderIcon from "../Assets/Icons/CalenderIcon";
import { db } from "../firebaseConfig";

export default function AddOptions(props) {
  const [courses, setcourses] = useState([]);
  const [courseSelected, setcourseSelected] = useState(props.courseSelected);
  const [daysPerWeek, setdaysPerWeek] = useState([]);
  const [daysPerWeekSelected, setdaysPerWeekSelected] = useState("");
  const [loading, setloading] = useState(false);
  const [duration, setduration] = useState([]);
  const [durationSelected, setdurationSelected] = useState(
    props.selectedDuration
  );
  const [types, settypes] = useState([]);
  const [selectedType, setselectedType] = useState(props.selectedType);
  const [currencies, setcurrencies] = useState([]);
  const [selectedCurrency, setselectedCurrency] = useState(null);

  const [error, seterror] = useState(false);
  useEffect(() => {
    async function fetchData() {
      // You can await hereby
      const data = await getCurrencies();
      const duration = await getDurations();
      const daysperweek = await getDaysPerWeek();
      const types = await getTypes();

      const courses = await getCourses();
      setcourses(courses);

      settypes(types);
      setdaysPerWeek(daysperweek);
      setduration(duration);
      setcurrencies(data);
    }
    fetchData();
  }, []);

  return (
    <div>
      <Modal id="myModal" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{ email: "" }}
            validationSchema={Yup.object({
              //   email: Yup.string().required("Enter Email"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                if (
                  !selectedCurrency ||
                  !courseSelected ||
                  !selectedType ||
                  !durationSelected ||
                  !daysPerWeekSelected
                ) {
                  seterror(true);
                  setloading(false);
                } else {
                  // setloading(true);
                  let discountType = await getDiscountTypes();
                  const currency = await getCurrencies();
                  let result = [];
                  for (let index = 0; index < discountType.length; index++) {
                    const element = discountType[index];
                    let dis = { discount: element.id, price: 0 };
                    for (let index = 0; index < currency.length; index++) {
                      const element2 = currency[index];
                      result.push({
                        ...dis,
                        currency: element2.id,
                      });
                    }
                  }
                  await createOptions({
                    course: courseSelected.id,
                    hrsPerday: values.hoursperday,
                    discountAmount: result,
                    price: {
                      currency: selectedCurrency.id,
                      price: values.price,
                      originalPrice: values.originalPrice,
                    },
                    duration: durationSelected.id,
                    type: selectedType.id,
                    status: true,
                    daysPerWeek: daysPerWeekSelected.id,
                  });
                  await db
                    .collection("clear_cache_home")
                    .doc("su3Zr6L4Q2d21lp0wDJL")
                    .update({
                      status: false,
                    });
                  await db
                    .collection("clear_cache_pricing")
                    .doc("Ex02fRvGaBL5IGYjsbxT")
                    .update({
                      status: false,
                    });
                  await db
                    .collection("clear_cache_checkout")
                    .doc("rk4HW8Rad7ju2jk7CEze")
                    .update({
                      status: false,
                    });
                  props.optionUpdated();
                  setloading(false);
                  props.onHide();
                }
              } catch (error) {
                setloading(false);
                alert(error.message);
              }
            }}
          >
            <Form>
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Course
                </span>

                <DropDown
                  data={courses}
                  error={error && !courseSelected}
                  errorMessage={error && !courseSelected ? "course" : null}
                  onClick={async (x) => {
                    setcourseSelected(x);
                  }}
                  courseIcon={courseSelected.flag}
                  course
                  name={!courseSelected ? "Course" : courseSelected.slug}
                />
              </div>

              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Duration
                </span>

                <DropDown
                  data={duration}
                  error={error && !durationSelected}
                  errorMessage={error && !durationSelected ? "duration" : null}
                  onClick={async (x) => {
                    setdurationSelected(x);
                  }}
                  icon={<CalenderIcon />}
                  name={!durationSelected ? "Durations" : durationSelected.slug}
                />
              </div>

              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Currencies
                </span>

                <DropDown
                  data={currencies}
                  onClick={async (x) => {
                    setselectedCurrency(x);
                  }}
                  error={error && !selectedCurrency}
                  errorMessage={error && !selectedCurrency ? "currency" : null}
                  countryIcon={selectedCurrency?.flag}
                  country
                  name={
                    !selectedCurrency?.currency
                      ? "Currency"
                      : selectedCurrency.currency
                  }
                />
              </div>
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Types
                </span>

                <DropDown
                  data={types}
                  error={error && !selectedType}
                  errorMessage={error && !selectedType ? "type" : null}
                  onClick={async (x) => {
                    setselectedType(x);
                  }}
                  name={!selectedType.name ? "Types" : selectedType.name}
                />
              </div>

              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Days Per Week
                </span>
                <DropDown
                  data={daysPerWeek}
                  error={error && !daysPerWeekSelected}
                  errorMessage={error && !selectedType ? "type" : null}
                  onClick={async (x) => {
                    setdaysPerWeekSelected(x);
                  }}
                  menuStyle={{ height: 114 }}
                  name={
                    !daysPerWeekSelected.name
                      ? "Days Per Week"
                      : daysPerWeekSelected.name
                  }
                />
              </div>

              <FormInput
                placeholder="Hours Per Day"
                name="hoursperday"
                type="number"
                style={{ marginBottom: 4 }}
                label="Hours Per Day"
              />

              <FormInput
                placeholder="Price"
                name="price"
                type="number"
                style={{ marginBottom: 4 }}
                label="Price"
              />
              <FormInput
                placeholder="Original Price"
                name="originalPrice"
                type="number"
                style={{ marginBottom: 4 }}
                label="Original Price"
              />

              <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                <ButtonComponent
                  disable={loading}
                  text="Create"
                  type="submit"
                  noOnclick
                />
                <ButtonComponent
                  text="Cancel"
                  style={{
                    marginLeft: 16,
                    color: "#3F4247",
                    backgroundColor: "#fff",
                    border: "1px solid #D7DFE9",
                  }}
                  onClick={() => props.onHide()}
                />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
