// #region Global Imports
import styled from "styled-components";
// #endregion Global Imports

export const AnchorText = styled.a`
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #3f4247;
  &:hover {
    color: #0d6efd;
    font-weight: 900 !important;
  }
`;

export const DetailText = styled.div`
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0d6efd;
  cursor: pointer;
`;
