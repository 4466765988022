/* eslint-disable import/no-anonymous-default-export */
import { REDUX } from "../Actions/types";

const INTIAL_STATE = { redux: "" };

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case REDUX:
      return {
        ...state,
        redux: action.payload,
      };
    default:
      return state;
  }
};
