import React from "react";
import { Form } from "react-bootstrap";
import { useField } from "formik";

export default function TextArea(props) {
  const [field] = useField(props);

  return (
    <div>
      {" "}
      <Form.Group>
        <Form.Label className="input-title" style={props.style}>
          {props.label}
        </Form.Label>
        <Form.Control
          {...field}
          type={props.type}
          className={`res-input-100 input-form `}
          placeholder={props.placeholder}
          style={{
            overflow: "hidden",
            width: "100%",
            ...props.style,
          }}
          maxLength={400}
          name={props.name}
          // onChange={e => onChange(e)}
          as="textarea"
          rows={props.rows}
        />
      </Form.Group>
    </div>
  );
}
