import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "../../Lottie/loading.json";

export default class Loader extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div style={{ marginTop: 100 }}>
        <Lottie options={defaultOptions} height={100} width={100} />
      </div>
    );
  }
}
