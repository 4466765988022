import moment from "moment";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import DotIcon from "../../Assets/Icons/DotIcon";
import DeleteModal from "../../Models/DeleteModal";
import { EditMajorUser } from "../../Models/EditMajorUser";
import { deleteMajorUser } from "../../Services/Service";
import { AnchorText, DetailText } from "./styled";

function MajorTable(props) {
  const [selectedUser, setselectedUser] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [loading, setloading] = useState(false);
  const { setstudentsArr, studentsArr } = props;

  return (
    <div className="table-responsive-xl">
      {loading ? (
        "Loading..."
      ) : (
        <table className="table">
          {props.tableData?.length ? (
            <thead>
              <tr>
                {props.header.map((x, key) => (
                  <th key={key} className="table-header">
                    {x}
                  </th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {props.tableData
              .sort(function (a, b) {
                return b - a;
              })
              ?.map((x, key) => {
                // console.log(
                //   props.durations.filter((a) => a.id == x.duration)[0]?.name
                // );
                return (
                  <tr
                    key={key}
                    style={{
                      background: "#FFFFFF",
                      boxShadow: "inset 0px -1px 0px #D7DFE9",
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        id={`custom-checkbox-${key}`}
                        name={x.name}
                        value={x.name}
                        checked={setstudentsArr[x.id]}
                        onChange={() => {
                          if (studentsArr.some((a) => a.id === x.id)) {
                            const updatedData = studentsArr.filter(
                              (z) => z.id !== x.id
                            );
                            setstudentsArr(updatedData);
                          } else {
                            setstudentsArr((oldMessages) => [
                              ...oldMessages,
                              x,
                            ]);
                          }
                        }}
                      />
                    </td>
                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div className="d-flex flex-column">
                        <div className="booking-table-text"> {x.name}</div>
                        <div className="booking-table-subtext">
                          {moment(
                            new Date(
                              parseInt(x.id.toString().substring(0, 8), 16) *
                                1000
                            )
                          )
                            .tz("Asia/Kuwait")
                            .format("DD/MM/YYYY [at] hh:mm:a")}
                        </div>{" "}
                      </div>
                    </td>

                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div className="d-flex flex-column">
                        <div className="booking-table-text">{x.email}</div>
                        <div className="booking-table-subtext">
                          {moment(x.dob).format("MMM D YYYY")}
                        </div>
                      </div>
                    </td>
                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div className="d-flex flex-column">
                        <AnchorText
                          target="_blank"
                          rel="noreferrer"
                          href={`https://wa.me/${x.countryCode || ""}${
                            x.phoneNo
                          }`}
                          className="booking-table-text"
                        >
                          +{x.countryCode || "965"}
                          {x.phoneNo}
                        </AnchorText>
                      </div>
                    </td>

                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div className="d-flex flex-column">
                        <div className="booking-table-text">
                          {x.nationality || "N/A"}
                        </div>
                      </div>
                    </td>
                    <td
                      style={{ opacity: x.status ? 1 : 0.5 }}
                      className="table-body-text"
                    >
                      <div className="d-flex flex-column">
                        <DetailText>Details</DetailText>
                      </div>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dd-custom-toggle"
                          id="dropdown-custom-components"
                        >
                          <DotIcon style={{ cursor: "pointer" }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dd-custom-menu">
                          <Dropdown.Item
                            target="_blank"
                            href={`https://wa.me/${x.countryCode}${x.phoneNo}?text=Hello%20${x.name}%20I%20would%20like%20more%20information%20`}
                            className="dd-custom-items"
                          >
                            Send WhatsApp
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dd-custom-items"
                            onClick={async () => {
                              setshowModal("delete-modal");
                              setselectedUser(x);
                            }}
                          >
                            Delete User
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dd-custom-items"
                            onClick={async () => {
                              setshowModal("edit-modal");
                              setselectedUser(x);
                            }}
                          >
                            Edit User
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {showModal === "delete-modal" ? (
        <DeleteModal
          onClick={async () => {
            try {
              setloading(true);
              await deleteMajorUser(selectedUser.id);
              await props.reload();
              setshowModal(false);
              setloading(false);
            } catch (error) {
              setloading(false);
              alert(error);
            }
          }}
          onHide={() => setshowModal(false)}
          show={showModal === "delete-modal"}
        />
      ) : null}

      {showModal === "edit-modal" ? (
        <EditMajorUser
          selected={selectedUser}
          onHide={() => setshowModal(false)}
          show={showModal === "edit-modal"}
          reload={() => props.reload()}
        />
      ) : null}
    </div>
  );
}

export default MajorTable;
