/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonComponent from "../Components/ButtonComponent";
import DropDown from "../Components/DropDown";
import { FormInput } from "../Components/FormInput";
import * as Yup from "yup";
import {
  getAgeGroup,
  getCourseById,
  getCourses,
  getDaysPerWeek,
  getDurations,
  getLevelById,
  getLevels,
  updateBooking,
} from "../Services/Service";
import TabComponent from "../Components/TabComponent";
import { useEffect } from "react";
import { DayPicker } from "react-day-picker";
import moment from "moment/moment";

function EditBooking(props) {
  const [loading, setloading] = useState(false);
  const { selectedBooking } = props;
  const [courses, setcourses] = useState([]);
  const [courseSelected, setcourseSelected] = useState({});
  const [durations, setdurations] = useState([]);
  const [selectedDuration, setselectedDuration] = useState({});
  const [ageGroup, setageGroup] = useState([]);
  const [levels, setlevels] = useState([]);
  const [calendarClick, setcalendarClick] = useState(false);
  const [startDate, setStartDate] = useState(selectedBooking.courseStartDate);
  const [error] = useState("");
  const [daysSelected, setdaysSelected] = useState(
    selectedBooking.daySelected.map((a) => {
      return { value: a };
    })
  );
  const [daysPerWeekSelected, setdaysPerWeekSelected] = useState(
    selectedBooking.daysPerWeek
  );
  const [daysPerWeek, setdaysPerWeek] = useState([]);
  const [levelSelected, setlevelSelected] = useState({});

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const data = await getCourses();
      setcourses(data);
      const d = await getDurations();
      const selduration = d.filter((x) => {
        if (x.id === selectedBooking.duration) {
          return x;
        }
      })[0];

      setselectedDuration(selduration);
      setdurations(d);
      const lvl = await getLevels();
      const daysperweek = await getDaysPerWeek();
      const ageGroup = await getAgeGroup();
      const courseSel = await getCourseById(selectedBooking?.course);
      if (selectedBooking?.level) {
        const leveldata = await getLevelById(selectedBooking?.level);
        setlevelSelected(leveldata);
      }

      setcourseSelected(courseSel);
      setdaysPerWeek(daysperweek);
      setageGroup(ageGroup);

      setlevels(lvl);
    }
    fetchData();
  }, []);

  const daysSelectedfn = (value) => {
    setdaysSelected([
      ...daysSelected,
      {
        value,
      },
    ]);
  };

  // var daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  const tomorrow = moment().add(1, "days").toDate();
  const today = new Date();
  //   let disableDays = daysOfWeek.filter(
  //     (val) => !daysSelected.includes((x) => x.value === val)
  //   );

  return (
    <div>
      <Modal id="myModal" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>New Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              fullname: selectedBooking.fullname,
              localTime: selectedBooking.localTime,
            }}
            validationSchema={Yup.object({
              //   zoomLink: Yup.string().required("Enter zoom link"),
              //   zoomPassword: Yup.string().required("Enter zoom password"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setloading(true);
                let payload = {
                  fullname: values.name,
                  courseStartDate: startDate,
                  level: levelSelected.id,
                  courseId: courseSelected.id,
                };
                // if (couponCode) {
                //   payload.couponCode = couponCode;
                // }
                await updateBooking(selectedBooking.id, payload);
                props.onHide();
                await props.getBookingsfn();
                // window.location.replace(redirect.paymentUrl);
                // setLoading(false)
              } catch (error) {
                setloading(false);
                // setError(error.response.data.message);
                console.log(error);
              }
            }}
          >
            <Form>
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Course
                </span>

                <DropDown
                  data={courses}
                  error={error && !courseSelected}
                  errorMessage={error && !courseSelected ? "course" : null}
                  onClick={async (x) => {
                    setcourseSelected(x);
                  }}
                  courseIcon={courseSelected?.flag}
                  course
                  name={courseSelected?.name}
                />
              </div>

              <FormInput
                placeholder="Name"
                name="fullname"
                type="text"
                style={{ marginBottom: 4, marginLeft: 0 }}
                label="Name"
              />
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Days Per Week
                </span>
                <DropDown
                  data={daysPerWeek}
                  error={error && !daysPerWeekSelected}
                  errorMessage={
                    error && !daysPerWeekSelected ? "days per week" : null
                  }
                  onClick={async (x) => {
                    setdaysPerWeekSelected(x.daysPerWeek);
                    setdaysSelected([]);
                  }}
                  menuStyle={{ height: 114 }}
                  name={
                    daysPerWeekSelected === selectedBooking.daysPerWeek
                      ? // eslint-disable-next-line array-callback-return
                        daysPerWeek.map((x) => {
                          if (x.daysPerWeek === selectedBooking.daysPerWeek) {
                            return x.name;
                          }
                        })
                      : `${daysPerWeekSelected} Days`
                  }
                />
              </div>

              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Days
                </span>
                <TabComponent
                  error={error && !daysSelected}
                  errorMessage={error && !daysSelected ? "days" : null}
                  data={[
                    { name: "Mon", value: "mon" },
                    { name: "Tue", value: "tue" },
                    { name: "Wed", value: "wed" },
                    { name: "Thu", value: "thu" },
                    { name: "Fri", value: "fri" },
                    { name: "Sat", value: "sat" },
                    { name: "Sun", value: "sun" },
                  ]}
                  style={{
                    padding: "0 17px",
                  }}
                  multi
                  tabSelected={daysSelected}
                  settabSelected={(a) => {
                    if (daysSelected.some((y) => y.value === a)) {
                      setdaysSelected(
                        daysSelected.filter((z) => z.value !== a)
                      );
                    } else {
                      if (daysPerWeekSelected !== daysSelected.length) {
                        daysSelectedfn(a);
                      }
                    }
                  }}
                />
              </div>
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Levels
                </span>

                <DropDown
                  data={levels}
                  error={error && !levelSelected}
                  errorMessage={error && !levelSelected ? "Levels" : null}
                  onClick={async (x) => {
                    setlevelSelected(x);
                  }}
                  name={levelSelected?.name}
                />
              </div>
              <div className="flex-column d-flex" style={{ marginBottom: 24 }}>
                <span className="input-title d-flex justify-content-start align-items-center">
                  Duration
                </span>

                <DropDown
                  data={durations}
                  error={error && !selectedDuration}
                  errorMessage={error && !selectedDuration ? "Duration" : null}
                  onClick={async (x) => {
                    setselectedDuration(x);
                  }}
                  name={selectedDuration?.name}
                />
              </div>

              <FormInput
                placeholder="Time"
                name="localTime"
                type="text"
                style={{ marginBottom: 4, marginLeft: 0 }}
                label="Time"
              />
              <div className="col-md-4" style={{ margin: "40px 0" }}>
                <div
                  className="dd-text d-flex align-items-center"
                  style={style.startDateInput}
                  onClick={() => setcalendarClick(!calendarClick)}
                >
                  {!startDate
                    ? "Select start date"
                    : moment(startDate).format("DD/MM/YYYY")}
                </div>
                {calendarClick ? (
                  <DayPicker
                    selectedDays={startDate}
                    // overlayComponent={CustomOverlay}
                    onDayClick={(date, { selected, disabled }) => {
                      // setdateErr();
                      if (disabled) {
                        // Day is disabled, do nothing
                        return;
                      }
                      if (selected) {
                        // Unselect the day if already selected
                        setStartDate(undefined);
                        return;
                      }
                      setStartDate(date);
                      setcalendarClick(false);
                      // console.log.*$
                    }}
                    disabledDays={[
                      //   {
                      //     daysOfWeek: [...disableDays],
                      //   },
                      {
                        before: today,
                      },
                      // {
                      //     after: today,
                      // },
                      today,
                      tomorrow,
                    ]}
                  />
                ) : null}
              </div>
              <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                <ButtonComponent
                  disable={loading}
                  text="Update Booking"
                  type="submit"
                  noOnclick
                />
                <ButtonComponent
                  text="Cancel"
                  style={{
                    marginLeft: 16,
                    color: "#3F4247",
                    backgroundColor: "#fff",
                    border: "1px solid #D7DFE9",
                  }}
                  onClick={() => props.onHide()}
                />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EditBooking;

const style = {
  startDateInput: {
    width: "100%",
    height: "35px",
    padding: 5,
    background: "#fff",
    borderRadius: 8,
  },
};
