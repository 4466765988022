/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import moment from "moment";
import React, { useState } from "react";
import { Dropdown, FormCheck, OverlayTrigger, Popover } from "react-bootstrap";
import DotIcon from "../Assets/Icons/DotIcon";
import { deleteClass, updateClass } from "../Services/Service";

import DeleteModal from "../Models/DeleteModal";
import { db } from "../firebaseConfig";

export default function ClassTable(props) {
  const [loading, setloading] = useState(false);
  const [modalShow, setmodalShow] = useState(false);
  const [ClassId, setClassId] = useState("");

  return (
    <div className="table-responsive-xl">
      <table className="table">
        {props.tableData.length ? (
          <thead>
            <tr>
              {props.header.map((x, key) => (
                <th key={key} className="table-header">
                  {x}
                </th>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {props.tableData?.map((x, key) => {
            return (
              <tr
                key={key}
                style={{
                  background: "#FFFFFF",
                  boxShadow: "inset 0px -1px 0px #D7DFE9",
                }}
              >
                <th
                  className="table-body-text"
                  scope="row"
                  style={{ opacity: x.status ? 1 : 0.5 }}
                >
                  {key + 1}
                </th>
                <td
                  style={{ opacity: x.status ? 1 : 0.5 }}
                  className="table-body-text"
                >
                  {x.courseName}
                </td>

                <td
                  style={{ opacity: x.status ? 1 : 0.5 }}
                  className="table-body-text"
                >
                  {!x.optionAgeName ? "" : x.optionAgeName}
                </td>
                <td
                  style={{ opacity: x.status ? 1 : 0.5 }}
                  className="table-body-text"
                >
                  {x.level.length > 1 ? (
                    <div className="d-flex flex-row align-items-center">
                      {/* {x.level[0]} */}
                      {props.levels.map((a, key) => {
                        if (a.id === x.level[0]) {
                          return a.name;
                        }
                      })}
                      <div
                        key={key}
                        style={{
                          width: 18,
                          height: 18,
                          background: "rgba(11, 105, 255, 0.1)",
                          borderRadius: 22,
                          color: "#0B69FF",
                          fontWeight: "bold",
                          fontSize: 10,
                          marginLeft: 3,
                        }}
                        className="d-flex justify-content-center align-items-center "
                      >
                        <OverlayTrigger
                          trigger="click"
                          key={"top"}
                          placement={"top"}
                          overlay={
                            <Popover id={`popover-positioned-${"top"}`}>
                              <Popover.Body className="d-flex flex-column">
                                {x.level.map((a, key) => {
                                  for (
                                    let index = 0;
                                    index < props.levels.length;
                                    index++
                                  ) {
                                    const element = props.levels[index];
                                    if (element.id === a) {
                                      return (
                                        <span
                                          key={key}
                                          style={{ fontSize: 12 }}
                                        >
                                          {element.name}
                                        </span>
                                      );
                                    }
                                  }
                                })}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <a style={{ cursor: "pointer" }} variant="secondary">
                            {x.level?.length - 1}
                          </a>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : (
                    <>
                      {props.levels.map((a) => {
                        if (a.id === x.level[0]) {
                          return a.name;
                        }
                      })}
                    </>
                  )}
                </td>
                <td
                  style={{ opacity: x.status ? 1 : 0.5 }}
                  className="table-body-text"
                >
                  {!x.time ? "01:00" : moment(x.time).format("hh:mm a")}
                </td>

                <td
                  style={{ opacity: x.status ? 1 : 0.5 }}
                  className="table-body-text"
                >
                  {x.partnerName}
                </td>
                <td
                  style={{ opacity: x.status ? 1 : 0.5 }}
                  className="table-body-text"
                >
                  {x.availableDays.length === 5 ? (
                    <span>
                      {x.availableDays[0]} -{" "}
                      {x.availableDays[x.availableDays.length - 1]}
                    </span>
                  ) : (
                    x.availableDays.map((a, key) => (
                      <span key={key}>
                        {a[0]}
                        {a[1]}{" "}
                      </span>
                    ))
                  )}
                </td>
                <td
                  onClick={() => {
                    navigator.clipboard.writeText(
                      x.zoomLink.concat(`?pwd=${x.zoomPassword}`)
                    );
                  }}
                  style={{
                    opacity: x.status ? 1 : 0.5,
                    color: "#0B69FF",
                    cursor: "pointer",
                  }}
                  className="table-body-text"
                >
                  Copy Link
                </td>
                <td>
                  {" "}
                  <FormCheck custom type="switch">
                    <FormCheck.Input
                      disabled={loading}
                      onChange={async () => {
                        setloading(true);
                        await updateClass(x.id, {
                          status: !x.status,
                        });
                        await db
                          .collection("clear_cache_home")
                          .doc("su3Zr6L4Q2d21lp0wDJL")
                          .update({
                            status: false,
                          });
                        await db
                          .collection("clear_cache_pricing")
                          .doc("Ex02fRvGaBL5IGYjsbxT")
                          .update({
                            status: false,
                          });
                        await db
                          .collection("clear_cache_checkout")
                          .doc("rk4HW8Rad7ju2jk7CEze")
                          .update({
                            status: false,
                          });
                        await props.classUpdated();

                        setloading(false);
                      }}
                      checked={x.status}
                    />
                  </FormCheck>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dd-custom-toggle"
                      id="dropdown-custom-components"
                    >
                      <DotIcon style={{ cursor: "pointer" }} />
                    </Dropdown.Toggle>

                    {/* {showMenu === z.id ? ( */}
                    <Dropdown.Menu className="dd-custom-menu">
                      <Dropdown.Item
                        className="dd-custom-items"
                        onClick={async () => {
                          props.onClickEdit(x);
                        }}
                      >
                        Edit
                      </Dropdown.Item>

                      <Dropdown.Item
                        className="dd-custom-items"
                        onClick={async () => {
                          setmodalShow(true);
                          setClassId(x.id);
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {modalShow ? (
        <DeleteModal
          show={modalShow}
          name="Class"
          onHide={() => setmodalShow(false)}
          onClick={async () => {
            await deleteClass(ClassId);
            await db
              .collection("clear_cache_home")
              .doc("su3Zr6L4Q2d21lp0wDJL")
              .update({
                status: false,
              });
            await db
              .collection("clear_cache_pricing")
              .doc("Ex02fRvGaBL5IGYjsbxT")
              .update({
                status: false,
              });
            await db
              .collection("clear_cache_checkout")
              .doc("rk4HW8Rad7ju2jk7CEze")
              .update({
                status: false,
              });
            await props.classUpdated();
          }}
        />
      ) : null}
    </div>
  );
}
