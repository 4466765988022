import { Form, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import ButtonComponent from "../Components/ButtonComponent";
import * as Yup from "yup";
import { FormInput } from "../Components/FormInput";
import { useState } from "react";
import { updateMajorUser } from "../Services/Service";

export const EditMajorUser = (props) => {
  const [loading, setloading] = useState(false);

  return (
    <div>
      <Modal id="myModal" show={props.show} onHide={props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: props.selected?.name,
              nationality: props.selected?.nationality,
              phoneNo: props.selected?.phoneNo,
              countryCode: props.selected?.countryCode,
            }}
            validationSchema={Yup.object({
              //   email: Yup.string().required("Enter Email"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setloading(true);

                await updateMajorUser(props.selected.id, {
                  name: values.name,
                  description: values.description,
                  phoneNo: values.phoneNo,
                  countryCode: values.countryCode,
                  nationality: values.nationality,
                });
                await props.reload();
                setloading(false);
                props.onHide();
              } catch (error) {
                setloading(false);
                alert(error.message);
              }
            }}
          >
            <Form>
              <FormInput
                placeholder="Name"
                name="name"
                type="text"
                style={{ marginBottom: 4 }}
                label="Name"
              />
              <FormInput
                placeholder="Nationality"
                name="nationality"
                type="text"
                style={{ marginBottom: 4 }}
                label="Nationality"
              />
              <FormInput
                placeholder="Coupon code"
                name="countryCode"
                type="text"
                style={{ marginBottom: 4 }}
                label="Coupon code"
              />
              <FormInput
                placeholder="Phone Number"
                name="phoneNo"
                type="text"
                style={{ marginBottom: 4 }}
                label="Phone Number"
              />
              <div className="d-flex flex-row" style={{ marginTop: 36 }}>
                <ButtonComponent
                  disable={loading}
                  text="Update"
                  type="submit"
                  noOnclick
                />
                <ButtonComponent
                  text="Cancel"
                  style={{
                    marginLeft: 16,
                    color: "#3F4247",
                    backgroundColor: "#fff",
                    border: "1px solid #D7DFE9",
                  }}
                  onClick={() => props.onHide()}
                />
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
