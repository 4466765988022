import "./Assets/css/Style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import Router from "./Router/Router";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createStore, compose, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { persistStore } from "redux-persist";
import reducer from "./Reducers";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "react-phone-number-input/style.css";
import "react-day-picker/dist/style.css";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(ArcElement, Tooltip, Legend);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehydrated: false,
      store: createStore(reducer, {}, compose(applyMiddleware(thunk))),
    };
  }

  componentWillMount() {
    this.persistor = persistStore(this.state.store, {}, () => {
      this.setState({ rehydrated: true });
    });
  }

  render() {
    return (
      <Provider store={this.state.store}>
        <BrowserRouter>
          {this.state.rehydrated ? (
            <Router history={this.props.history} />
          ) : null}
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
